import React from 'react';
import mtzApis from '../../../../services';
const { companyService } = mtzApis;

function DonationSettingManager({ companyGroupId, onCreate, onUpdate }) {
    let [donation, setDonation] = React.useState();
    let [isEditing, setIsEditing] = React.useState(false);
    let [creating, setCreating] = React.useState({
        title: '',
        description: '',
        companyGroupId,
        asEnabled: false
    });
    let [changes, setChanges] = React.useState({
        title: '',
        description: '',
        asEnabled: false
    });

    React.useEffect(() => {
        init();
    }, [companyGroupId]);

    const init = async () => {
        // load donation
        let donations = await companyService.getCGDonations(new URLSearchParams(`companyGroupIds=${companyGroupId}`));
        if (donations.length > 0) {
            setDonation(donations[0]);
            setChanges({ title: donations[0].title, description: donations[0].description, asEnabled: donations[0].asEnabled });
        }
    };

    const createDonation = async (e) => {
        e.preventDefault();

        mtzApis.startSpinningIcon();
        let created = await companyService.createCGDonation(creating);
        mtzApis.stopSpinningIcon();

        if (created) {
            alert('Donation created. Next, please add donation amounts');
            setDonation(created);
            setCreating({
                title: '',
                description: '',
                companyGroupId
            });
            setChanges({ title: created.title, description: created.description, asEnabled: created.asEnabled });
        }
    };

    const updateDonation = async (e) => {
        e.preventDefault();

        if (Object.keys(changes).length === 0) {
            alert('Nothing changed');
            return;
        }

        mtzApis.startSpinningIcon();
        let updated = await companyService.updateCGDonationById(donation.id, changes);
        mtzApis.stopSpinningIcon();

        if (updated) {
            alert('Donation updated');
            setDonation(updated);
            setChanges({ title: updated.title, description: updated.description, asEnabled: updated.asEnabled });
            if (onUpdate) onUpdate(updated);
        }
    }

    return (
        <div className="d-flex flex-column mtz-gap-16">
            <h5>
                {donation ? 'Update Donation' : 'Create Donation'}:
            </h5>

            <form onSubmit={donation ? updateDonation : createDonation} className="d-flex flex-column mtz-gap-8">
                <div className="form-group">
                    <label>Donation Title:</label>
                    <input type="text" className="form-control"
                        onChange={
                            donation ?
                                e => setChanges({ ...changes, title: e.target.value }) :
                                e => setCreating({ ...creating, title: e.target.value })
                        }
                        value={donation ? changes.title : creating.title || ''}
                    />
                </div>
                <div className="form-group">
                    <label>Donation Message:</label>
                    <input type="text" className="form-control"
                        onChange={
                            donation ?
                                e => setChanges({ ...changes, description: e.target.value }) :
                                e => setCreating({ ...creating, description: e.target.value })
                        }
                        value={donation ? changes.description : creating.description || ''}
                    />
                </div>
                <div className="form-check">
                    <input type='checkbox' className='form-check-input' id='label-donation-enabled'
                        checked={donation ? changes.asEnabled : (creating.asEnabled ? true : false)}
                        onChange={
                            donation ?
                                e => setChanges({ ...changes, asEnabled: e.target.checked }) :
                                e => setCreating({ ...creating, asEnabled: e.target.checked })
                        }
                    />
                    <label className='form-check-label' htmlFor='label-donation-enabled'>
                        Donation is enabled?: {donation ? donation.asEnabled ?
                            <span className='text-success'>Yes</span> :
                            <span className='text-danger'>No</span> : ''}
                    </label>
                </div>
                <div className='text-right'>
                    <button type="submit" className="btn btn-primary">
                        {donation ? 'Update Donation' : 'Create Donation'}
                    </button>
                </div>
            </form>

            {
                donation && donation.asEnabled && (
                    <>
                        <hr />
                        <DonationPriceManager donationId={donation.id} />
                    </>
                )
            }
        </div >
    );
}

function DonationPriceManager({ donationId }) {
    let [prices, setPrices] = React.useState([]);
    let [params, setParams] = React.useState({});
    let [active, setActive] = React.useState();
    let modalDonationPriceForm = React.useRef();

    React.useEffect(() => {
        init();
    }, [donationId]);

    const init = async () => {
        let prices = await companyService.getCGDonationPrices(new URLSearchParams(`cgDonationIds=${donationId}`));
        setPrices(prices);
    };

    const createPrice = async (e) => {
        e.preventDefault();

        mtzApis.startSpinningIcon();
        params.cgDonationId = donationId;
        let created = await companyService.createCGDonationPrice(params);
        mtzApis.stopSpinningIcon();

        if (created) {
            alert('Price created');
            setPrices([created, ...prices]);
            setParams({ cents: 0 });
            modalDonationPriceForm.current.click();
            setActive();
        }
    };

    const selectPrice = (price) => {
        setActive(price);
        setParams({ cents: price.cents });
    };

    const updatePrice = async (e) => {
        e.preventDefault();

        mtzApis.startSpinningIcon();
        let updated = await companyService.updateCGDonationPriceById(active.id, params);
        mtzApis.stopSpinningIcon();

        if (updated) {
            alert('Price updated');
            setPrices(prices.map(p => p.id === updated.id ? updated : p));
            modalDonationPriceForm.current.click();
            setActive();
        }
    };

    const deletePrice = async price => {
        const ok = await window.createMtzConfirm('Are you sure?');
        if (!ok) return;

        mtzApis.startSpinningIcon();
        let deleted = await companyService.deleteCGDonationPriceById(price.id);
        mtzApis.stopSpinningIcon();

        if (deleted) {
            alert('Price deleted');
            setPrices(prices.filter(p => p.id !== price.id));
        }
    };

    return (
        <div className="d-flex flex-column mtz-gap-16">
            <h5 className='mtz-h5'>
                Donation Amount: <small className='text-danger'>
                    <i>
                        <small>
                            (You can create up to 5 donation amounts)
                        </small>
                    </i>
                </small>
            </h5>

            <div>
                <button className='btn btn-outline-primary' data-toggle="modal" onClick={() => {
                    setParams({ cents: 0 });
                    setActive();
                }}
                    data-target="#modal-donation-price-form" ref={modalDonationPriceForm}>
                    Create Amount <i className="fa fa-plus"></i>
                </button>
            </div>

            <div>
                {
                    prices && prices.length > 0 ?
                        prices.sort((a, b) => a.cents - b.cents).map((price, i) => (
                            <div key={price.id} className="d-flex flex-row justify-content-between m-1">
                                <div>
                                    <b>Amount {i + 1}:</b> ${price.cents / 100}
                                </div>
                                <div>
                                    <button className="btn btn-outline-primary btn-sm" data-toggle="modal" ref={modalDonationPriceForm}
                                        data-target="#modal-donation-price-form" onClick={() => selectPrice(price)}>
                                        Edit
                                    </button>
                                    &nbsp;
                                    <button onClick={() => deletePrice(price)} className="btn btn-outline-danger btn-sm">
                                        Delete
                                    </button>
                                </div>
                            </div>
                        )) :
                        <div className="text-center text-muted">
                            No amount found
                        </div>
                }

                <div className="modal fade" id="modal-donation-price-form">
                    <div className="modal-dialog modal-dialog-scrollable modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {active ? 'Update' : 'Create'} Amount
                                </h5>
                                <button type="button" className="close"
                                    onClick={() => modalDonationPriceForm.current.click()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <form onSubmit={active ? updatePrice : createPrice}>
                                    <div className="form-group">
                                        <label>Amount:</label>
                                        <input type="number" className="form-control" min="$2" placeholder='Min amount is $2'
                                            onChange={e => setParams({ ...params, cents: e.target.value * 100 })}
                                            value={params.cents ? params.cents / 100 : ''}
                                        />
                                    </div>

                                    <button type="submit" className="btn btn-primary">
                                        {active ? 'Update amount' : 'Add amount'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default DonationSettingManager;