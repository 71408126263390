import React from "react";

import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { NavLink } from "react-router-dom";
import AuthContext from "../../../../../AuthContext";
import mtzApis from "../../../../../services";
import { PAYMENT_SERVER_URL } from "../../../../../services/config";
import PaymentSelector from "../../../../payment-service/general/PaymentSelector";


let stripePromise = null;
if (PAYMENT_SERVER_URL.includes('localhost'))
    stripePromise = loadStripe('pk_test_51MC6L3A61qE8cuHxPJFWqpVDc6GtgDks7LvFuafSLWtBzqhTY3ZEyJBvPVSryYZ91tkgkzEsAEJb4zKetppjR5XY007pkmKWnV');
else if (PAYMENT_SERVER_URL.includes('mytradezone.com'))
    stripePromise = loadStripe('pk_live_51LuGhqH6fkJtSoVxsSVoDiSV7KpR3EhT0rCBwCHZmZVhgeBm140UiXiHstO3GG5F57KxGqopWT2c8bf7bVMrr2y700fViKFBYd');
else
    stripePromise = loadStripe('pk_test_51MC6L3A61qE8cuHxPJFWqpVDc6GtgDks7LvFuafSLWtBzqhTY3ZEyJBvPVSryYZ91tkgkzEsAEJb4zKetppjR5XY007pkmKWnV');

const { companyService, paymentService } = mtzApis;

function CGDonationButton({ profileName }) {
    const me = React.useContext(AuthContext);
    const stripe = useStripe();
    const elements = useElements();
    let [donation, setDonation] = React.useState();
    let [amounts, setAmounts] = React.useState([]);
    let [group, setGroup] = React.useState();
    let [step, setStep] = React.useState('amounts');
    let [calc, setCalc] = React.useState();
    let [cardErr, setCardErr] = React.useState();
    let [newDonationOrder, setNewDonationOrder] = React.useState(
        {
            buyerUserEmail: me ? me.email : '',
            buyerUserFirstname: me ? me.firstname : '',
            buyerUserLastname: me ? me.lastname : '',
            buyerUserEmailConfirm: me ? me.email : '',
        }
    );
    let [paymentMethod, setPaymentMethod] = React.useState();
    let [paymentInstrumentId, setPaymentInstrumentId] = React.useState();
    let [wallet, setWallet] = React.useState();
    let [myCustomer, setMyCustomer] = React.useState(null);
    let [cards, setCards] = React.useState(null);
    const modalCreateDonationPaymentRef = React.useRef();

    React.useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let groups = await companyService.getCompanyGroups(new URLSearchParams({ profileNames: profileName }));
        let group = groups[0];
        if (group) {
            setGroup(group);
            let donations = await companyService.getCGDonations(new URLSearchParams({ companyGroupIds: group.id }));
            donation = donations[0];

            if (donation) {
                setDonation(donation);
                let amounts = await companyService.getCGDonationPrices(new URLSearchParams({ cgDonationIds: donation.id }));
                setAmounts(amounts);
            }

            // load my customer
            if(!me || !me.userId)
                return;
            myCustomer = await paymentService.getMyMtzCustomer();
            if (myCustomer) {
                setMyCustomer(myCustomer);
                if (myCustomer.paymentMethod) setPaymentMethod(myCustomer.paymentMethod);

                if (myCustomer.paymentMethod === 'CARD') {
                    let cards = await paymentService.getCards(new URLSearchParams(``));
                    setCards(cards);
                    if (cards) {
                        let defaultCard = cards.find(c => c.asDefault);
                        if (defaultCard) {
                            setPaymentInstrumentId(defaultCard.id);
                        }
                    }
                }
            }
        }
    }

    const orderCalc = async () => {
        let amount = amounts.find(a => a.id === newDonationOrder.cgDonationPriceId);
        newDonationOrder.cents = amount.cents;

        let params = {
            cents: newDonationOrder.cents,
            sellerUserId: group.ownerId,
            buyerUserEmail: newDonationOrder.buyerUserEmail,
            _dryRun: true
        };

        // if not login, use direct payment
        if (!me || !me.userId) {
            params.paymentSource = 'random-payment-token'; // for non-login user's calc purpose only
        } else {
            if (paymentMethod)
                params.paymentMethod = paymentMethod;
            if (paymentInstrumentId)
                params.paymentInstrumentId = paymentInstrumentId;
        }

        calc = await paymentService.createMtzCharge(params);
        if (calc) {
            setNewDonationOrder({ ...newDonationOrder });
            setCalc(calc);
            return calc
        }
    }

    const createDonationPayment = async () => {
        mtzApis.startSpinningIcon();

        if (!me || !me.userId) { // use card directly
            if (newDonationOrder.buyerUserEmailConfirm !== newDonationOrder.buyerUserEmail) {
                alert('Confirm email does not match email');
                return;
            }

            const card = elements.getElement(CardElement);
            const result = await stripe.createToken(card);
            if (result.error) {
                setCardErr(result.error.message);
                return;
            }
            newDonationOrder.paymentSource = result.token.id;
            card.clear();

        } else {
            if (paymentMethod)
                newDonationOrder.paymentMethod = paymentMethod;
            if (paymentInstrumentId)
                newDonationOrder.paymentInstrumentId = paymentInstrumentId;
        }

        let params = { ...newDonationOrder };
        delete params.buyerUserEmailConfirm;

        if (!params.buyerUserFirstname) {
            setCardErr("First name is required");
            return;
        }

        if (!params.buyerUserLastname) {
            setCardErr("Last name is required");
            return;
        }

        if (!params.buyerUserEmail) {
            setCardErr("Email is required");
            return;
        }

        let created = await companyService.createCGDonationPayment(params);
        mtzApis.stopSpinningIcon();

        if (created) {
            if (me && me.userId) {
                const ok = await window.createMtzConfirm(<div>
                    <div className="text-center text-success mtz-h5">
                        Thank you so much for your donation!
                    </div>
                </div>, "Check my donations", "Close");
                if (ok) {
                    window.open("/me/billing/donations", "_blank");
                }
            } else {
                const ok = await window.createMtzConfirm(<div>
                    <div className="text-center text-success mtz-h5">
                        Thank you so much for your donation!. Login to explore more features?
                    </div>
                </div>, "Go to login", "Close");
                if (ok) {
                    window.open("/login", "_blank");
                }
            }

            setStep('amounts');
            modalCreateDonationPaymentRef.current.click();
        }
    }

    if (!me || !group || !donation || !donation.asEnabled)
        return <></>;

    if (me.userId === group.ownerId)
        return <></>;

    const renderAmounts = () => {
        return (
            <>
                <div className="form-group">
                    <label>Select amount to donate:</label>
                    {
                        amounts.length > 0 ?
                            <div className="d-flex mtz-gap-16 align-items-center flex-wrap justify-content-center">
                                {
                                    amounts.map((amount, index) => {
                                        return (
                                            <div key={index} className="form-check">
                                                <input type="radio" className="form-check-input" id={`cg-donation-price-to-payment-${amount.id}`}
                                                    checked={newDonationOrder.cgDonationPriceId === amount.id}
                                                    onChange={() => { setNewDonationOrder({ ...newDonationOrder, cgDonationPriceId: amount.id }) }} />
                                                <label className="form-check-label" htmlFor={`cg-donation-price-to-payment-${amount.id}`}>
                                                    ${amount.cents / 100}
                                                </label>
                                            </div>
                                        );
                                    })
                                }
                            </div> :
                            <div className="text text-muted">
                                There are no donation amounts available
                            </div>
                    }
                </div>

                <div className="text-center">
                    <button type="button" className="btn btn-primary"
                        disabled={amounts.length === 0 || !amounts || !newDonationOrder.cgDonationPriceId}
                        onClick={() => setStep('contact')}
                    >
                        Next, select payment method
                    </button>
                </div>
            </>
        );
    }

    const renderGetContact = () => {
        const submit = async e => {
            e.preventDefault();

            if (newDonationOrder.buyerUserEmail !== newDonationOrder.buyerUserEmailConfirm) {
                alert('Email does not match');
                return;
            }

            await orderCalc();

            setStep('pay');
        };

        return (
            <form className=" d-flex flex-column mtz-gap-16" onSubmit={submit}>
                <h5 className="mtz-h5 text-center">Your contact info to receive a receipt.</h5>

                <div className="d-flex mtz-gap-8">
                    <div className="form-group flex-fill">
                        <label>Email: <span className="text-danger">*required</span></label>
                        <input required placeholder="E.g. abc@test.com" className="form-control"
                            value={newDonationOrder.buyerUserEmail || ''} disabled={me && me.userId}
                            onChange={e => setNewDonationOrder({ ...newDonationOrder, buyerUserEmail: e.target.value })} type='email' />
                    </div>
                    <div className="form-group flex-fill">
                        <label>Confirm Email:</label>
                        <input required className="form-control" value={newDonationOrder.buyerUserEmailConfirm || ''} type='email' disabled={me && me.userId}
                            onChange={e => setNewDonationOrder({ ...newDonationOrder, buyerUserEmailConfirm: e.target.value || '' })} />
                    </div>
                </div>

                <div className="d-flex mtz-gap-8">
                    <div className="form-group flex-fill">
                        <label>First Name: <span className="text-danger">*required</span></label>
                        <input required placeholder="E.g. John" className="form-control"
                            value={newDonationOrder.buyerUserFirstname || ''} disabled={me && me.userId}
                            onChange={e => setNewDonationOrder({ ...newDonationOrder, buyerUserFirstname: e.target.value })} />
                    </div>
                    <div className="form-group flex-fill">
                        <label>Last Name: <span className="text-danger">*required</span></label>
                        <input required placeholder="E.g. Smith" className="form-control"
                            value={newDonationOrder.buyerUserLastname || ''} disabled={me && me.userId}
                            onChange={e => setNewDonationOrder({ ...newDonationOrder, buyerUserLastname: e.target.value })} />
                    </div>
                </div>

                <div className="d-flex justify-content-between">
                    <button onClick={() => setStep('amounts')} className="btn btn-secondary">
                        Back
                    </button>
                    <span className="flex-fill"></span>
                    <button className="btn btn-primary">Next</button>
                </div>
            </form>
        );
    };

    const renderPay = () => {
        return (
            <div>
                <h5 className="mtz-h5 text-primary text-center">=== Review & Pay ===</h5>

                <PaymentSelector onChange={async paymentInfo => {
                    if (paymentInfo.paymentMethod) {
                        paymentMethod = paymentInfo.paymentMethod;
                        setPaymentMethod(paymentMethod);
                        orderCalc();

                        // refresh wallet state
                        if (paymentMethod === 'WALLET') {
                            wallet = await paymentService.getMyWallet();
                            if (wallet)
                                setWallet(wallet);
                        }

                        if (paymentMethod === 'CARD') {
                            cards = await paymentService.getCards();
                            if (cards)
                                setCards(cards);
                        }
                    }

                    if (paymentInfo.paymentInstrumentId) {
                        paymentInstrumentId = paymentInfo.paymentInstrumentId;
                        setPaymentInstrumentId(paymentInstrumentId);
                    }
                }} />

                <div className="d-flex justify-content-end">
                    <div>
                        <div>
                            <b>Donation amount:</b> ${calc.cents / 100}
                        </div>
                        <div>
                            <b>Transaction fee:</b> ${calc?.tranxCents / 100}
                        </div>
                        <div className="border-top">
                            <b>Total amount:</b> {calc && <span className="mtz-h5 text-primary">${calc.finalCents / 100}</span>}
                        </div>
                    </div>
                </div>

                <br />

                <div className="d-flex">
                    <div className="d-flex align-items-end">
                        <button onClick={() => setStep('quantity')} className="btn btn-secondary">Back</button>
                    </div>
                    <span className="flex-fill"></span>
                    <div className="d-flex flex-column align-items-end">
                        {
                            (paymentMethod === 'CARD') && (
                                (calc && calc.finalCents > 0 && (!cards || cards.length === 0)) ?
                                    <div className="text-danger">
                                        You need to add a card to make payment
                                    </div> :
                                    <div>
                                        <button onClick={createDonationPayment} className="btn btn-primary text-nowrap">
                                            Confirm & Donate
                                        </button>
                                    </div>
                            )
                        }

                        {
                            (paymentMethod === 'WALLET') && (
                                (calc && calc.finalCents > 0 && (!wallet || wallet.cents < calc.finalCents)) ?
                                    <div className="text-danger">
                                        Your wallet balance is not enough to make payment
                                    </div> :
                                    <div>
                                        <button onClick={createDonationPayment} className="btn btn-primary text-nowrap">
                                            Confirm & Donate
                                        </button>
                                    </div>
                            )
                        }

                        {
                            !paymentMethod && me.userId &&
                            <div className="text-danger">
                                Please select a payment method
                            </div>
                        }

                        {
                            !me.userId &&
                            <div>
                                <button onClick={createDonationPayment} className="btn btn-primary text-nowrap">
                                    Confirm & Donate
                                </button>
                            </div>
                        }
                    </div>
                </div>

                {
                    cardErr &&
                    <div className='text-danger font-weight-bold'>* ERROR: {cardErr}</div>
                }
            </div>
        );
    };

    return (
        <>
            <button className="btn btn-primary" data-toggle="modal" ref={modalCreateDonationPaymentRef}
                data-target="#modal-create-donation-payment">
                donate
            </button>

            <div className="modal fade" id="modal-create-donation-payment">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mtz-h5">
                                Donate to the group <span className="text-primary mtz-h5">{group.name}</span>
                            </h5>
                            <button type="button" className="close"
                                onClick={() => { modalCreateDonationPaymentRef.current.click() }}>&times;</button>
                        </div>

                        <div className="modal-body">
                            {
                                step === 'amounts' &&
                                renderAmounts()
                            }

                            {
                                step === 'contact' &&
                                renderGetContact()
                            }

                            {
                                step === 'pay' &&
                                renderPay()
                            }

                            <hr />

                            <div className="text-right">
                                <small>
                                    <NavLink to="/me/billing/donations" target="_blank">
                                        Check my donation history
                                    </NavLink>
                                </small>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}

const CGDonationButtonWrapper = props => <Elements stripe={stripePromise}><CGDonationButton {...props} /></Elements>;
export default CGDonationButtonWrapper;