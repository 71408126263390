import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import mtzApis from "../../../../services";
import * as authUtil from "../../../../services/authUtil";
import LoginForm from "../LoginForm";
import RegisterForm from "../RegisterForm";

const { companyService } = mtzApis;

function OneClickLoginSignUp({ onSuccessLogin, onSuccessRegister, showRegisterButton }) {
    let [showRegister, setShowRegister] = React.useState(false);
    let [createdUser, setCreatedUser] = React.useState();
    let [userActivated, setUserActivated] = React.useState(false);
    let [code, setCode] = React.useState();

    const verify = async (e) => {
        e.preventDefault();

        if (!code || code.length !== 6) {
            alert("Please enter 6 digits code");
            return;
        }

        mtzApis.startSpinningIcon();
        let req = await companyService.createAuthReq({
            type: 'CONFIRM_EMAIL_WITH_CODE',
            data: {
                email: createdUser.email,
                confirmCode: code
            }
        });
        mtzApis.stopSpinningIcon();

        if (req) {
            alert('You account has been successfully activated');
            await saveLoginToken(req);

            // create company
            let meta = createdUser.meta;
            if (meta.companyName && meta.categoryIds) {
                await companyService.createCompany({
                    name: meta.companyName,
                    categoryIds: meta.categoryIds.split(',')
                });
            }

            if (onSuccessRegister) {
                onSuccessRegister();
                return;
            }
            setUserActivated(true);
        }
    }

    const saveLoginToken = async (req) => {
        if (!req.accessToken)
            return;
        let parsed = atob(req.accessToken.split('.')[1]);
        parsed = JSON.parse(parsed);
        authUtil.setLogin(parsed.email, { accessToken: req.accessToken, refreshToken: req.refreshToken });

        // test token
        let user = await companyService.getMyUser();
        if (!user) {
            alert('Invalid login');
            authUtil.removeLogin(parsed.email);
        }
    };

    const resendCode = async () => {
        mtzApis.startSpinningIcon();
        let req = await companyService.createAuthReq({
            type: 'RESEND_CONFIRM_EMAIL_WITH_CODE',
            data: {
                email: createdUser.email
            }
        });
        mtzApis.stopSpinningIcon();

        if (req) {
            alert("New 6-digit code sent. Please check your email: " + createdUser.email);
        }
    }

    return (
        <div className="w-100 mx-auto" style={{ maxWidth: '600px' }}>
            {
                !showRegister && !createdUser &&
                <>
                    {
                        showRegisterButton &&
                        <>
                            <button onClick={() => setShowRegister(true)} className="w-100 btn py-3 btn-warning">
                                No account? register here
                            </button>

                            <hr /></>
                    }

                    <LoginForm onSuccess={onSuccessLogin} />

                    <hr />

                    <div className="d-flex flex-column">
                        <div className="">
                            <NavLink to="/reset-password">Forgot password?</NavLink>
                        </div>

                        <div className="">
                            <a href="/register-confirm-resend">Resend registration confirm email?</a>
                        </div>

                        <div className="">
                            <a href="/reset">Reset password?</a>
                        </div>
                    </div>
                </>
            }

            {
                showRegister && !createdUser &&
                <>
                    <RegisterForm onCreate={newUser => {
                        setCreatedUser(newUser);
                        setShowRegister(false);
                    }} confirmType={'CONFIRM_CODE'} />

                    <br />

                    <div className="text-center">
                        <button onClick={() => setShowRegister(false)} className="btn btn-link">
                            <i className="fa fa-arrow-left"></i> Back to login
                        </button>
                    </div>
                </>
            }

            {
                createdUser && !userActivated &&
                <div className=" d-flex flex-column mtz-gap-16">
                    <h5 className="text-success text-center">Congratulation, welcome to MyTradeZone</h5>
                    <div>
                        Step 1: Please check your email <b>'{createdUser.email}'</b> to get 6 digits code. <b>It will expire in 5 minutes.</b> If it is already expired, click <b>Re-send</b> button to get a new code.
                    </div>

                    <div>
                        <div>
                            Step 2: Use the code to verify your email below.
                        </div>

                        <form onSubmit={verify}>
                            <div className="form-group">
                                <label className="d-flex align-items-center">Enter 6 digits code: <button type="button" className="btn btn-link btn-sm" onClick={resendCode}>
                                    Re-send
                                </button></label>
                                <input type="text" className="form-control" placeholder="6 digits code" maxLength={6}
                                    value={code || ''} onChange={e => {
                                        let val = e.target.value;
                                        val = val.replace(/[^0-9]/g, '');
                                        if (val.length > 6) {
                                            val = val.substring(0, 6);
                                        }
                                        setCode(val);
                                    }}
                                />
                            </div>
                            <div className="form-group text-right">
                                <button type="submit" className="btn btn-primary">Verify</button>
                            </div>
                        </form>
                    </div>
                </div>
            }

            {
                createdUser && userActivated &&
                <div className="d-flex flex-column mtz-gap-16">
                    <h5 className="text-success text-center">Congratulation on creating your account on MyTradeZone!</h5>
                    <div>
                        Please make sure you visit your dashboard later to finish your registration process to make the most of MyTradeZone
                    </div>

                    <button className="btn btn-outline-primary" onClick={() => window.location.reload()}>
                        Reload and continue your group subscription
                    </button>
                </div>
            }
        </div>
    );
}

export default OneClickLoginSignUp;