import React from 'react';

import mtzApis from '../../../../services';
import MyWallet from '../../../user-service/mtzWallets/MyWallet';
import CardManager from '../../cards/CardManager';

const { paymentService } = mtzApis;

function PaymentSelector({ onChange, value, label }) {
    let [paymentMethod, setPaymentMethod] = React.useState();
    let [paymentInstrumentId, setPaymentInstrumentId] = React.useState();
    let [myCustomer, setMyCustomer] = React.useState(null);

    React.useEffect(() => {
        init();
    }, []);

    const init = async () => {
        myCustomer = await paymentService.getMyMtzCustomer();
        if (myCustomer) {
            if (value) {
                paymentMethod = value.paymentMethod;
                setPaymentMethod(paymentMethod);
                paymentInstrumentId = value.paymentInstrumentId;
                setPaymentInstrumentId(paymentInstrumentId);
            } else {
                paymentMethod = myCustomer.paymentMethod;
                setPaymentMethod(paymentMethod);

                if (paymentMethod === 'CARD') {
                    let cards = await paymentService.getCards();
                    if (cards) {
                        let defaultCard = cards.find(c => c.asDefault);
                        if (defaultCard) {
                            paymentInstrumentId = defaultCard.id;
                            setPaymentInstrumentId(paymentInstrumentId);
                        }
                    }
                }
            }

            setMyCustomer(myCustomer);
        }
    }


    const selectPaymentMethod = async (method) => {
        if (!method) {
            alert('Payment method is required');
            return;
        }

        setPaymentMethod(method);

        if (method === 'CARD') {
            let cards = await paymentService.getCards();
            if (cards) {
                let defaultCard = cards.find(c => c.asDefault);
                if (defaultCard) {
                    paymentInstrumentId = defaultCard.id;
                    setPaymentInstrumentId(paymentInstrumentId);
                }
            }
        }

        if (onChange) onChange({ paymentMethod: method, paymentInstrumentId });
    }

    const setDefaultPaymentMethod = async () => {
        mtzApis.startSpinningIcon();
        let updated = await paymentService.updateMtzCustomerById(myCustomer.id, { paymentMethod });
        mtzApis.stopSpinningIcon();

        if (updated) {
            alert('Default payment method updated');
            setMyCustomer(updated);
        }
    }

    const onCardSelected = async (card) => {
        setPaymentInstrumentId(card.id);
        if (onChange) onChange({ paymentMethod: 'CARD', paymentInstrumentId: card.id });
    }

    const onWalletChange = async (wallet) => {
        if (onChange) onChange({ paymentMethod: 'WALLET' });
    }

    return (
        <div className='d-flex flex-column mtz-gap-16'>
            <div className='form-group'>
                <h6 className='mtz-h6'><u>{label || 'Select payment method'}:</u></h6>

                <div className='mtz-card'>
                    <div>
                        <div className='form-group bg-light p-2 rounded'>
                            <label>
                                Current payment method:
                            </label>
                            <div>
                                {/* dont use select, use radio */}
                                <div className='form-check'>
                                    <label className='form-check-label'> <input onChange={e => selectPaymentMethod('WALLET')}
                                        className='form-check-input' type='radio' name='payment-method' value='WALLET'
                                        checked={paymentMethod === 'WALLET'} /> Use Wallet</label> {myCustomer?.paymentMethod === 'WALLET' && ' (Default)'}
                                </div>

                                <div className='form-check'>
                                    <label className='form-check-label'><input onChange={e => selectPaymentMethod('CARD')}
                                        className='form-check-input' type='radio' name='payment-method' value='CARD'
                                        checked={paymentMethod === 'CARD'} /> Use Card</label> {myCustomer?.paymentMethod === 'CARD' && ' (Default)'}
                                </div>

                                {/* <select onChange={e => selectPaymentMethod(e.target.value)}
                                    className='form-control' value={(paymentMethod) || ''}>
                                    <option value=''>== Select payment method ==</option>
                                    <option value='WALLET'>Wallet</option>
                                    <option value='CARD'>Card</option>
                                </select> */}
                            </div>
                            {
                                paymentMethod && paymentMethod !== myCustomer?.paymentMethod &&
                                <small className='form-check text-success'>
                                    <input onChange={e => setDefaultPaymentMethod(e.target.checked)}
                                        className='form-check-input' type='checkbox' />
                                    <label className='form-check-label'>Default method for future payments?</label>
                                </small>
                            }
                        </div>

                        {
                            paymentMethod &&
                            <div className='bg-light p-2 rounded'>
                                {
                                    paymentMethod === 'WALLET' &&
                                    <MyWallet onChange={onWalletChange} />
                                }

                                {
                                    paymentMethod === 'CARD' &&
                                    <>
                                        <CardManager onSelect={onCardSelected} />
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentSelector;