import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGEventSponsorPlanOrder = (cgEventSponsorPlanOrder) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plan-orders`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, cgEventSponsorPlanOrder, headers);
};

export const deleteCGEventSponsorPlanOrderById = (cgEventSponsorPlanOrderId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plan-orders/${cgEventSponsorPlanOrderId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCGEventSponsorPlanOrderById = (cgEventSponsorPlanOrderId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plan-orders/${cgEventSponsorPlanOrderId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export const getCGEventSponsorPlanOrderById = (cgEventSponsorPlanOrderId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plan-orders/${cgEventSponsorPlanOrderId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventSponsorPlanOrders = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plan-orders?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventSponsorPlanOrderStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plan-orders/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};