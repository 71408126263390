import React from "react";

import { NavLink } from "react-router-dom";
import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
const { companyService } = mtzApis;

function CompMems({ company }) {
    let [mems, setMems] = React.useState(null);
    let [selectedEmails, setSelectedEmails] = React.useState([]);
    let [targetEmail, setTargetEmail] = React.useState(null);
    const modalCompMembersRef = React.useRef();
    const modalAddCompMemRef = React.useRef();
    const me = React.useContext(AuthContext);

    React.useEffect(() => {
        (async () => {
            let mems = await companyService.getCompanyMembers(new URLSearchParams(`companyId=${company.id}`));
            mems.unshift({ userId: company.ownerId });

            let userIds = mems.map((item) => item.userId);
            let users = await companyService.getUsers(new URLSearchParams(`userIds=${userIds}`));
            let userMap = {};
            users.forEach((user) => (userMap[user.id] = user));
            mems.forEach((mem) => {
                mem.user = userMap[mem.userId];
            });

            setMems(mems);
        })();
    }, [company]);

    const validateAndAddEmail = () => {
        if (!targetEmail) {
            alert("Please enter email");
            return;
        }

        if (selectedEmails.includes(targetEmail)) {
            alert("Email already selected");
            return;
        }

        let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!pattern.test(targetEmail)) {
            alert("Please enter valid email");
            return;
        }

        setSelectedEmails([...selectedEmails, targetEmail]);
        setTargetEmail(null);
    }

    const sendInvites = async () => {
        if (selectedEmails.length === 0) {
            alert("Please select at least one email");
            return;
        }

        let reqs = selectedEmails.map((email) => companyService.createCompanyMemberInvite({ targetEmail: email }));
        let data = await Promise.all(reqs);

        if (data && data.filter(d => !!d).length > 0) {
            setSelectedEmails([]);
            modalAddCompMemRef.current.click();
            alert("Invites sent");
        }
    }

    const deleteMem = async (mem) => {
        const ok = await window.createMtzConfirm(`Are you sure?`);
        if (!ok) return;

        await companyService.deleteCompanyMemberById(mem.id);
        setMems(mems.filter(m => m.userId !== mem.userId));
    }

    return (
        <>
            <button className="btn btn-link btn-sm" data-toggle="modal" data-target="#modal-company-members">
                Members {mems ? mems.length : "0"}
            </button>

            <div className="modal fade" id="modal-company-members">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog modal-dialog-scrollable-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Company Members</h5>
                            <button
                                onClick={() =>
                                    modalCompMembersRef.current.click()
                                }
                                className="close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body d-flex flex-column mtz-gap-16">
                            {
                                company.ownerId === me.userId &&
                                <div className="d-flex flex-column mtz-gap-16">
                                    <div className="d-flex justify-content-between">
                                        <button ref={modalAddCompMemRef} data-toggle='collapse' data-target='#modal-add-comp-mem'
                                            className="btn btn-outline-primary btn-sm">Invite member <span className="fa fa-plus"></span></button>

                                        <NavLink target="_blank" to={"/profile/" + company.profileName + "/members/invites"} className="btn btn-link">
                                            Manage Invites
                                        </NavLink>
                                    </div>

                                    <div className="collapse alert alert-secondary" id="modal-add-comp-mem">
                                        <div className="form-group">
                                            <label>Email:</label>
                                            <div className="input-group">
                                                <input type="email" className="form-control" placeholder="E.g. test@example.com"
                                                    value={targetEmail || ''} onChange={e => setTargetEmail(e.target.value || '')} />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" onClick={validateAndAddEmail}>
                                                        add
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            {
                                                selectedEmails.length === 0 &&
                                                <div className="text-center text-muted">No email selected</div>
                                            }

                                            {
                                                selectedEmails.length > 0 &&
                                                <ul className="list-group">
                                                    {
                                                        selectedEmails.map((email) => (
                                                            <li key={email} className="list-group-item d-flex align-items-center justify-content-between">
                                                                {email} <button onClick={() => setSelectedEmails(selectedEmails.filter((e) => e !== email))}
                                                                    className="btn btn-outline-danger btn-sm">
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                        </div>

                                        <div className="form-group">
                                            <div className="text-right">
                                                <button className="btn btn-primary btn-sm" onClick={sendInvites}>
                                                    Send Invite(s)
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="d-flex flex-column">
                                {
                                    mems &&
                                    <ul className="list-group">
                                        {
                                            mems.filter((mem) => !!mem).map((mem) => (
                                                <div key={mem.userId} className="list-group-item p-2">
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            (mem.user && mem.user.logo) ?
                                                                <img src={companyService.getBackendHost() + mem.user.logo}
                                                                    alt="profilePic" width="32px" height="32px" /> :
                                                                <i className="fa fa-user text-muted" width="32px" height="32px" />
                                                        }

                                                        <div className="ml-2 flex-grow-1">
                                                            <h6 className="mtz-h6 mb-0">
                                                                {mem.user && (mem.user.firstname + " " + mem.user.lastname)} {mem.userId === company.ownerId ? "(admin)" : ""}
                                                            </h6>
                                                            <p className="mb-0">{mem.user && mem.user.email}</p>
                                                        </div>

                                                        {
                                                            company.ownerId === me.userId &&
                                                            company.ownerId !== mem.userId &&
                                                            <button onClick={() => deleteMem(mem)}
                                                                className="btn btn-outline-danger btn-sm">
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default CompMems