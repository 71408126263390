import Box from "@material-ui/core/Box";
import React from "react";

import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
import ShareLinkButton from "../../../shared2/ShareLinkButton";
import CGEventBannerManager from "../CGEventBannerManager";
import CGEventBasicInfoViewEdit from "../CGEventBasicInfoViewEdit";
import CGEventInterestedButton from "../CGEventInterestedButton";

const { companyService } = mtzApis;

export default function CGEventDetail({ profileName, cgEventId }) {
  const bannerLogoRef = React.useRef();
  // const avatar = React.useRef();

  let [cgEvent, setCGEvent] = React.useState(null);
  let [bannerLogoWidth, setBannerLogoWidth] = React.useState();

  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    (async () => {
      window.addEventListener("resize", getBannerLogoWidth);
      let cgEvent;
      if (cgEventId) {
        const cgEvents = await companyService.getCGEvents(
          `cgEventIds=${cgEventId}`
        );
        cgEvent = cgEvents && cgEvents.length > 0 ? cgEvents[0] : null;
      }
      if (profileName) {
        const cgEvents = await companyService.getCGEvents(
          `profileNames=${profileName}`
        );
        cgEvent = cgEvents && cgEvents.length > 0 ? cgEvents[0] : null;
      }

      if (cgEvent === null) return;

      setCGEvent(cgEvent);
      getBannerLogoWidth();
    })();
    return () => {
      window.removeEventListener("resize", getBannerLogoWidth);
    };
  }, [profileName, cgEventId]);

  const getBannerLogoWidth = () => {
    if (!bannerLogoRef || !bannerLogoRef.current) return;

    let w = bannerLogoRef.current.offsetWidth;
    setBannerLogoWidth(w);
  };

  const renderIconMenu = () => {
    return (
      <div className="d-flex mtz-gap-4 flex-wrap align-items-center mtz-rounded-16">
        <CGEventInterestedButton
          cgEventId={cgEvent.id}
          profileName={profileName}
        />

        <ShareLinkButton link={window.location.href} />
      </div>
    );
  };

  return (
    <div>
      {cgEvent && (
        <Box className="bg-white mtz-rounded-16">
          <div ref={bannerLogoRef} className="w-100">
            <CGEventBannerManager
              width={bannerLogoWidth}
              profileName={profileName}
              cgEventId={cgEventId}
            />

            {/* <div className="rounded-circle shadow-sm bg-light" ref={avatar}>
              <CGEventLogoManager
                width={bannerLogoWidth / 5}
                profileName={profileName}
                cgEventId={cgEventId}
              />
            </div> */}
          </div>

          <Box className="mtz-p-16">
            {renderIconMenu()}

            <CGEventBasicInfoViewEdit
              profileName={profileName}
              cgEventId={cgEventId}
            />
          </Box>
        </Box>
      )}
    </div>
  );
}
