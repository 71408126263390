import TextField from "@material-ui/core/TextField";
import React from "react";

export default function LimitedCharTextField({
  rows,
  attribute,
  value,
  required,
  label,
  limit,
  onChange,
  onBlur,
  className,
  placeholder,
  variant,
  fullWidth
}) {
  const [content, setContent] = React.useState('');
  const counter = content.length;

  React.useEffect(() => {
    setContent(value);
  }, [value]);

  const setFormattedContent = React.useCallback(
    (event) => {
      let newValue = event.target.value;
      if (newValue.length > limit) {
        setContent(newValue.slice(0, limit));
      } else {
        setContent(newValue);
      }

      onChange(event);
    },
    [limit, onChange, setContent]
  );

  const handleChange = (event) => {
    onBlur(event);
  };

  return (
    <div>
      <small
        className={
          counter < limit - 10
            ? "text-success float-right"
            : "text-danger float-right"
        }
      >
        {counter}/{limit}
      </small>
      <b>{label || " Summary"}</b>
      <textarea
        className={"form-control " + className}
        rows={rows}
        required={required}
        onBlur={(event) => handleChange(event)}
        onChange={(event) => setFormattedContent(event)}
        value={content}
        placeholder={placeholder}
        inputProps={{ maxLength: limit }}
      />
    </div>
  );
}

LimitedCharTextField.defaultProps = {
  onChange: () => undefined,
  onFocus: () => undefined,
  onBlur: () => undefined,
  required: false,
  disabled: false,
  limit: 25,
  variant: "outlined",
  fullWidth: false,
  value: '',
  className: ''
};