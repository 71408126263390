import React from "react";
import ChatButton2 from "../../../../components/chat-service/messages/ChatButton2";
import CompanyBasicInfoViewEdit from "../../../../components/company-service/companies/CompanyBasicInfoViewEdit";
import CompanyDetailNavBar from "../../../../components/company-service/companies/CompanyDetailNavBar";
import CompanyReportIconButton from "../../../../components/company-service/companies/CompanyReportIconButton";
import CompanyBannerManager from "../../../../components/company-service/companies2/CompanyBannerManager";
import CompanyLogoManager from "../../../../components/company-service/companies2/CompanyLogoManager";
import CompanyNoteButton2 from "../../../../components/company-service/companyNotes/CompanyNoteButton2";
import ConnectButton from "../../../../components/network-service/connections/ConnectButton";
import FollowButton from "../../../../components/network-service/follows/FollowButton";
import CompanyWatchlistAddButton from "../../../../components/shared2/CompanyWatchlistAddButton";
import mtzApis from "../../../../services";
import CompMems from "./CompMems";

const { companyService } = mtzApis;

function LogoBanner({ companyId, profileName }) {
  const bannerLogoRef = React.useRef();
  const avatar = React.useRef();
  let [bannerLogoWidth, setBannerLogoWidth] = React.useState();
  let [company, setCompany] = React.useState(null);
  let [compSearch, setCompSearch] = React.useState(null);
  let [compMembers, setCompMembers] = React.useState(null);

  const getBannerLogoWidth = () => {
    if (!bannerLogoRef || !bannerLogoRef.current) return;

    let w = bannerLogoRef.current.offsetWidth;
    setBannerLogoWidth(w);
    avatar.current.style.display = "inline-block";
    avatar.current.style.position = "relative";
    avatar.current.style.top = `-${w / 7}px`;
    avatar.current.style.left = `${w / 16}px`;
    bannerLogoRef.current.style.marginBottom = `-${w / 7}px`;
  };

  React.useEffect(() => {
    window.addEventListener("resize", getBannerLogoWidth);

    (async () => {
      if (profileName) {
        let companies = await companyService.getCompanies(
          new URLSearchParams(`profileNames=${profileName}`)
        );
        company = companies && companies.length > 0 ? companies[0] : undefined;
      }

      if (!company)
        if (companyId) {
          let companies = await companyService.getCompanies(
            new URLSearchParams(`companyIds=${companyId}`)
          );
          company = companies && companies.length > 0 ? companies[0] : undefined;
        }

      if (company) {
        let companySearches = await companyService.getCompanySearches(new URLSearchParams(`companyIds=${company.companyId}`));
        setCompSearch(companySearches[0]);
      }

      setCompany(company);
      getBannerLogoWidth();
    })();

    return () => {
      window.removeEventListener("resize", getBannerLogoWidth);
    };
  }, [companyId, profileName]);

  const onReportHandler = async (company, report) => {
    mtzApis.startSpinningIcon();
    if (profileName) {
      let companySearch = await companyService.getCompanySearches(new URLSearchParams(`profileNames=${profileName}`));
      let reported = await companyService.createCompanySearchReport(companySearch[0].id, report);
      mtzApis.stopSpinningIcon();

      if (reported) {
        window.location.href = "/";
      }
      else {
        alert("Server Err")
      }
    }
  };

  if (company === null)
    return 'Loading...';

  if (company !== null && !company)
    return 'Company not found';

  return (
    <div className="d-flex flex-column w-100 mtz-gap-16">
      <div className="bg-white mtz-rounded-16 w-100 mtz-gap-16 pb-2 d-flex flex-column">
        <div ref={bannerLogoRef} className="w-100">
          <div className="w-100">
            <CompanyBannerManager
              width={bannerLogoWidth}
              profileName={company.profileName}
              companyId={company.companyId}
            />
          </div>
          <div className="d-flex">
            <div ref={avatar} className="rounded-circle shadow-sm bg-light">
              <CompanyLogoManager
                width={bannerLogoWidth / 5}
                profileName={company.profileName}
                companyId={company.companyId}
              />
            </div>
          </div>
        </div>

        <div className="d-flex mtz-gap-4 justify-content-end mx-1 flex-wrap">
          <div style={{ marginRight: "4px" }}>
            <CompanyReportIconButton
              style={{ height: "30px" }}
              onReport={(report) => onReportHandler(company, report)}
            />
          </div>
          <div>
            <CompanyWatchlistAddButton
              profileName={company.profileName}
              companyId={company.companyId}
              style={{ height: "30px" }}
            />
          </div>
          <div>
            <CompanyNoteButton2
              profileName={company.profileName}
              companyId={company.companyId}
              style={{ height: "30px" }}
            />
          </div>
          <div>
            <ConnectButton targetUserId={company.ownerId} size='sm' title="Connect" />
          </div>
          <div>
            <FollowButton
              companyId={company.companyId}
              profileName={company.profileName}
              style={{ width: "80px", height: "30px" }}
            />
          </div>
          <div>
            <ChatButton2 targetUserId={company.ownerId} className='btn btn-primary btn-sm' title="Message Us" />
          </div>
        </div>

        <div className="d-flex mtz-gap-4 mx-2 justify-content-end flex-wrap align-items-center">
          <CompMems company={company} />
          |
          <button className="btn btn-link text-muted btn-sm">
            Follower(s) {compSearch ? compSearch.followerCount : "0"}
          </button>
        </div>

        <CompanyBasicInfoViewEdit companyId={company.companyId} profileName={company.profileName} />
      </div>

      <div>
        <CompanyDetailNavBar profileName={company.profileName} />
      </div>
    </div >
  );
}

export default LogoBanner;
