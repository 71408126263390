import React from "react";


import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
import CompanyNoteCreateForm2 from "../CompanyNoteCreateForm2";
import CompanyNoteUpdateForm from "../CompanyNoteUpdateForm";
import "./style.css";


const { companyService } = mtzApis;

export default function CompanyNoteButton2({ companyId, profileName, style = {} }) {
  // let [message, setMessage] = React.useState(null);
  // let [snackbarOpen, setSnackBarOpen] = React.useState(false);
  let [notes, setNotes] = React.useState([]);
  let [noteStats, setNoteStats] = React.useState(null);
  let [myComp, setMyComp] = React.useState(false);
  let [company, setCompany] = React.useState(null);
  let [isCreatingNote, setIsCreatingNote] = React.useState(false);
  let [selectedNote, setSelectedNote] = React.useState(null);
  const addFormRef = React.useRef();

  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    init();
  }, [companyId, profileName]);

  const init = async () => {
    let myComp = await companyService.getMyCompany();
    if (myComp) {
      setMyComp(myComp);
    }

    let company;
    if (profileName) {
      let companies = await companyService.getCompanies(
        new URLSearchParams(`profileNames=${profileName}`)
      );
      if (companies && companies.length === 1) company = companies[0];
    }

    if (company) {
      notes = await companyService.getCompanyNotes(new URLSearchParams(`companyIds=${company.id}`));
      setNotes(notes || []);

      noteStats = await companyService.getCompanyNoteStats(new URLSearchParams(`companyIds=${company.id}`));
      if (noteStats)
        setNoteStats(noteStats);

      setCompany(company);
    }
  };

  const openNote = async () => {
    if (me && me.userId)
      addFormRef.current.click();
    else {
      const ok = await window.createMtzConfirm(<span>Login to write note? Click <b className="">'Yes'</b> to go to login page.</span>);
      if (!ok) return;
      window.location.href = '/login';
    }
  };

  const onCreateHandler = async (note) => {
    let updatedNotes = [note, ...notes];
    setNotes(updatedNotes);
    setIsCreatingNote(false);
  };

  const onUpdateHandler = async (note) => {
    let updatedNotes = notes.map((item) => (item.id === note.id ? note : item));
    setNotes(updatedNotes);
    setSelectedNote(note);
  };

  const removeNote = async note => {
    let ok = await window.createMtzConfirm("Are you sure?");
    if (!ok) return;

    mtzApis.startSpinningIcon();
    note = await companyService.deleteCompanyNoteById(note.id);
    mtzApis.stopSpinningIcon();

    if (note) {
      alert("Note deleted");
      notes = notes.filter(n => n.id !== note.id);
      setNotes(notes);
      noteStats = { all: { count: noteStats.all.count - 1 } };
      setNoteStats(noteStats);
    }
  };

  return (
    <>
      {company && (
        <React.Fragment>
          {(!myComp || myComp.id !== company.id) && (
            <div>
              <div data-toggle='tooltip' title="Make a private note for your records">
                <button data-toggle='modal' data-target='#add-form-ref'
                  className="text-center btn btn-outline-primary btn-sm"
                >
                  <span className="fa-regular fa-sticky-note"></span>
                  &nbsp;Note
                </button>
              </div>

              <div className="modal" id='add-form-ref'>
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header d-flex">
                      <h5 className="flex-fill mtz-h5"> Add/Update Notes of company '{company.name}'</h5>
                      <button ref={addFormRef} data-dismiss='modal' className="btn btn-sm close"><span className="fa fa-close"></span></button>
                    </div>

                    <div className="modal-body d-flex mtz-gap-16 flex-column">
                      {!isCreatingNote && (
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setIsCreatingNote(true);
                            setSelectedNote(null);
                          }}
                        >

                          Add note <span className="fa fa-plus"></span>
                        </button>
                      )}

                      {
                        isCreatingNote ? (
                          <div>
                            <NavLink to="#" onClick={async () => {
                              setIsCreatingNote(false)
                              let notes = await companyService.getCompanyNotes(
                                new URLSearchParams(`companyIds=${company.id}`)
                              );
                              if (notes && notes.length > 0) {
                                setNotes(notes);
                              }
                            }}>
                              {"<<< Back to Notes"}
                            </NavLink>

                            <CompanyNoteCreateForm2
                              companyId={companyId}
                              profileName={profileName}
                              onCreate={onCreateHandler}
                            />
                          </div>
                        ) : (
                          !selectedNote ?
                            <div>
                              <div className="mtz-card">
                                <h5>Notes: (found: {noteStats ? noteStats.all.count : 0})</h5>

                                <div className="list-group">
                                  {!!notes &&
                                    notes.map((note) => (
                                      <div key={note.id}
                                        className={`list-group-item ` + `mtz-cursor-pointer ${selectedNote && selectedNote.id === note.id ? 'border-primary border' : ''}`}>
                                        <div className="d-flex">
                                          <b onClick={() => setSelectedNote(note)} className="text-primary flex-fill">{note.title || ""}</b>
                                          <button className="btn btn-sm close" onClick={() => removeNote(note)}>
                                            <span className="fa fa-trash"></span>
                                          </button>
                                        </div>
                                        <div >
                                          <Moment fromNow>{note.createdAt}</Moment>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div >
                            </div> :
                            <div>
                              <button className="btn btn-secondary" onClick={() => setSelectedNote(null)}>Cancel editing</button>
                            </div>
                        )}

                      {selectedNote && (
                        <CompanyNoteUpdateForm
                          note={selectedNote}
                          onUpdate={onUpdateHandler}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <Snackbar
            open={snackbarOpen}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            autoHideDuration={5000}
            onClose={() => setSnackBarOpen(false)}
            TransitionComponent={Transition}
            message={message}
            key={message ? message : ""}
          /> */}
        </React.Fragment>
      )}
    </>
  );
}

