import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import React from 'react';
import ImageUploading from "react-images-uploading";

export default function ImageUploader({ onChange, maxNumber, title, value }) {
	const [images, setImages] = React.useState(value);

	React.useEffect(() => {
		setImages(value);
	}, [value]);

	const onImagesChanged = (imageList, addUpdateIndex) => {
		setImages(imageList);
		if (onChange)
			onChange(imageList);
	};

	return (
		<React.Fragment>
			<ImageUploading
				multiple
				value={images}
				onChange={onImagesChanged}
				maxNumber={maxNumber || 5}
				dataURLKey="data_url"
			>
				{({
					imageList,
					onImageUpload,
					onImageRemoveAll,
					onImageUpdate,
					onImageRemove,
					isDragging,
					dragProps
				}) => (
					<Card className="border mtz-rounded-16" elevation={0}>
						<CardHeader
							title={<div className='mtz-h5'>{title || 'Upload images:'}</div>}
						/>

						<CardContent className="py-1">
							<div className="upload__image-wrapper">
								<div className="my-2">
									<button type="button"
										className="btn btn-sm btn-outline-secondary" onClick={onImageRemoveAll}>Remove all images</button>
									&nbsp;
									<button type="button"
										className="btn btn-sm btn-primary"
										style={isDragging ? { color: "red" } : null}
										onClick={onImageUpload}
										{...dragProps}
									>
										Click or Drop here
									</button>
								</div>

								<div className="d-flex flex-wrap mtz-gap-16 justify-content-center">
									{imageList.map((image, index) => (
										<div key={index} className="border rounded p-2 align-items-center justify-content-center d-flex flex-column">
											<img src={image.data_url} alt="" style={{ maxWidth: '150px' }} />
											<div className="flex-fill"></div>
											<div className="d-flex mtz-gap-4">
												<button type="button"
													className="btn text-danger" onClick={() => onImageRemove(index)}>Remove</button>
												<button type="button"
													className="btn text-primary" onClick={() => onImageUpdate(index)}>Edit</button>
											</div>
										</div>
									))}
								</div>
							</div>
						</CardContent>
					</Card>
				)}
			</ImageUploading>
		</React.Fragment>
	);
}