import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getCompanyMembers = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/members?${queries}`;
  let params = { spinnerOn: true };
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

const getCompanyMemberStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/members/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, { spinnerOn: false }, headers);
};

const createCompanyMember = company => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/members`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, company, headers);
};

const getCompanyMemberById = companyId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/members/` + companyId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, companyId, headers);
};

const updateCompanyMemberById = (companyId, company) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/members/${companyId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, company, headers);
};

const deleteCompanyMemberById = companyId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/members/${companyId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, companyId, headers);
};

export {
  createCompanyMember,
  deleteCompanyMemberById,
  getCompanyMemberById,
  getCompanyMembers,
  getCompanyMemberStats,
  updateCompanyMemberById
};

