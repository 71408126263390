import React from 'react';

import Moment from 'react-moment';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import AuthContext from '../../../../AuthContext';
import mtzApis from '../../../../services';
import PaymentSelector from '../../../payment-service/general/PaymentSelector';
import LimitedCharReactQuill from '../../../shared2/reactQuills/LimitedCharReactQuill';

const { companyService, paymentService } = mtzApis;

function CGSponsorSubscriptionButton({ profileName, companyGroupId }) {
    let [group, setGroup] = React.useState(null);
    let [plans, setPlans] = React.useState(null);
    let [myMem, setMyMem] = React.useState(null);
    let [sub, setSub] = React.useState();
    let [mySponsor, setMySponsor] = React.useState(null);
    const me = React.useContext(AuthContext);
    const modalSponsorPlanSelectRef = React.useRef();
    const modalSponsorManagerRef = React.useRef();

    React.useEffect(() => {
        let isMounted = true;
        init(isMounted);
        return () => { isMounted = false; };
    }, []);

    const init = async (isMounted) => {
        if (profileName) {
            let groups = await companyService.getCompanyGroups(new URLSearchParams(`profileName=${profileName}`));
            if (groups && groups.length > 0) {
                group = groups[0];
                if (isMounted) setGroup(group);
            }
        } else if (companyGroupId) {
            let groups = await companyService.getCompanyGroups(new URLSearchParams(`companyGroupId=${companyGroupId}`));
            if (groups && groups.length > 0) {
                group = groups[0];
                if (isMounted) setGroup(group);
            }
        }

        if (group) {
            plans = await companyService.getCGSponsorPlans(new URLSearchParams(`companyGroupIds=${group.id}`));
            let prices = await companyService.getCGSponsorPlanPrices(new URLSearchParams(`companyGroupIds=${group.id}`));
            let map = {};
            prices.forEach(p => {
                if (!map[p.cgSponsorPlanId])
                    map[p.cgSponsorPlanId] = [];
                map[p.cgSponsorPlanId].push(p);
            });
            plans.forEach(pl => pl.prices = map[pl.id] || []);
            if (isMounted) setPlans(plans || undefined);

            let mems = await companyService.getCompanyGroupMembers(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));
            if (isMounted) setMyMem(mems && mems.length > 0 ? mems[0] : undefined);

            let sponsors = await companyService.getCompanyGroupSponsors(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));
            if (sponsors) setMySponsor(sponsors && sponsors.length > 0 ? sponsors[0] : undefined);

            let subs = await companyService.getCGSponsorPlanSubscriptions(new URLSearchParams(`companyGroupIds=${group.id}`));
            if (subs && subs.length > 0) {
                sub = subs[0];
                sub.price = await companyService.getCGSponsorPlanPriceById(sub.cgSponsorPlanPriceId);
                sub.plan = await companyService.getCGSponsorPlanById(sub.cgSponsorPlanId);
                setSub(sub);
            }
        }
    };

    const updateCancel = async update => {
        if (update.cancelled) {
            const ok = await window.createMtzConfirm(`After cancellation, your sponsorship will remain until the end of the current billing cycle. Please confirm.`);
            if (!ok) return;
        }

        mtzApis.startSpinningIcon();
        let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, update);
        if (updated) {
            alert(`Sponsorship ${updated.cancelled ? 'Unsubscribed' : 'Re-subscribed'}`);
            setTimeout(() => window.location.reload(), 500);
        }
        mtzApis.stopSpinningIcon();
    };

    const updatePrice = async (pl, pr) => {
        if (sub.plan.rank === pl.rank) {
            const ok = await window.createMtzConfirm(`You are changing the billing cycle, this will apply on the next billing. Proceed?`);
            if (!ok) return;

            mtzApis.startSpinningIcon();
            let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, { _switchToPriceId: pr.id });
            mtzApis.stopSpinningIcon();

            if (updated) {
                alert(`Sponsorship plan's billing cycle updated`);
                window.location.reload();
            }
        } else {
            if (sub.plan.rank > pl.rank) {
                const ok = await window.createMtzConfirm(`You are downgrading the plan from ${sub.plan.name} to ${pl.name}, this will be applied on the next billing. Proceed?`);
                if (!ok) return;

                mtzApis.startSpinningIcon();
                let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, { _downgradeToPriceId: pr.id });
                mtzApis.stopSpinningIcon();

                if (updated) {
                    alert(`Sponsorship plan downgraded`);
                    window.location.reload();
                }
            } else {
                const ok = await window.createMtzConfirm(`You are upgrading the plan from ${sub.plan.name} to ${pl.name}, this will be applied immediately, and you can access the extra features. Proceed?`);
                if (!ok) return;

                mtzApis.startSpinningIcon();
                let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, { _upgradeToPriceId: pr.id });
                mtzApis.stopSpinningIcon();

                if (updated) {
                    alert(`Sponsorship plan upgraded`);
                    window.location.reload();
                }
            }
        }
    };

    const leaveGroup = async () => {
        const ok = await window.createMtzConfirm(`Are you sure? If you have a sponsorship subscription, it will be cancelled immediately. Also, It will removed in the next billing cycle`);
        if (!ok) return;

        mtzApis.startSpinningIcon();
        let del = await companyService.deleteCompanyGroupSponsorById(mySponsor.id);

        if (del) {
            alert(`Your sponsorship has been removed`);
            setTimeout(() => window.location.reload(), 500);
        }
        mtzApis.stopSpinningIcon();
    };

    const retryPayment = async () => {
        mtzApis.startSpinningIcon();
        let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, { _recharge: true });
        mtzApis.stopSpinningIcon();

        if (updated) {
            alert('Payment retry succeeded');
            setTimeout(() => window.location.reload(), 500);
        }
    };


    if (!profileName && !companyGroupId)
        return <div>
            Please provide at least profileName or companyGroupId
        </div>

    if (myMem && myMem.role === 'ADMIN')
        return '';

    if (!me || !me.userId)
        return (
            <button className='btn btn-primary' onClick={() => alert('Please login to be a sponsor')}>
                become group sponsor
            </button>
        );

    return (
        <>
            {group === null && plans === null && myMem === null && <div>Loading...</div>}

            {group !== null && !group && 'Error: no group found'}

            {plans !== null && !plans && ''}

            {
                group && plans && !!myMem &&
                <>
                    {
                        !sub &&
                        <>
                            <button className='btn btn-primary' ref={modalSponsorPlanSelectRef}
                                data-toggle='modal' data-target='#modal-sponsor-plan-select'>
                                become group sponsor
                            </button>
                            <div className='modal' id='modal-sponsor-plan-select'>
                                <div className='modal-dialog modal-dialog-scrollable modal-md'>
                                    <div className='modal-content'>
                                        <div className='modal-header'>
                                            <div className='d-flex w-100 align-items-center'>
                                                <h5 className='mtz-h5 flex-grow-1'>Sponsorship Subscription:</h5>
                                                <button className='btn btn-sm close' onClick={() => modalSponsorPlanSelectRef.current.click()}>
                                                    <span className='fa fa-close'></span>
                                                </button>
                                            </div>
                                        </div>

                                        <div className='modal-body'>
                                            <SponsorSubscribeMenu group={group} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        sub &&
                        <>
                            {
                                mySponsor ?
                                    <>
                                        <div className='dropdown'>
                                            <button data-toggle='dropdown'
                                                className={`btn btn-secondary`}>
                                                {sub.cancelled && 'sponsorship unsubscribed'}
                                                {!sub.cancelled && 'sponsorship subscribed'}
                                            </button>

                                            <div className="dropdown-menu">
                                                <a ref={modalSponsorManagerRef} className="dropdown-item" href="#" data-toggle='modal' data-target='#sponsorship-manage-popup'>Manage sponsorship subscription</a>

                                                {
                                                    sub.cancelled &&
                                                    <a href='' className="dropdown-item" onClick={() => updateCancel({ cancelled: false })}>
                                                        Re-subscribe sponsorship <span className="text-danger">(access removed <Moment fromNow>{sub.nextExpiredAt}</Moment>)</span>
                                                    </a>
                                                }

                                                <a className="dropdown-item text-danger" onClick={() => leaveGroup()} href="#">Remove sponsorship</a>
                                            </div>
                                        </div>

                                        <div className="modal" id='sponsorship-manage-popup'>
                                            <div className="modal-dialog modal-dialog-scrollable modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <div className="d-flex w-100">
                                                            <h5 className="mtz-h5 flex-fill">Update group sponsorship subscription:</h5>
                                                            <button className="btn-sm btn" onClick={() => modalSponsorManagerRef.current.click()}>
                                                                <span className="fa fa-close"></span>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="modal-body">
                                                        <div>
                                                            <h5 className="text-muted"><u>Current subscription:</u></h5>
                                                            <div className='mtz-h5 text-primary'><b>Plan name:</b> {sub.plan.name}</div>
                                                            <div><b>Plan price:</b> ${sub && sub.price && sub.price.cents / 100} {sub.type.toUpperCase() + 'LY'}</div>
                                                            <div><b>Subscription status:</b> {sub.cancelled ? <span className="text-danger">UNSUBSCRIBED</span> : <span className="text-success">SUBSCRIBED</span>}</div>
                                                            <div><b>Date expired:</b> <Moment fromNow>{sub.nextExpiredAt}</Moment></div>
                                                            <div><b>Date started:</b> <Moment fromNow>{sub.createdAt}</Moment></div>
                                                            <div>
                                                                <b>Next billing cycle plan:</b> <span className='text-primary'>{(() => {
                                                                    let nextPriceId = sub.willDowngradeToPriceId ? sub.willDowngradeToPriceId : sub.cgSponsorPlanPriceId;
                                                                    let nextPlan = plans.find(p => {
                                                                        return p.prices.find(pr => pr.id === nextPriceId);
                                                                    });
                                                                    return nextPlan.name;
                                                                })()} ${(() => {
                                                                    let nextPriceId = sub.willDowngradeToPriceId ? sub.willDowngradeToPriceId : sub.cgSponsorPlanPriceId;
                                                                    let prices = [];
                                                                    plans.forEach(pl => prices = [...prices, ...(pl.prices || [])]);
                                                                    let nextPrice = prices.find(pr => pr.id === nextPriceId);
                                                                    return `${nextPrice.cents / 100}/${nextPrice.type.toLowerCase()}ly`;
                                                                })()}</span>
                                                            </div>
                                                        </div>

                                                        <hr />

                                                        <div>
                                                            <h5 className="text-muted"><u>Change subscription plan:</u></h5>

                                                            {
                                                                plans && plans.map(pl => (
                                                                    <div key={pl.id} className="border rounded my-1 mtz-p-16">
                                                                        <h5 className='mtz-h5'><b>Plan name:</b> <span className='text-primary'>{pl.name} - Rank: {pl.rank} <small className='text-danger mtz-b3'>(* Higher rank, more visible)</small></span></h5>
                                                                        <div className='d-flex mtz-gap-4'>
                                                                            {
                                                                                pl.prices.length > 0 ?
                                                                                    pl.prices.map(pr => (
                                                                                        <button onClick={() => updatePrice(pl, pr)}
                                                                                            disabled={sub.cgSponsorPlanPriceId === pr.id} key={pr.id}
                                                                                            className='btn btn-outline-primary'>
                                                                                            <div><b>Price:</b> ${pr.cents / 100} {pr.type === 'month' ? 'MONTHLY' : 'YEARLY'} {sub.cgSponsorPlanPriceId === pr.id && <small><i className='text-danger'>(currently subscribed)</i></small>}</div>
                                                                                        </button>
                                                                                    )) :
                                                                                    <span>No price found</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }

                                                            <hr />
                                                            <div className="text-right">
                                                                {
                                                                    !sub.cancelled ?
                                                                        <button className="btn btn-outline-danger" onClick={() => updateCancel({ cancelled: true })}>
                                                                            Unsubscribe sponsorship
                                                                        </button> :
                                                                        <button className="btn btn-outline-success" onClick={() => updateCancel({ cancelled: false })}>
                                                                            Re-subscribe sponsorship <span className="text-danger">(access removed <Moment fromNow>{sub.nextExpiredAt}</Moment>)</span>
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        {
                                            sub.cancelled &&
                                            <button onClick={() => updateCancel({ cancelled: false })} className='btn btn-outline-danger btn-sm'>
                                                sponsorship removed and unsubscribed, be sponsor again?
                                            </button>
                                        }

                                        {
                                            sub.paymentError &&
                                            <div>
                                                <div className="my-1 alert alert-danger mtz-rounded-16">
                                                    <div><b>Sponsor-subscription error</b></div>
                                                    <div>{sub.paymentError}.</div>
                                                    <div>
                                                        Please click <NavLink to='/me/billing/payment-accounts' target='_blank'>
                                                            HERE
                                                        </NavLink> to update payment info.
                                                    </div>
                                                    <div>
                                                        Then click <button className="btn btn-sm btn-outline-primary" onClick={retryPayment}>Retry payment</button>.
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                            }
                        </>
                    }
                </>
            }
        </>
    );
}

function SponsorSubscribeMenu({ group }) {
    const [view, setView] = React.useState('list');
    let [plans, setPlans] = React.useState(null);
    let [active, setActive] = React.useState(null);
    let [activePrice, setActivePrice] = React.useState(null);
    let [cards, setCards] = React.useState(null);
    let [billAddrs, setBillAddrs] = React.useState(null);
    let [wallet, setWallet] = React.useState(null);
    let [calc, setCalc] = React.useState(null);
    let [bannerFile, setBannerFile] = React.useState(null);
    let [myCompany, setMyCompany] = React.useState(null);
    let [paymentMethod, setPaymentMethod] = React.useState();
    let [paymentInstrumentId, setPaymentInstrumentId] = React.useState();
    let [couponObjs, setCouponObjs] = React.useState([]);
    let [myCustomer, setMyCustomer] = React.useState(null);

    const bannerRef = React.useRef();
    let [coupon, setCoupon] = React.useState('');
    let [sponsorDetails, setSponsorDetails] = React.useState({
        description: '',
        adLink: ''
    });
    const me = React.useContext(AuthContext);
    const modalSponsorSubRef = React.useRef();
    const [isSponsorModalOpen, setIsSponsorModalOpen] = React.useState(false);
    const [useProfileAsRedirect, setUseProfileAsRedirect] = React.useState(false);



    // const toggleView = () => {
    //     setView(view === 'list' ? 'grid' : 'list');
    // };

    React.useEffect(() => {
        let mounted = true;

        (async () => {
            let company = await companyService.getMyCompany();
            if (company)
                setMyCompany(company)
            plans = await companyService.getCGSponsorPlans(new URLSearchParams(`companyGroupIds=${group.id}`));
            if (plans) {
                let reqs = plans.map(pl => companyService.getCGSponsorPlanSubscriptionStats(new URLSearchParams(`cgSponsorPlanIds=${pl.id}`)));
                let statList = (await Promise.allSettled(reqs)).map(res => res.value);
                for (let i = 0; i < plans.length; i++)
                    plans[i].subStats = statList[i];

                let prices = await companyService.getCGSponsorPlanPrices(new URLSearchParams(`companyGroupIds=${group.id}`));
                let map = {};
                prices.forEach(p => {
                    if (!map[p.cgSponsorPlanId])
                        map[p.cgSponsorPlanId] = [];
                    map[p.cgSponsorPlanId].push(p);
                });
                plans.forEach(pl => pl.prices = map[pl.id] || []);
            }
            if (mounted) setPlans(plans || []);

            if (me && me.userId) {
                // load cards
                cards = await paymentService.getCards(new URLSearchParams(``));
                if (mounted) setCards(cards);

                // load billing addresses
                billAddrs = await paymentService.getBillingAddresses(new URLSearchParams(``));
                if (mounted) setBillAddrs(billAddrs);

                // get wallet
                wallet = await paymentService.getMyWallet();
                if (mounted) setWallet(wallet || undefined);

                // load my customer
                let myCustomer = await paymentService.getMyMtzCustomer();
                if (myCustomer) {
                    setMyCustomer(myCustomer);
                    if (myCustomer.paymentMethod) setPaymentMethod(myCustomer.paymentMethod);

                    if (myCustomer.paymentMethod === 'CARD') {
                        let cards = await paymentService.getCards(new URLSearchParams(``));
                        if (cards) {
                            let defaultCard = cards.find(c => c.asDefault);
                            if (defaultCard) {
                                setPaymentInstrumentId(defaultCard.id);
                            }
                        }
                    }
                }
            }

        })();

        return () => mounted = false;
    }, []);

    const selectPlan = async plan => {
        setActive(plan);
    };

    const updateSponsorDetails = async () => {
        mtzApis.startSpinningIcon();
        let sponsors = await companyService.getCompanyGroupSponsors(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));

        // Update sponsor text and link
        let response = await companyService.updateCompanyGroupSponsorById(
            sponsors[0].id,
            { text: sponsorDetails.description, adLink: sponsorDetails.adLink }
        );


        // Upload sponsor banner
        if (bannerFile) {
            const data = new FormData();
            data.append("bannerFile", new File([bannerFile.blob], 'bannerFile.' + bannerFile.ext));
            response = await companyService.updateCompanyGroupSponsorBannerById(sponsors[0].id, data);
        }

        mtzApis.stopSpinningIcon();

        alert(`Thank you for your subscription, we are processing your payment. We will send you an email when it is done.`);
        window.location.reload();
    };

    const subscribe = async () => {
        const params = {
            cgSponsorPlanPriceId: activePrice.id
        };
        if (paymentMethod) params.paymentMethod = paymentMethod;
        if (paymentInstrumentId) params.paymentInstrumentId = paymentInstrumentId;
        if (couponObjs) params.couponCodes = couponObjs.map(c => c.code);

        mtzApis.startSpinningIcon();
        let created = await companyService.createCGSponsorPlanSubscription(params);
        mtzApis.stopSpinningIcon();

        if (created) {
            alert(`Payment success! Thank you for your subscription to the group ${group.name}.`);
            window.location.reload();
        }
    };

    const updatePrice = async updateParams => {
        const ok = await window.createMtzConfirm(`This new plan will start in the next billing cycle, proceed?`);
        if (!ok) return;

        mtzApis.startSpinningIcon();
        let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, updateParams);
        mtzApis.stopSpinningIcon();

        if (updated) {
            alert(`Sponsorship plan updated`);
            window.location.reload();
        }
    };

    const leaveGroup = async () => {
        const ok = await window.createMtzConfirm(`Are you sure? If you have a group subscription, it will be cancelled immediately. Also, It will removed in the next billing cycle`);
        if (!ok) return;

        mtzApis.startSpinningIcon();
        let del = await companyService.deleteCompanyGroupSponsorById(mem.id);

        if (del) {
            alert(`You have lost access to this group`);
            setTimeout(() => window.location.reload(), 500);
        }
        mtzApis.stopSpinningIcon();
    };

    const updateCancel = async update => {
        if (update.cancelled) {
            const ok = await window.createMtzConfirm(`After cancellation, your sponsorship will remain until the end of the current billing cycle. Please confirm.`);
            if (!ok) return;
        }

        mtzApis.startSpinningIcon();
        let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, update);
        if (updated) {
            alert(`Sponsorship ${updated.cancelled ? 'Unsubscribed' : 'Re-subscribed'}`);
            setTimeout(() => window.location.reload(), 500);
        }
        mtzApis.stopSpinningIcon();
    };

    const retryPayment = async () => {
        mtzApis.startSpinningIcon();
        let updated = await companyService.updateCGSponsorPlanSubscriptionById(sub.id, { _recharge: true });
        mtzApis.stopSpinningIcon();

        if (updated) {
            alert('Payment retry succeeded');
            setTimeout(() => window.location.reload(), 500);
        }
    };

    const selectPrice = async pr => {
        activePrice = pr;
        setActivePrice(pr);
        calcSubPrice();
    };

    const calcSubPrice = async () => {
        if (!activePrice) return;

        let codes = [];
        if (couponObjs)
            codes = couponObjs.map(c => c.code);
        if (coupon)
            codes.push(coupon);

        let params = {
            cents: activePrice.cents,
            sellerUserId: group.ownerId,
            buyerUserEmail: me.email,
            couponCodes: [...new Set(codes)],
            _dryRun: true
        };
        if (paymentMethod)
            params.paymentMethod = paymentMethod;
        if (paymentInstrumentId)
            params.paymentInstrumentId = paymentInstrumentId;

        let created = await paymentService.createMtzCharge(params);
        if (created) {
            setCalc(created);
            setCoupon('');
            let couponData = await paymentService.getMtzCoupons(new URLSearchParams(`codes=${codes}`));
            setCouponObjs(couponData);
        }
    };

    return (
        <>
            <div className="mtz-card">

                <h5>Sponsorship Plans:</h5>

                {plans === null && 'Loading...'}

                {plans && plans.length === 0 && 'No sponsorship plan found'}

                {view === 'list' && (
                    <>
                        {plans && plans.length > 0 && (
                            <div className="d-flex flex-column mtz-gap-16">
                                {plans.map(p => (
                                    <div key={p.id} className="">
                                        <div className='card'>
                                            <div className='card-header'>
                                                <b>Name:</b> <span className='mtz-h4 text-muted'>
                                                    {p.name}
                                                </span>
                                            </div>

                                            <div className='card-body'>
                                                {p.description && <div><b>Description:</b> {p.description}</div>}
                                                <div><b>Number of subscriptions allowed:</b> {p.subscriptionMax}</div>
                                                <div>
                                                    <div><b>Rank:</b> {p.rank} - <small className="">(*Higher rank, more visible. 1 is the lowest rank)</small></div>
                                                </div>
                                                <div><b>Number of remaining subscriptions :</b> {p.subscriptionMax - p.subStats.all.count}</div>
                                            </div>

                                            <div className='card-footer text-right'>
                                                <button className='btn btn-link'
                                                    onClick={() => selectPlan(p)} data-toggle="modal"
                                                    data-target="#modal-sponsor-sub" ref={modalSponsorSubRef}>
                                                    Subscribe
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}
                {view === 'grid' && (
                    <>
                        {/* Grid View */}
                        <div className="row d-inline-block d-md-flex flex-column">
                            {plans && plans.length > 0 && plans.map(p => (
                                <div key={p.id} className="col-12 col-md-6 mb-4 mtz-rounded-16">
                                    <div key={p.id} className="list-group-item d-flex flex-column mtz-gap-8">
                                        <div>
                                            <b>Name:</b> <NavLink onClick={() => selectPlan(p)} data-toggle="modal"
                                                data-target="#modal-sponsor-sub" to="#" ref={modalSponsorSubRef}>
                                                {p.name}
                                            </NavLink>
                                        </div>
                                        {p.description && <div><b>Description:</b> {p.description}</div>}
                                        <div><b>Number of subscriptions allowed:</b> {p.subscriptionMax}</div>
                                        <div>
                                            <div><b>Rank:</b> {p.rank} - <small className="">(*Higher rank, more visible. 1 is the lowest rank)</small></div>
                                        </div>
                                        <div><b>Number of remaining subscriptions :</b> {p.subscriptionMax - p.subStats.all.count}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>

            <div className="modal" id='modal-sponsor-sub'>
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="d-flex w-100">
                                <h5 className="mtz-h5 flex-fill">Select Payment Cycle:</h5>
                                <button className='btn btn-sm close' onClick={() => modalSponsorSubRef.current.click()}>
                                    <span className='fa fa-close'></span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            {
                                active && (
                                    !activePrice ?
                                        <div className="d-flex flex-column mtz-gap-16">
                                            {active.prices.length === 0 && 'No payment plan found. This sponsorship plan may not be ready yet.'}

                                            {
                                                active.prices.length > 0 &&
                                                <div className="list-group">
                                                    {
                                                        active.prices.map(pr => (
                                                            <div className="list-group-item" key={pr.id}>
                                                                <div className="d-flex mtz-gap-4 align-items-center">
                                                                    <span>
                                                                        <b>Price:</b> ${pr.cents / 100}/{pr.type.toLowerCase()}
                                                                    </span>
                                                                    |
                                                                    <button onClick={() => selectPrice(pr)}
                                                                        className="btn btn-outline-primary btn-sm">
                                                                        select
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                        </div> :
                                        <div className="d-flex flex-column mtz-gap-16">
                                            <div>
                                                <button className="btn btn-secondary" onClick={() => selectPrice(null)}>
                                                    <i className="fa fa-arrow-left"></i> Back
                                                </button>
                                            </div>

                                            <h5 className="text-center text-primary mtz-h5">Review & Payment</h5>

                                            {/* <div className="alert alert-info mtz-rounded-16">
                                                <b>Please read:</b> Your wallet will be used to deduct money for the payment. In case your wallet balance is below the payment amount, your default credit card on file will be used to fill the balance of your wallet then deduct the payment. Note the minimum balance refill amount is $15. By clicking on Confirm & Pay, I agree to this terms and conditions.
                                            </div> */}

                                            <PaymentSelector onChange={paymentInfo => {
                                                if (paymentInfo.paymentMethod) {
                                                    paymentMethod = paymentInfo.paymentMethod;
                                                    setPaymentMethod(paymentMethod);
                                                    calcSubPrice();
                                                }

                                                if (paymentInfo.paymentInstrumentId) {
                                                    paymentInstrumentId = paymentInfo.paymentInstrumentId;
                                                    setPaymentInstrumentId(paymentInstrumentId);
                                                }
                                            }} />

                                            {/* coupon */}
                                            <div className="border mtz-p-16 mtz-rounded-16 mtz-gap-8 d-flex flex-column">
                                                <h6 className="mtz-h6 text-muted">Apply coupon:</h6>
                                                <div>
                                                    <div className="w-100 w-md-50">
                                                        <div className="d-flex mtz-gap-4">
                                                            <input onChange={e => {
                                                                // sanitize coupon 6-12 chars
                                                                let c = e.target.value.replace(/[^a-z0-9]/g, '').trim().toLowerCase();
                                                                setCoupon(c);
                                                            }} value={coupon || ''}
                                                                className="form-control" type="text" placeholder="E.g. abc123" />
                                                            <button className="btn btn-primary" onClick={calcSubPrice}>
                                                                Apply</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <small>* Coupon only has lowercased letters and numbers</small>
                                                {

                                                    couponObjs && couponObjs.length > 0 &&
                                                    <div>
                                                        <h6 className="mtz-h6"><u>Coupon applied:</u></h6>
                                                        <div>
                                                            {
                                                                couponObjs.map(c => (
                                                                    <div key={c} className="rounded">
                                                                        &emsp;- {c.code} ({
                                                                            c.type === 'PERCENTAGE' ? `${c.value}%` : `$${c.value}`
                                                                        }) <i onClick={() => {
                                                                            couponObjs = couponObjs.filter(cc => cc.id !== c.id);
                                                                            setCouponObjs(couponObjs);
                                                                            calcSubPrice();
                                                                        }} className="fa fa-trash text-danger mtz-cursor-pointer"></i>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className='d-flex'>
                                                <span className='flex-grow-1'></span>
                                                <div className="alert alert-light mtz-rounded-16">
                                                    <div><b>- Sponsoring Group:</b> <span className='text-primary h5'>{group.name}</span></div>
                                                    <div><b>- Sponsorship Plan:</b> {active.name}</div>
                                                    <div><b>- Plan Rank:</b> {active.rank}</div>
                                                    <div><b>- Price:</b> ${calc?.cents / 100}/{activePrice?.type}</div>
                                                    {
                                                        couponObjs && couponObjs.length > 0 &&
                                                        <div><b>- After Discount:</b> ${(calc?.finalCents - calc?.tranxCents) / 100}</div>
                                                    }
                                                    <div><b>- Transaction Fee:</b> ${calc?.tranxCents / 100}</div>
                                                    <div className='mtz-h5 border-top'><b>- Total Amount:</b> <span className='text-primary'>${calc && calc.finalCents / 100}</span></div>
                                                </div>
                                            </div>

                                            {
                                                (calc && calc.finalCents > 0) && (!cards || cards.length === 0) && wallet.cents < activePrice.cents ?
                                                    <div className='text-center text-danger mtz-h5'>
                                                        Your wallet balance is insufficient, and you have no card on file. Please add a card to proceed.
                                                    </div> :
                                                    <div className='text-center'>
                                                        <button onClick={subscribe} className="btn btn-primary">
                                                            Confirm & Pay
                                                        </button>
                                                    </div>
                                            }
                                        </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            {isSponsorModalOpen && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-scrollable modal-lg">
                        <div className="modal-content d-flex flex-column">
                            <div className="modal-header alert alert-info">
                                <h5 className="modal-title">Enter Sponsor Details</h5>
                                <button className="close" onClick={() => setIsSponsorModalOpen(false)}>&times;</button>
                            </div>
                            <div className="modal-body overflow-auto p-3" style={{ maxHeight: '70vh' }}>
                                <div className="form-group mb-3">
                                    <LimitedCharReactQuill
                                        value={sponsorDetails.description || ''}
                                        onChange={(event) => setSponsorDetails({ ...sponsorDetails, description: event.target.value })}
                                        placeholder="Description: Max 1000 Characters*"
                                        label={<span>Sponsor description (3-500 characters):</span>}
                                        limit={100}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Redirect Link:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={sponsorDetails.adLink || ''}
                                        onChange={e => setSponsorDetails({ ...sponsorDetails, adLink: e.target.value })}
                                        placeholder="Enter the URL where the banner should redirect"
                                        disabled={useProfileAsRedirect}
                                    />
                                    <small className="text-muted">Your sponsor banner image will redirect to this link.</small>

                                    {myCompany &&
                                        <div className="form-check mt-2">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="useProfileRedirect"
                                                checked={useProfileAsRedirect}
                                                onChange={e => {
                                                    const checked = e.target.checked;
                                                    setUseProfileAsRedirect(checked);
                                                    setSponsorDetails({
                                                        ...sponsorDetails,
                                                        adLink: checked ? `https://mytradezone.com/profile/${myCompany.profileName}` : ''
                                                    });
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="useProfileRedirect">
                                                Use your MyTradeZone profile as the default redirect link
                                            </label>
                                        </div>
                                    }
                                </div>

                                {/* <div className="form-group mb-3" ref={bannerRef}>
                                    <label>Sponsor Banner:</label>
                                    <BannerImageForm
                                        height={150}
                                        width={200}
                                        editable={true}
                                        imageUrl={bannerFile ? URL.createObjectURL(bannerFile.blob) : ""}
                                        onUpload={(file) => setBannerFile(file)}
                                    />
                                </div> */}
                                <div className="form-group mb-3" ref={bannerRef}>
                                    <label className="font-weight-bold">Sponsor Banner:</label>

                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="bannerUpload"
                                            accept="image/*"
                                            onChange={(e) => {
                                                if (e.target.files.length > 0) {
                                                    const file = e.target.files[0];
                                                    setBannerFile({
                                                        blob: file,
                                                        ext: file.name.split('.').pop()
                                                    });

                                                    // Update label with file name
                                                    document.getElementById("bannerUploadLabel").innerText = file.name;
                                                }
                                            }}
                                        />
                                        <label className="custom-file-label" htmlFor="bannerUpload" id="bannerUploadLabel">
                                            Choose file...
                                        </label>
                                    </div>

                                    {bannerFile && (
                                        <div className="mt-3">
                                            <img
                                                src={URL.createObjectURL(bannerFile.blob)}
                                                alt="Sponsor Preview"
                                                className="img-fluid rounded border"
                                                style={{ width: "200px", height: "150px", objectFit: "cover" }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between p-3">
                                <button className="btn btn-secondary" onClick={() => setIsSponsorModalOpen(false)}>Cancel</button>
                                <button className="btn btn-primary" onClick={updateSponsorDetails}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}

export default CGSponsorSubscriptionButton;