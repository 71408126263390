import React from 'react';

import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import AuthContext from '../../AuthContext';
import InstantChatManager from '../../components/chat-service/messages/InstantChatManager';
import CompanyGroupMemberCategoryManagers from '../../components/network-service/companyGroupCategories/CompanyGroupMemberCategoryManager';
import CompanyGroupDetail from '../../components/network-service/companyGroups/CompanyGroupDetail';
import mtzApis from '../../services';
import * as authUtil from '../../services/authUtil';
import Header from '../ParentLayout/Header';
import NonLoginHeader from '../ParentLayout/NonLoginHeader';

function GroupLayout(props) {
    const me = React.useContext(AuthContext);
    let [allowAccess, setAllowAccess] = React.useState(false);
    let [mem, setMem] = React.useState(null);

    const checkLogin = async () => {
        if (window.location.pathname.startsWith("/me") ||
            window.location.pathname.startsWith("/timeline") ||
            window.location.pathname.startsWith("/mtz-admin")) {
            const tokens = authUtil.getLogins();
            if (!tokens || tokens.length === 0) {
                this.state.allowAccess = false;
                return;
            }
        }

        setAllowAccess(true);
    }

    React.useEffect(() => {
        checkLogin();

        if (me && me.userId) {
            (async () => {
                let profileName = props.match.params.profileName;
                if (profileName) {
                    const companyGroups = await mtzApis.companyService.getCompanyGroups(new URLSearchParams(`profileName=${profileName}&isViewCounted=${true}&isLogged=${true}`));
                    let companyGroup =
                        companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
                    if (companyGroup == null)
                        return;
                    let mems = await mtzApis.companyService.getCompanyGroupMembers(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${companyGroup.id}`));
                    if (mems && mems.length > 0) setMem(mems[0])
                }
            })();
        }
    }, [window.location.href]);

    return (
        <>
            {
                allowAccess === true ?
                    <div className="vh-100 w-100 d-flex flex-column mtz-gap-16">
                        {
                            !!me.userId &&
                            <div className='d-none d-xl-inline-block position-fixed' style={{ bottom: '0', right: '0', zIndex: '1050' }}>
                                <InstantChatManager />
                            </div>
                        }

                        {
                            mem &&
                            <CompanyGroupMemberCategoryManagers companyGroupId={mem.companyGroupId} companyGroupMemberId={mem.id} />
                        }

                        <div className='w-100 sticky-top'>
                            {
                                !!me.userId ?
                                    <Header asSimple={true} /> :
                                    <NonLoginHeader asSimple={true} />
                            }
                        </div>

                        <div className='h-100 d-flex flex-grow-1 flex-column'>
                            <div className='d-flex justify-content-center'>
                                <div className='w-100 d-flex flex-column mtz-gap-16' style={{ maxWidth: '1306px' }}>
                                    <CompanyGroupDetail profileName={props.match.params.profileName} />
                                </div>
                            </div>

                            <props.component />
                        </div>
                    </div>
                    :
                    <div className="p-2">Login required. Please <a href="/login">Login</a> to access.</div>
            }
        </>
    );
}

export default withRouter(GroupLayout);
