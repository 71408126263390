import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGEventTicket = (cGEventTicket) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/tickets`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, cGEventTicket, headers);
};

export const deleteCGEventTicketById = (cGEventTicketId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/tickets/${cGEventTicketId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventTicketById = (cGEventTicketId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/tickets/${cGEventTicketId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventTickets = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/tickets?${queries}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventTicketStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/tickets/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCGEventTicketById = (cGEventTicketId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-events/tickets/${cGEventTicketId}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
};