import React from 'react';

import Moment from 'react-moment';
import AuthContext from '../../../AuthContext';
import MtzPagination from '../../../components/shared2/MtzPagination';
import mtzApis from '../../../services';
const { paymentService } = mtzApis;

function MyCouponPage({ }) {
    let [coupons, setCoupons] = React.useState([]);
    let [couponStats, setCouponStats] = React.useState({ all: { count: 0 } });
    let [active, setActive] = React.useState(null);
    const modalAddCouponRef = React.useRef();
    const modalEditCouponRef = React.useRef();
    const DEFAULT_LIMIT = 5;
    const me = React.useContext(AuthContext);

    React.useEffect(() => {
        init();
    }, []);

    const init = async () => {
        loadCouponInfo(0, DEFAULT_LIMIT);
    }

    const loadCouponInfo = async (skip = 0, limit = DEFAULT_LIMIT) => {
        coupons = await paymentService.getMtzCoupons(new URLSearchParams(`sellerUserIds=${me.userId}&limit=${limit}&skip=${skip}`));
        if (coupons) {
            setCoupons(coupons);
        }

        couponStats = await paymentService.getMtzCouponStats(new URLSearchParams(`sellerUserIds=${me.userId}`));
        if (couponStats) {
            setCouponStats(couponStats);
        }
    }

    const couponCreateHandler = (coupon) => {
        if (!coupons) return;
        setCoupons([coupon, ...coupons]);
        setCouponStats({ all: { count: couponStats.all.count + 1 } });
        modalAddCouponRef.current.click();
    }

    const couponDeleteHandler = async () => {
        const ok = await window.createMtzConfirm('Are you sure you want to delete this coupon?');
        if (!ok) return;

        mtzApis.startSpinningIcon();
        let res = await paymentService.deleteMtzCouponById(active.id);
        mtzApis.stopSpinningIcon();

        if (!res) return;
        alert('Coupon deleted');
        setCoupons(coupons.filter(c => c.id !== active.id));
        setActive(null);
        setCouponStats({ all: { count: couponStats.all.count - 1 } });
    }

    const couponUpdateHandler = (coupon) => {
        setCoupons(coupons.map(c => c.id === coupon.id ? coupon : c));
        modalEditCouponRef.current.click();
    }

    if (!coupons || !couponStats)
        return <div>Loading...</div>;

    return (
        <div className='mtz-card'>
            <div>
                <button className='btn btn-primary' ref={modalAddCouponRef}
                    data-toggle='modal' data-target='#modal-create-coupon'>
                    Create coupon <i className='fa fa-plus'></i>
                </button>

                <div className='modal' id='modal-create-coupon'>
                    <div className='modal-dialog modal-dialog-scrollable'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Add Coupon Details</h5>
                                <button className='close' onClick={() => modalAddCouponRef.current.click()}>&times;</button>
                            </div>

                            <div className='modal-body'>
                                <CouponForm onCreate={couponCreateHandler} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h5 className='mtz-h5'>My coupons - (Found {couponStats.all.count})</h5>

            {
                !coupons || coupons.length === 0
                    ? <div>No coupons created yet</div>
                    : <div className='d-flex flex-column mtz-gap-16'>
                        {
                            coupons.map(coupon => (
                                <div key={coupon.id} className='d-flex flex-column mtz-gap-8 border mtz-p-16 mtz-rounded-16'>
                                    <div>
                                        <div>
                                            <b>Coupon code:</b> {coupon.code}
                                        </div>
                                        <div>
                                            <b>Time created:</b> <Moment fromNow>{coupon.createdAt}</Moment>
                                        </div>
                                        {
                                            !active ?
                                                <div className='text-right'>
                                                    <button className='btn btn-link' onClick={() => setActive(coupon)}
                                                        data-toggle='collapse' data-target={`#coupon-${coupon.id}`}>
                                                        More</button>
                                                </div> :
                                                <div className='text-right'>
                                                    <button className='btn btn-link' onClick={() => setActive(null)}
                                                        data-toggle='collapse' data-target={`#coupon-${coupon.id}`}>
                                                        Less</button>
                                                </div>
                                        }
                                        <div className='collapse' id={`coupon-${coupon.id}`}>
                                            <div>
                                                <b>Description:</b> {coupon.description}
                                            </div>
                                            <div>
                                                <b>Type:</b> {coupon.type}
                                            </div>
                                            <div>
                                                <b>Value:</b> {coupon.type === 'PERCENTAGE' ? coupon.value + '%' : coupon.value / 100 + ' USD'}
                                            </div>
                                            <div>
                                                <b>Expiration date:</b> {coupon.expiredAt && new Date(coupon.expiredAt).toLocaleString()}
                                            </div>
                                            {
                                                coupon.minToApplyCents &&
                                                <div>
                                                    <b>Minimum amount to apply:</b> {coupon.minToApplyCents / 100} USD
                                                </div>
                                            }
                                            {
                                                coupon.maxToApplyCents &&
                                                <div>
                                                    <b>Maximum amount to apply:</b> {coupon.maxToApplyCents / 100} USD
                                                </div>
                                            }

                                            <hr />

                                            {/* delete & edit buttons */}
                                            <div className='d-flex mtz-gap-8'>
                                                <button onClick={couponDeleteHandler} className='btn btn-danger'>Delete</button>
                                                <span className='flex-fill'></span>
                                                <button className='btn btn-primary' data-toggle='modal' data-target='#modal-edit-coupon'
                                                    ref={modalEditCouponRef}>Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
            }

            {
                active &&
                <div className='modal' id='modal-edit-coupon'>
                    <div className='modal-dialog modal-dialog-scrollable'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Edit Coupon Details</h5>
                                <button className='close' onClick={() => modalEditCouponRef.current.click()}>&times;</button>
                            </div>

                            <div className='modal-body'>
                                <CouponForm coupon={active} onUpdate={couponUpdateHandler} />
                            </div>
                        </div>
                    </div>
                </div>
            }

            <hr />
            <MtzPagination total={couponStats.all.count} onChange={loadCouponInfo} defaultPerPage={DEFAULT_LIMIT} />
        </div>
    )
}

function CouponForm({ coupon, onCreate, onUpdate }) {
    let defaultCoupon = { type: 'PERCENTAGE' };
    let id = null;
    if (coupon) {
        id = coupon.id;
        let { code, description, type, value, expiredAt, quantity, minToApplyCents, maxToApplyCents } = coupon;
        defaultCoupon = { code, description, type, value, expiredAt, quantity, minToApplyCents, maxToApplyCents };
    }
    let [newCoupon, setNewCoupon] = React.useState(defaultCoupon);

    const createCoupon = async e => {
        e.preventDefault();
        newCoupon.value = parseFloat(newCoupon.value);

        mtzApis.startSpinningIcon();
        let coupon = await paymentService.createMtzCoupon(newCoupon);
        mtzApis.stopSpinningIcon();

        if (coupon) {
            setNewCoupon(defaultCoupon);
            if (onCreate)
                onCreate(coupon);
        }
    }

    const updateCoupon = async e => {
        e.preventDefault();

        mtzApis.startSpinningIcon();
        let coupon = await paymentService.updateMtzCouponById(id, newCoupon);
        mtzApis.stopSpinningIcon();

        if (coupon) {
            alert('Coupon updated');
            setNewCoupon(defaultCoupon);
            if (onUpdate)
                onUpdate(coupon);
        }
    }

    return (
        <form onSubmit={createCoupon}>
            <div className='form-group'>
                <label>Coupon code, 6-12 characters, numbers and letters only</label>
                <input type='text' className='form-control' value={newCoupon.code || ''} placeholder='E.g. SUMMER2021'
                    onChange={e => {
                        let c = e.target.value;
                        if (c.length > 12) return;
                        if (!/^[a-z0-9]*$/.test(c)) return;
                        c = c.toLowerCase().trim();

                        setNewCoupon({ ...newCoupon, code: e.target.value });
                    }} />
            </div>

            <div className='form-group'>
                <label>Description</label>
                <input type='text' className='form-control' value={newCoupon.description || ''} placeholder='E.g. Summer Sale 2021'
                    onChange={e => setNewCoupon({ ...newCoupon, description: e.target.value })} />
            </div>

            <div className='form-group'>
                <label>Type</label>
                <select className='form-control' value={newCoupon.type || ''}
                    onChange={e => setNewCoupon({ ...newCoupon, type: e.target.value })}>
                    <option value='PERCENTAGE'>PERCENTAGE</option>
                    <option value='FIXED'>FIXED</option>
                </select>
            </div>

            {
                newCoupon.type === 'PERCENTAGE' ?
                    <div className='form-group'>
                        <label>Percentage, values is 1-100</label>
                        <input type='number' className='form-control' value={newCoupon.value || ''} placeholder='E.g. 10 is 10%'
                            onChange={e => setNewCoupon({ ...newCoupon, value: e.target.value })} />
                    </div> :
                    <div className='form-group'>
                        <label>Amount USD</label>
                        <input type='number' className='form-control' value={newCoupon.value ? newCoupon.value / 100 : ''}
                            placeholder='E.g. 10 is $10' step={0.01} min={0.01}
                            onChange={e => setNewCoupon({ ...newCoupon, value: e.target.value * 100 })} />
                    </div>
            }

            <div className='form-group'>
                <label>Expiration date (optional)</label>
                <input type='date' className='form-control' value={!newCoupon.expiredAt ? '' : (() => {
                    let d = new Date(newCoupon.expiredAt);
                    let dateStr = d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
                    return dateStr;
                })()}
                    onChange={e => {
                        let date = e.target.value;
                        date = date.split('-');
                        date = new Date(date[0], date[1] - 1, date[2]);
                        date.setHours(23, 59, 59, 999);
                        setNewCoupon({ ...newCoupon, expiredAt: date.getTime() });
                    }}
                    min={(() => {
                        let date = new Date();
                        date.setDate(date.getDate() + 1);
                        date.setHours(23, 59, 59, 999);
                        let dateStr = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
                        return dateStr;
                    })()} />
                <small className='text-danger'>
                    * Expiration: {newCoupon.expiredAt && new Date(newCoupon.expiredAt).toLocaleString()}
                </small>
            </div>

            <div className='form-group'>
                <label>Quantity (optional)</label>
                <input type='number' className='form-control' value={newCoupon.quantity > -1 ? newCoupon.quantity : ''}
                    onChange={e => setNewCoupon({ ...newCoupon, quantity: e.target.value })} />
            </div>

            <div className='form-group'>
                <label>Minimum amount to apply this coupon USD (optional)</label>
                <input type='number' className='form-control' value={newCoupon.minToApplyCents / 100 || ''}
                    placeholder='E.g. 10 means min $10'
                    onChange={e => setNewCoupon({ ...newCoupon, minToApplyCents: e.target.value * 100 })} />
            </div>

            {
                newCoupon.type === 'PERCENTAGE' &&
                <div className='form-group'>
                    <label>Maximum amount of apply this coupon USD (optional)</label>
                    <input type='number' className='form-control' value={newCoupon.maxToApplyCents / 100 || ''}
                        placeholder='E.g. 100 means max $100. If discount > $100, distount will be $100'
                        onChange={e => setNewCoupon({ ...newCoupon, maxToApplyCents: e.target.value * 100 })} />
                </div>
            }

            <div className='modal-footer'>
                {
                    coupon && coupon.id ?
                        <button className='btn btn-primary' onClick={updateCoupon}>Update</button> :
                        <button className='btn btn-primary' onClick={createCoupon}>Create</button>
                }
            </div>
        </form>
    );
}

export default MyCouponPage;



// ===== Below is the old code, which is not working =====


// import React from "react";

// import Moment from "react-moment";
// import AuthContext from "../../../AuthContext";
// import mtzApis from "../../../services";

// const { companyService, paymentService } = mtzApis;

// function MyCouponPage() {
//     let [coupons, setCoupons] = React.useState(null);
//     let [couponStats, setCouponStats] = React.useState(null);
//     const me = React.useContext(AuthContext);

//     React.useEffect(() => {
//         init();
//     }, []);

//     const init = async () => {
//         try {
//             mtzApis.startSpinningIcon();
//             const fetchedCoupons = await paymentService.getMtzCoupons(new URLSearchParams(`sellerUserIds=${me.userId}`));
//             setCoupons(fetchedCoupons);

//             const fetchedCouponStats = await paymentService.getMtzCouponStats(new URLSearchParams(`sellerUserIds=${me.userId}`));
//             setCouponStats(fetchedCouponStats);
//         } finally {
//             mtzApis.stopSpinningIcon();
//         }
//     };

//     const createCoupon = (coupon) => {
//         coupons = [...coupons, coupon];
//         setCoupons(coupons);
//     };

//     const deleteCoupon = async (coupon) => {
//         let ok = await window.createMtzConfirm('Are you sure?');
//         if (!ok)
//             return

//         try {
//             mtzApis.startSpinningIcon();
//             let deleted = await paymentService.deleteMtzCouponById(coupon.id);

//             if (deleted) {
//                 alert('Coupon deleted');
//                 let updated = coupons.filter(c => c.id !== coupon.id);
//                 setCoupons(updated);
//             }
//         } finally {
//             mtzApis.stopSpinningIcon();
//         }
//     };

//     if (coupons === null || couponStats === null)
//         return <div>Loading...</div>;

//     return (
//         <div className="mtz-card">
//             <CreateCoupon onCreate={createCoupon} />

//             <h5 className="mtz-h5">My Coupons: (found: {couponStats.all && couponStats.all.count} )</h5>

//             {
//                 coupons && coupons.length > 0 && coupons.map(coupon => (
//                     <div className="d-flex flex-column mtz-gap-8 border mtz-rounded-16 mtz-p-16">
//                         <div>
//                             <b>Code:</b> <span className="mtz-h5 text-primary">{coupon.code}</span>
//                         </div>

//                         <div>
//                             <b>Value:</b> {coupon.value}
//                         </div>

//                         <div>
//                             <b>Description:</b> {coupon.description}
//                         </div>

//                         <div>
//                             <b>Type:</b> {coupon.type}
//                         </div>

//                         <div>
//                             <b>Quantity:</b> {coupon.quantity}
//                         </div>

//                         <div>
//                             <b>Expired At:</b> <Moment fromNow>{new Date(coupon.expiredAt)}</Moment>
//                         </div>

//                         <div>
//                             <b>Created At:</b> <Moment fromNow>{new Date(coupon.createdAt)}</Moment>
//                         </div>

//                         <div className="text-right">
//                             <button onClick={() => deleteCoupon(coupon)} className="btn btn-danger">delete</button>
//                         </div>
//                     </div>
//                 ))
//             }
//         </div>
//     );
// }

// function CreateCoupon({ onCreate }) {
//     const getDefaultCoupon = () => {
//         let expiredDate = getDefaultExpiredDate();
//         return {
//             type: "PERCENTAGE",
//             quantity: -1,
//             expiredDate,
//             expiredAt: getExpiredDateToMillis(expiredDate)
//         }
//     }

//     const getDefaultExpiredDate = () => {
//         let d = new Date();
//         d.setDate(d.getDate() + 1);
//         let year = d.getFullYear();
//         let month = (d.getMonth() + 1 + "").length > 1 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1);
//         let day = (d.getDate() + "").length > 1 ? d.getDate() : "0" + d.getDate();
//         return year + '-' + month + '-' + day;
//     }

//     const getExpiredDateToMillis = (date) => {
//         if (date) {
//             let [year, month, day] = date.split('-');
//             date = new Date(year, month - 1, day);
//             date.setHours(0, 0, 0, 0);
//             return date.getTime();
//         }

//         return 0;
//     }

//     const modalAddCouponRef = React.useRef();
//     let [newCoupon, setNewCoupon] = React.useState(getDefaultCoupon);

//     const createCoupon = async () => {
//         try {
//             newCoupon.expiredAt = getExpiredDateToMillis(newCoupon.expiredDate);
//             let creating = { ...newCoupon };
//             delete creating.expiredDate;

//             mtzApis.startSpinningIcon();
//             const response = await paymentService.createMtzCoupon(creating);
//             if (response) {
//                 alert('Coupon created');
//                 if (onCreate)
//                     onCreate(response);
//                 setNewCoupon(getDefaultCoupon());
//                 modalAddCouponRef.current.click();
//             }
//         } finally {
//             mtzApis.stopSpinningIcon();
//         }
//     }

//     return (
//         <>
//             <div>
//                 <button ref={modalAddCouponRef} className="btn btn-primary" data-target='#modal-add-coupon' data-toggle='modal'>
//                     Create coupon <i className="fa fa-plus"></i>
//                 </button>
//             </div>

//             <div className="modal" id='modal-add-coupon'>
//                 <div className="modal-dialog modal-dialog-scrollable">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h5 className="modal-title">Create Coupon</h5>
//                             <button onClick={() => modalAddCouponRef.current.click()} type="button" className="btn btn-close">
//                                 <i className="fa fa-close"></i>
//                             </button>
//                         </div>
//                         <div className="modal-body">
//                             <form>
//                                 <div className="form-group">
//                                     <label htmlFor="code">Code: (letters and numbers only)</label>
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         placeholder="6-12 characters, only letters and numbers"
//                                         value={newCoupon.code || ''}
//                                         onChange={(e) => setNewCoupon({ ...newCoupon, code: e.target.value })}
//                                     />
//                                 </div>
//                                 <div className="form-group">
//                                     <label htmlFor="type">Type:</label>
//                                     <select onChange={(e) => setNewCoupon({ ...newCoupon, type: e.target.value })}
//                                         className="form-control"
//                                         value={newCoupon.type || ''}
//                                     >
//                                         <option value="PERCENTAGE">Percentage</option>
//                                     </select>
//                                 </div>

//                                 {
//                                     newCoupon.type === 'PERCENTAGE' &&
//                                     <div className="form-group">
//                                         <label htmlFor="value">Value in %, 0-100:</label>
//                                         <input onChange={(e) => setNewCoupon({ ...newCoupon, value: parseFloat(e.target.value) || 0 })}
//                                             type="number"
//                                             className="form-control"
//                                             value={newCoupon.value || ''}
//                                             step="1"
//                                             min={0} max={100}
//                                             placeholder="e.g. 15"
//                                         />
//                                     </div>
//                                 }

//                                 <div className="form-group">
//                                     <label htmlFor="quantity">Quantity:</label>
//                                     <input onChange={(e) => setNewCoupon({ ...newCoupon, quantity: parseInt(e.target.value) || 0 })}
//                                         type="number"
//                                         className="form-control"
//                                         value={newCoupon.quantity || ''}
//                                     />
//                                     <small className="text-danger">* -1 means unlimited number of coupons, and 1 coupon per user</small>
//                                 </div>
//                                 <div className="form-group">
//                                     <label htmlFor="description">Description:</label>
//                                     <textarea onChange={(e) => setNewCoupon({ ...newCoupon, description: e.target.value })}
//                                         type="text"
//                                         className="form-control"
//                                         placeholder="Coupon description"
//                                         value={newCoupon.description || ''}
//                                     />
//                                 </div>
//                                 <div className="form-group">
//                                     <label htmlFor="expiredAt">Date expired:</label> {newCoupon.expiredAt && <Moment fromNow>{new Date(newCoupon.expiredAt)}</Moment>}
//                                     <input onChange={(e) => setNewCoupon({ ...newCoupon, expiredDate: e.target.value, expiredAt: getExpiredDateToMillis(e.target.value) })}
//                                         type="date"
//                                         className="form-control"
//                                         min={
//                                             (() => {
//                                                 let d = new Date();
//                                                 d.setDate(d.getDate() + 1);
//                                                 let year = d.getFullYear();
//                                                 let month = (d.getMonth() + 1 + "").length > 1 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1);
//                                                 let day = (d.getDate() + "").length > 1 ? d.getDate() : "0" + d.getDate();
//                                                 return year + '-' + month + '-' + day;
//                                             })()
//                                         }
//                                         value={newCoupon.expiredDate || ''}
//                                     />
//                                 </div>

//                                 <div className="text-right">
//                                     <button onClick={createCoupon} type="button" className="btn btn-primary">
//                                         Create
//                                     </button>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default MyCouponPage;