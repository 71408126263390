import React from 'react';
import AuthContext from '../../AuthContext';
import InstantChatManager from '../../components/chat-service/messages/InstantChatManager';
import * as authUtil from "../../services/authUtil";
import Header from '../ParentLayout/Header';
import NonLoginHeader from './NonLoginHeader';

function NewParentLayout(props) {
    const me = React.useContext(AuthContext);
    const [allowAccess, setAllowAccess] = React.useState(false);

    const checkLogin = async () => {
        if (
            window.location.pathname.startsWith("/me") ||
            window.location.pathname.startsWith("/timeline") ||
            window.location.pathname.startsWith("/mtz-admin")
        ) {
            const auths = authUtil.getLogins();
            if (!auths || auths.length === 0) {
                setAllowAccess(false);
                return;
            }
        }
        setAllowAccess(true);
    };

    React.useEffect(() => {
        checkLogin();
    }, [window.location.href]);

    return (
        <>
            {allowAccess === true ? (
                <div className="vh-100 w-100 d-flex flex-column">
                    <div
                        className="d-none d-xl-inline-block position-fixed"
                        style={{ bottom: "0", right: "0", zIndex: "1050" }}
                    >
                        <InstantChatManager />
                    </div>

                    <div className="w-100 sticky-top">
                        {window.location.pathname === "/welcome" || !me.userId ? (
                            <NonLoginHeader />
                        ) : (
                            <Header />
                        )}
                    </div>

                    <div className="h-100 d-flex flex-grow-1 flex-column">
                        <props.component />
                    </div>
                </div>
            ) : (
                <div className="p-2">
                    Login required. Please <a href="/login">Login</a> to access.
                </div>
            )}
        </>
    );
}

export default NewParentLayout;
