import React from "react";

import { NavLink, withRouter } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import QRCode from 'qrcode';
import AuthContext from "../../AuthContext";
import mtzApis from "../../services";
import { PlanButton } from "../ParentLayout/Header";

const { companyService, paymentService, metricService } = mtzApis;

function MainMenu(props) {
  let pathname = window.location.pathname;
  const me = React.useContext(AuthContext);
  let [myCompany, setMyCompany] = React.useState(null);
  let [companyQRopen, setCompanyQROpen] = React.useState(false);
  let [companyQRcode, setCompanyQRCode] = React.useState(null);
  let [userQROpen, setUserQROpen] = React.useState(false);
  let [userQRCode, setUserQRCode] = React.useState(null);
  let [bio, setBio] = React.useState(null);
  let [refTc, setRefTc] = React.useState();

  React.useEffect(() => {
    (async () => {
      if (!me || JSON.stringify(me) === '{}' || !me.userId) return;
      else {
        bio = await companyService.getMyUserBio();
        if (bio)
          setBio(bio);
      }

      refTc = await companyService.getMyReferralTrackingCode();
      if (refTc) setRefTc(refTc);

      myCompany = await companyService.getMyCompany();
      if (myCompany) setMyCompany(myCompany);
    })();
  }, []);

  const manageBiling = async () => {
    mtzApis.startSpinningIcon();
    let customerPortalSession = await paymentService.createStripeCustomerPortalSession({
      returnUrl: `${window.location.origin}/me`,
    });
    mtzApis.stopSpinningIcon();

    if (customerPortalSession) window.location.href = customerPortalSession.url;
    else window.location.href = "/pricing";
  };

  const isNetworkSelected = () => {
    if (
      pathname === "/me/network" ||
      pathname === "/me/connections" ||
      pathname.includes("/me/groups") ||
      pathname === "/me/followings" ||
      pathname === "/me/company-watchlists" ||
      pathname === "/me/product-watchlists"
    ) {
      return true;
    } else return false;
  };

  const generateCompanyQRCode = () => {
    let opts = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 1,
      width: 300,
    }

    QRCode.toDataURL(`${window.location.protocol}//${window.location.host}/profile/${myCompany.profileName}${!refTc ? '' : `?fromQR=true&tc=${refTc.id}`}`, opts, function (err, url) {
      if (err) throw err
      setCompanyQRCode(url);
    })
  }

  const generateUserQRCode = () => {
    let opts = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 1,
      width: 300,
    }

    QRCode.toDataURL(`${window.location.protocol}//${window.location.host}/users/${bio.profileName}${!refTc ? '' : `?fromQR=true&tc=${refTc.id}`}`, opts, function (err, url) {
      if (err) throw err
      setUserQRCode(url);
    })
  }

  return (
    <div
      className="mtz-card"
    >
      <div className="w-100 btn-group-toggle overflow-auto d-flex flex-column mtz-gap-8">
        <PlanButton />

        <NavLink to={`/me`} className="text-nowrap text-dark">
          <i className="fas fa-home"></i> Overview
        </NavLink>

        <NavLink to={`/me/get-started`} className="text-nowrap text-dark">
          <i className="fas fa-star"></i> Get Started
        </NavLink>

        <NavLink to={`/users/${bio?.profileName}`} className="text-nowrap text-dark">
          <i className="fas fa-user"></i> My Profile
        </NavLink>

        {myCompany && (
          <>
            <NavLink to={`/profile/${myCompany.profileName}`} className="text-nowrap text-dark">
              <i className="fas fa-building"></i> My Company
            </NavLink>

            <NavLink to={`/me/network/events/tickets/my-orders`} className="text-nowrap text-dark">
              <i className="fas fa-ticket"></i> My Ticket Orders
            </NavLink>

            <NavLink to={`/me/notifications`} className="text-nowrap text-dark">
              <i className="fas fa-bell"></i> Notifications
            </NavLink>

            <NavLink to={`#`} onClick={() => { generateCompanyQRCode(); setCompanyQROpen(true) }} className="text-nowrap text-dark">
              <i className="fas fa-qrcode"></i> Company QR
            </NavLink>

            <NavLink to={`#`} onClick={() => { generateCompanyQRCode(); setCompanyQROpen(true) }} className="text-nowrap text-dark">
              <i className="fas fa-qrcode"></i> User QR
            </NavLink>
          </>
        )}

        <NavLink to={`/me/mtz-files`} className="text-nowrap text-dark">
          <i className="fas fa-file"></i> MTZ Files
        </NavLink>

        <NavLink to={`/me/tag-alerts`} className="text-nowrap text-dark">
          <i className="fas fa-tags"></i> Tag Alerts
        </NavLink>

        <NavLink to={`/me/extra-info`} className="text-nowrap text-dark">
          <i className="fas fa-info-circle"></i> Extra Info
        </NavLink>

        <NavLink to={`/me/products`} className="text-nowrap text-dark">
          <i className="fas fa-cubes"></i> Products
        </NavLink>

        <NavLink to={`/me/network`} className="text-nowrap text-dark">
          <i className="fas fa-network-wired"></i> Network
        </NavLink>

        <NavLink to={`/me/crm`} className="text-nowrap text-dark">
          <i className="fa fa-users"></i> CRM
        </NavLink>

        <NavLink to={`/me/rfqs`} className="text-nowrap text-dark">
          <i className="fa fa-reply"></i> RFQs
        </NavLink>


        <NavLink to={`/me/billing`} className="text-nowrap text-dark">
          <i className="fa fa-credit-card"></i> Billing
        </NavLink>

        <NavLink to={`/me/advertisement`} className="text-nowrap text-dark">
          <i className="fa fa-bullhorn"></i> Advertisement
        </NavLink>

        <NavLink to={`/pricing`} className="text-nowrap text-dark">
          <i className="fa fa-book"></i> Plan & Pricing
        </NavLink>

        <NavLink to={`/me/account`} className="text-nowrap text-dark">
          <i className="fa fa-cog"></i> Account Settings
        </NavLink>
      </div>

      {
        myCompany && companyQRcode &&
        <Dialog open={companyQRopen} onClose={() => setCompanyQROpen(false)} maxWidth="sm" fullWidth>
          <DialogTitle>Company QR code</DialogTitle>
          <DialogContent dividers>
            <Alert severity="info" className="w-100">
              You can share the QR code with businesses to visit your company.
            </Alert>

            <div className="text-center">
              <img src={companyQRcode} />
            </div>

            <hr />
            <div>
              Having issue to scan the QR code? Just copy the link below to share:
              <div className="alert alert-primary text-break">
                {`${window.location.protocol}//${window.location.host}/profile/${myCompany.profileName}${!refTc ? '' : `?fromQR=true&tc=${refTc.id}`}`}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <button className="btn btn-secondary" onClick={() => setCompanyQROpen(false)}>
              Close
            </button>

            <a className="btn btn-primary text-white" href={companyQRcode} download="qrcode.png">
              Download QR
            </a>
          </DialogActions>
        </Dialog>
      }

      {
        bio && userQRCode &&
        <Dialog open={userQROpen} onClose={() => setUserQROpen(false)} maxWidth="sm" fullWidth>
          <DialogTitle>User QR code</DialogTitle>
          <DialogContent dividers>
            <Alert severity="info" className="w-100">
              You can share the QR code with people to visit your user profile.
            </Alert>

            <div className="text-center">
              <img src={userQRCode} />
            </div>

            <hr />
            <div>
              Having issue to scan the QR code? Just copy the link below to share:
              <div className="alert alert-primary text-break">
                {`${window.location.protocol}//${window.location.host}/users/${bio.profileName}${!refTc ? '' : `?fromQR=true&tc=${refTc.id}`}`}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <button className="btn btn-secondary" onClick={() => setUserQROpen(false)}>
              Close
            </button>

            <a className="btn btn-primary text-white" href={userQRCode} download="qrcode.png">
              Download QR
            </a>
          </DialogActions>
        </Dialog>
      }
    </div >
  );
}

export default withRouter(MainMenu);
