import commonUtil from "../common";
import {
  PAYMENT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const createCommRate = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/comm-rates`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

const getCommRates = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/comm-rates?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const getCommRateStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/comm-rates/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const getCommRateById = commRateId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/comm-rates/${commRateId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const deleteCommRateById = commRateId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/comm-rates/${commRateId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const updateCommRateById = (commRateId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/comm-rates/${commRateId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

const getMyCommRate = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/comm-rate`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

export {
  createCommRate, deleteCommRateById, getCommRateById, getCommRates,
  getCommRateStats, getMyCommRate, updateCommRateById
};

