import React from "react";
import AuthContext from "../../../../AuthContext";
import { directRoomEventPublish } from '../../../../redux/actions/directRoomEventActions';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import mtzApis from "../../../../services";

const { chatService, companyService } = mtzApis;

function DirectRooms({ room, onChange, newRoomEvent, newMessageEvent }) {
    let [active, setActive] = React.useState();
    let [rooms, setRooms] = React.useState([]);
    let [users, setUsers] = React.useState([]);
    let [userK, setUserK] = React.useState('');
    const me = React.useContext(AuthContext);
    const modalAddRoomDismissRef = React.useRef();
    const [dropdownDirectRoomAddMemberOpen, setDropdownDirectRoomAddMemberOpen] = React.useState(false);
    const dropdownDirectRoomAddMemberRef = React.useRef();

    React.useEffect(() => {
        let mounted = true;

        document.addEventListener('mousedown', e => {
            if (dropdownDirectRoomAddMemberRef.current && dropdownDirectRoomAddMemberRef.current.contains(e.target))
                setDropdownDirectRoomAddMemberOpen(true);
            else
                setDropdownDirectRoomAddMemberOpen(false);
        });

        if (room)
            if (!active || active.id !== room.id)
                if (mounted)
                    setActive(room);

        (async () => {
            rooms = await chatService.getDirectRooms(new URLSearchParams(`meta.type=DIRECTROOM`));
            if (!rooms) return;
            rooms = await loadRoomInfo(rooms);
            if (mounted)
                setRooms(rooms);
        })();

        return () => {
            mounted = false;
            document.removeEventListener('mousedown', () => { });
        }
    }, [room]);

    // room event
    React.useEffect(() => {
        let mounted = true;
        (async () => {
            if (newRoomEvent && newRoomEvent.resource && newRoomEvent.resource.meta.type === 'DIRECTROOM') {
                if (newRoomEvent.action === 'CREATED') {
                    let newRoom = newRoomEvent.resource;
                    newRoom = await chatService.getDirectRoomById(newRoom.id);
                    setRooms([newRoom, ...rooms]);
                }

                if (newRoomEvent.action === 'UPDATED') {
                    let newRoom = newRoomEvent.resource;
                    newRoom = await chatService.getDirectRoomById(newRoom.id);
                    let found = rooms.find(r => r.id === newRoom.id);
                    if (found)
                        if (!newRoom.memberIds.includes(me.userId)) {
                            if (mounted) setRooms(rooms.filter(r => r.id !== newRoom.id))
                            if (active && newRoom.id === active.id)
                                if (mounted) onChange();
                        } else {
                            newRoom.members = await companyService.getUsers(new URLSearchParams(`userIds=${newRoom.memberIds}`));
                            if (mounted) setRooms(rooms.map(r => r.id === newRoom.id ? newRoom : r))
                            if (active && active.id === newRoom.id)
                                if (mounted) onChange(newRoom);
                        }

                    if (!found)
                        if (newRoom.memberIds.includes(me.userId))
                            if (mounted) setRooms([newRoom, ...rooms])
                }

                if (newRoomEvent.action === 'DELETED') {
                    let deleted = newRoomEvent.resource;
                    if (rooms)
                        if (mounted) setRooms(rooms.filter(r => r.id !== deleted.id));
                    if (active && active.id === deleted.id)
                        if (mounted) onChange();
                }
            }
        })();

        return () => mounted = false;
    }, [newRoomEvent]);

    // message event
    React.useEffect(() => {
        if (!newMessageEvent || !newMessageEvent.resource)
            return;
        let msg = newMessageEvent.resource;

        (async () => {
            if (newMessageEvent.action === 'CREATED') {
                if (!active || msg.roomId !== active.id) {
                    let room = rooms.find(r => r.id === msg.roomId);
                    if (!room)
                        return;
                    if (room.unseenMsgs && room.unseenMsgs.find(m => m.id === msg.id))
                        return;
                    if (msg.viewerIds.includes(me.userId))
                        return;
                    room.unseenMsgs = [msg, ...(room.unseenMsgs || [])];
                    if (room.unseenMsgs.length > 11)
                        room.unseenMsgs = room.unseenMsgs.slice(0, 10 + 1);

                    setRooms(prevRooms => prevRooms.map(r => r.id === msg.roomId ? room : r));
                }
            }

            if (newMessageEvent.action === 'UPDATED') {
                if (msg.viewerIds.includes(me.userId)) {
                    let foundRoom = rooms.find(r => r.id === msg.roomId);
                    if (!foundRoom)
                        return;
                    foundRoom.unseenMsgs = await chatService.getMessages(new URLSearchParams(`roomIds=${foundRoom.id}&notSeenBy=${me.userId}&limit=11`));
                    setRooms(prevRooms => prevRooms.map(r => r.id === msg.roomId ? foundRoom : r));
                    setActive(prevActive => prevActive.id === foundRoom.id ? foundRoom : prevActive);
                }
            }

            if (newMessageEvent.action === 'DELETED') {
                let room = rooms.find(r => r.id === msg.roomId);
                if (room) {
                    if (msg.viewerIds.includes(me.userId))
                        return;
                    if (room.unseenMsgs && room.unseenMsgs.length > 0)
                        room.unseenMsgs = room.unseenMsgs.filter(m => m.id !== msg.id);
                    setRooms(prevRooms => prevRooms.map(r => r.id === room.id ? room : r));
                }
            }
        })();
    }, [newMessageEvent]);

    const searchUser = async k => {
        if (!k) return;

        users = await companyService.getUsers(new URLSearchParams(`keyword=${k}`));
        setUsers(users);
    };

    const loadRoomInfo = async roomList => {
        let reqs = roomList.map(r => chatService.getMessages(new URLSearchParams(`roomIds=${r.id}&notSeenBy=${me.userId}&limit=11`)));
        let data = await Promise.allSettled(reqs);
        let vals = data.map(res => res.value);

        let count = 0;
        roomList.forEach(r => {
            r.unseenMsgs = vals[count] || [];
            count++;
        });

        return roomList;
    };

    const createRoom = async user => {
        const ok = await window.createMtzConfirm(`Do you want to create a chat with user '${user.firstname} ${user.lastname}'`);
        if (!ok) return;

        // check if room already exists
        let found = rooms.find(r => r.memberIds.includes(user.id));
        if (found) {
            alert(`You already have a direct-chat with user '${user.firstname} ${user.lastname}'`);
            modalAddRoomDismissRef.current.click();
            return;
        }

        mtzApis.startSpinningIcon();
        let room = await chatService.createDirectRoom({ toMemberId: user.id });
        mtzApis.stopSpinningIcon();

        if (room) {
            rooms = [room, ...rooms];
            setRooms(rooms);
            setUsers([]);
            setUserK('');
            if (modalAddRoomDismissRef && modalAddRoomDismissRef.current)
                modalAddRoomDismissRef.current.click();
        }
    };

    const editRoom = async (room, changes) => {
        mtzApis.startSpinningIcon();
        if (changes._leave)
            await chatService.updateDirectRoomById(room.id, changes);
        mtzApis.stopSpinningIcon();
    };

    return (
        <div className="d-flex flex-column mtz-gap-8">
            <div className="d-flex align-items-center">
                <h6 className="m-0 mtz-h6 flex-fill">Direct Messages:</h6>
                <button data-toggle='modal' data-target='#modal-add-droom' className="btn btn-sm btn-outline-dark" ref={modalAddRoomDismissRef}>
                    <span className="fa fa-plus"></span>
                </button>

                <div id='modal-add-droom' className="modal" data-backdrop='static'>
                    <div className="modal-dialog modal-dialog-scrollable modal-lg">
                        <div className="modal-content">
                            <div className="modal-header d-flex">
                                <h5 className="mtz-h5">Add direct room</h5>
                                <button className="btn btn-sm close" onClick={() => modalAddRoomDismissRef.current.click()}>
                                    <span className="fa fa-times"></span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Search user:</label>

                                    <div className="dropdown" ref={dropdownDirectRoomAddMemberRef}>
                                        <input placeholder="Search user" className="form-control" data-toggle="dropdown"
                                            value={userK}
                                            onChange={e => {
                                                searchUser(e.target.value);
                                                setUserK(e.target.value);
                                            }}
                                        />

                                        <div className={"w-100 overflow-auto dropdown-menu " +
                                            (dropdownDirectRoomAddMemberOpen ? 'show' : '')}
                                            style={{ maxHeight: '200px' }}
                                        >
                                            {
                                                users && users.map(user => (
                                                    <div key={user.id} className="dropdown-item w-100 rounded-0 d-flex align-items-center mtz-gap-8"
                                                        onClick={() => {
                                                            createRoom(user);
                                                        }}>
                                                        {
                                                            user.logo ?
                                                                <img className="img-fluid rounded-circle"
                                                                    style={{ width: '40px', height: '40px' }}
                                                                    src={user.logo && (chatService.getBackendHost() + user.logo)} /> :
                                                                <i className="fa fa-user text-muted d-flex algin-items-center justify-content-center"
                                                                    style={{ fontSize: '25px', width: '40px', height: '40px' }}
                                                                ></i>
                                                        }

                                                        {`${user.firstname} ${user.lastname}`} - Click to start messaging
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="list-group">
                {
                    rooms === null &&
                    <div className="list-group-item">Loading...</div>
                }

                {
                    rooms && rooms.length === 0 &&
                    <div className="list-group-item text-center">
                        No message found
                    </div>
                }

                {
                    rooms && rooms.length !== 0 && rooms.map(room => (
                        <div key={room.id}
                            className={"list-group-item p-1" + (active && room.id === active.id ? ' bg-light' : '')}>
                            <div className="d-flex align-items-center mtz-gap-8">
                                <div className="flex-fill d-flex mtz-gap-8 align-items-center mtz-cursor-pointer"
                                    onClick={() => {
                                        if (!active || active.id !== room.id)
                                            if (onChange)
                                                onChange(room);
                                    }} >
                                    {
                                        room.logoPath ?
                                            <img style={{ maxHeight: '40px', maxWidth: '40px' }}
                                                className="rounded-circle" src={companyService.getBackendHost() + room.logoPath} /> :
                                            <span className="fa fa-user-circle text-muted d-flex algin-items-center justify-content-center"
                                                style={{ fontSize: '40px', width: '40px', height: '40px' }}
                                            ></span>
                                    }
                                    <span className="">{room.name}</span>
                                </div>
                                <div className="dropdown">
                                    <button data-toggle='dropdown' className="btn" >
                                        <span className="fa fa-ellipsis-v"></span>
                                    </button>
                                    <div className="dropdown-menu">
                                        <div className="dropdown-item">
                                            <button onClick={async () => {
                                                const ok = await window.createMtzConfirm(`Are you sure?`);
                                                if (!ok) return;

                                                editRoom(room, { _leave: true });
                                            }} className="btn text-danger">
                                                <span className="fa fa-sign-out"></span> Leave
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {
                                    room && room.unseenMsgs && room.unseenMsgs.length > 0 ?
                                        <div>
                                            <div className="circle-rounded bg-danger badge text-white">
                                                {room.unseenMsgs.length > 10 ? '10+' :
                                                    room.unseenMsgs.length}
                                            </div>
                                        </div> :
                                        ''
                                }
                            </div>
                        </div>
                    ))
                }

                <div className="modal" id='modal-view-droom'>
                    <div className="modal-dialog modal-dialog-scrollable modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="mtz-h5">Room info: {active ? active.name : ''}</h5>
                            </div>

                            <div className="modal-body">
                                {
                                    active &&
                                    <div className="form-group">
                                        <label>Room members:</label>

                                        <div className="mb-2 d-flex mtz-gap-8 align-items-center">
                                            <b className="fa fa-users"></b>
                                            <b className="text-danger">me</b>
                                            {
                                                active.members && active.members.filter(m => m.id !== me.userId).map(m => (
                                                    <div key={m.id} className="align-items-center border px-2 mtz-rounded-16">
                                                        {m.firstname + " " + m.lastname}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const stateToProps = (state) => ({
    newRoomEvent: state.room_topic.new_uievent,
    newPresenceEvent: state.presence_topic.new_uievent,
    newMessageEvent: state.message_topic.new_uievent,
});

const dispatchToProps = (dispatch) => ({
    publishDirectRoomEvent: (e) => dispatch(directRoomEventPublish(e)),
});

const RoomsWrapper = connect(stateToProps, dispatchToProps)(withRouter(DirectRooms));

export default RoomsWrapper;