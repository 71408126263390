import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DeleteIcon from "@material-ui/icons/Delete";

import MicRecorder from "mic-recorder-to-mp3";
import mtzApis from "../../../../services";
import LimitedCharReactQuill from "../../../shared2/reactQuills/LimitedCharReactQuill";

const { companyService } = mtzApis;
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default function CompanyNoteCreateForm2({
    companyId,
    profileName,
    onCreate,
}) {
    let [company, setCompany] = React.useState(null);
    let [note, setNote] = React.useState({});
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    let [isRecording, setIsRecording] = React.useState(false);
    let [isBlocked, setIsBlocked] = React.useState(false);
    let [elapseTime, setElapseTime] = React.useState(0);
    let [timer, setTimer] = React.useState(null);
    const [recordedVoiceNotes, setRecordedVoiceNotes] = React.useState([]);


    React.useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (profileName) {
            let companies = await companyService.getCompanies(
                new URLSearchParams(`profileNames=${profileName}`)
            );

            if (companies && companies.length === 1) {
                let company = companies[0];
                setCompany(company);
            }
        }
    };

    const createNote = async (event) => {
        event.preventDefault();
        note.companyId = company.id;
        mtzApis.startSpinningIcon();
        note = await companyService.createCompanyNote(note);
        mtzApis.stopSpinningIcon();
        if (note) {
            if (onCreate) onCreate(note);
            uploadNoteFiles(note, selectedFiles);
        }
    };

    const uploadNoteFiles = async (note, files) => {
        let res = [];
        mtzApis.startSpinningIcon();
        for (let i = 0; i < files.length; i++) {
            let formData = new FormData();
            formData.append(`file`, files[i], files[i].name);
            formData.append(
                "noteFile",
                new Blob([JSON.stringify({ noteId: note.id })], {
                    type: "application/json",
                })
            );
            let fileUploadRes = await companyService.createNoteFile(formData);
            if (!!fileUploadRes) res.push(fileUploadRes);
        }
        mtzApis.stopSpinningIcon();
        return res;
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...files]);
    };

    const start = async () => {
        let isBlocked = false;
        await navigator.getUserMedia(
            { audio: true },
            () => {
                console.log("Permission Granted");
            },
            () => {
                console.log("Permission Denied");
                isBlocked = true
            }
        );

        if (isBlocked) {
            console.log("Permission Denied");
        } else {
            Mp3Recorder.start()
                .then(() => {
                    setIsRecording(true);

                    let timer = setInterval(() => {
                        elapseTime = elapseTime + 1;
                        setElapseTime(elapseTime);
                    }, 1000);

                    setTimer(timer);
                })
                .catch((e) => console.error(e));
        }
    };

    const stop = () => {
        Mp3Recorder.stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const file = new File(buffer, "me-at-thevoice.mp3", {
                    type: blob.type,
                    lastModified: Date.now(),
                });
                setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, file]);
                setIsRecording(false);
                clearInterval(timer);
                setRecordedVoiceNotes((prevVoiceNotes) => [...prevVoiceNotes, file]);
            })
            .catch((e) => console.log(e));
    };

    const handleCreateVoiceNote = () => {
        if (!isRecording) {
            start(); // Start recording voice note
        } else {
            stop(); // Stop recording voice note
        }
    };

    const handleDeleteVoiceNote = async (index) => {
        let ok = await window.createMtzConfirm("Are you sure?");
        if (!ok) return;
        const newVoiceNotes = [...recordedVoiceNotes];
        newVoiceNotes.splice(index, 1);
        setRecordedVoiceNotes(newVoiceNotes);
    };
    const audioFileTypes = ["audio/mp3", "audio/mpeg"];
    const noteDocs = selectedFiles
        .filter((file) => !audioFileTypes.includes(file.type))
        .map((file) => ({
            uri: URL.createObjectURL(file),
            fileType: file.type,
        }));
    const handleDeleteFile = async (index) => {
        let ok = await window.createMtzConfirm("Are you sure?");
        if (!ok) return;
        const newSelectedFiles = [...selectedFiles];
        newSelectedFiles.splice(index, 1);
        setSelectedFiles(newSelectedFiles);
    };

    const MyHeader = (state, previousDocument, nextDocument) => {
        if (!state.currentDocument || state.config?.header?.disableFileName) {
            return null;
        }
        return (
            <React.Fragment>
                {/* <div>{state.currentDocument.fileName || ""}</div> */}
                <small>Total files:{state.documents.length}</small>
                <Grid container justifyContent="flex-end" style={{ display: "flex" }}>
                    <Grid item>
                        <IconButton
                            variant="contained"
                            color="secondary"
                            onClick={() => handleDeleteFile(state.currentFileNo)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={previousDocument}
                            disabled={state.currentFileNo === 0}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={nextDocument}
                            disabled={state.currentFileNo >= state.documents.length - 1}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <ValidatorForm onSubmit={(event) => createNote(event)}>
                <div className="d-flex flex-column mtz-gap-16">
                    <div className='alert alert-primary'>
                        This is a private note for your records only and will not be shared with the
                        company.
                    </div>

                    <div>
                        <label>
                            <b>
                                Title <small className="text-danger">*required</small>
                            </b>
                        </label>
                        <input
                            className="form-control"
                            required
                            onChange={(e) => setNote({ ...note, title: e.target.value })}
                        />
                    </div>
                    <div>
                        <LimitedCharReactQuill
                            label={
                                <label>
                                    Content: Max 2000 characters{" "}
                                    <small className="text-danger mtz-rounded-16">
                                        *required
                                    </small>
                                </label>
                            }
                            onChange={(event) =>
                                setNote({ ...note, content: event.target.value })
                            }
                            required={true}
                            value={note.content || ""}
                            limit={2000}
                        />
                    </div>
                    <Box p={1} m={1} style={{ backgroundColor: "rgb(238, 238, 238)" }}>
                        <div>
                            <h6>Upload note file(s)</h6>
                            <Box mb={1}>
                                <input type="file" multiple onChange={handleFileChange} />
                            </Box>
                        </div>
                        <Box p={1}>
                            <DocViewer
                                pluginRenderers={DocViewerRenderers}
                                documents={noteDocs}
                                config={{
                                    header: {
                                        overrideComponent: MyHeader,
                                    },
                                }}
                                style={{ maxHeight: "300px", maxWidth: "250px" }}
                            />
                        </Box>
                    </Box>

                    <Box p={1} style={{ backgroundColor: "rgb(238, 238, 238)" }}>
                        <Box>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <button
                                        type="button"
                                        onClick={handleCreateVoiceNote}
                                        className={
                                            !isRecording
                                                ? "btn btn-sm btn-primary text-white mtz-rounded-8"
                                                : "btn btn-sm btn-danger text-white"
                                        }
                                    >
                                        {!isRecording
                                            ? "Create voice note"
                                            : `Stop - Current recording length: ${elapseTime} second(s)`}
                                    </button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={1}>
                            {recordedVoiceNotes.length > 0 &&
                                recordedVoiceNotes.map((voiceNote, index) => (
                                    <Box display="flex" className="" key={voiceNote.id || index}>
                                        <Box>
                                            <audio controls>
                                                <source
                                                    src={URL.createObjectURL(voiceNote)}
                                                    type="audio/mpeg"
                                                />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </Box>
                                        <Box>
                                            <IconButton
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleDeleteVoiceNote(index)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ))}
                        </Box>
                    </Box>

                    <button type="submit" className="btn btn-primary text-white">
                        Create
                    </button>
                </div>
            </ValidatorForm>
        </React.Fragment>
    );
}
