import React from "react";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";
import MicRecorder from "mic-recorder-to-mp3";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import mtzApis from "../../../../services";
import LimitedCharReactQuill from "../../../shared2/reactQuills/LimitedCharReactQuill";

const { companyService } = mtzApis;

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default function CompanyNoteUpdateForm({ note, onUpdate, onDelete }) {
  let [changes, setChanges] = React.useState({});
  let [noteFiles, setNoteFiles] = React.useState([]);
  let [selectedFiles, setSelectedFiles] = React.useState(null);
  let [isBlocked, setIsBlocked] = React.useState(false);
  let [isRecording, setIsRecording] = React.useState(false);
  let [blobFile, setBlobFile] = React.useState(null);
  let [elapseTime, setElapseTime] = React.useState(0);
  let [timer, setTimer] = React.useState(null);

  const fileRef = React.useRef();

  React.useEffect(() => {
    init();
  }, [note && note.id]);

  const init = async () => {
    if (note) {
      let noteFiles = await companyService.getNoteFiles(new URLSearchParams(`noteIds=${note.id}`));
      if (!!noteFiles) setNoteFiles(noteFiles);
    }

    navigator.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        setIsBlocked(false);
      },
      () => {
        console.log("Permission Denied");
        setIsBlocked(true);
      }
    );
  };

  const onResetHandler = () => {
    fileRef.current.value = "";
    setSelectedFiles(null);
  };

  const onSelectHandler = (event) => {
    setSelectedFiles(event.target.files);
  };

  const updateNote = async () => {
    if (!changes || Object.keys(changes).length === 0) return;
    mtzApis.startSpinningIcon();
    note = await companyService.updateCompanyNoteById(note.id, changes);
    mtzApis.stopSpinningIcon();
    if (note) {
      if (onUpdate) {
        setChanges({});
        onUpdate(note);
      }
    }
  };

  const uploadNoteFiles = async (files) => {
    let noteFile = {
      noteId: note.id,
    };
    let res = [];
    mtzApis.startSpinningIcon();
    for (let i = 0; i < files.length; i++) {
      let formData = new FormData();
      formData.append(`file`, files[i], files[i].name);
      formData.append(
        "noteFile",
        new Blob([JSON.stringify(noteFile)], {
          type: "application/json",
        })
      );
      let fileUploadRes = await companyService.createNoteFile(formData);
      if (!!fileUploadRes) res.push(fileUploadRes);
    }
    mtzApis.stopSpinningIcon();
    let updateNoteFiles = [...noteFiles, ...res];
    setNoteFiles(updateNoteFiles);
    onResetHandler();
    setBlobFile(null);
  };

  function getUrlExtension(url) {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  }

  const start = () => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);

          let timer = setInterval(() => {
            elapseTime = elapseTime + 1;
            setElapseTime(elapseTime);
          }, 1000);

          setTimer(timer);
        })
        .catch((e) => console.error(e));
    }
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "me-at-thevoice.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        setIsRecording(false);
        clearInterval(timer);
        uploadNoteFiles([file]);
      })
      .catch((e) => console.log(e));
  };

  const deleteNoteFile = async (noteFileId) => {
    let res = await window.createMtzConfirm("Are you sure?");
    if (!res) return;
    mtzApis.startSpinningIcon();
    await companyService.deleteNoteFileById(noteFileId);
    mtzApis.stopSpinningIcon();
    noteFiles = noteFiles.filter((item) => item.id != noteFileId);
    setNoteFiles(noteFiles);
  };

  const MyHeader = (state, previousDocument, nextDocument) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
      return null;
    }

    return (
      <React.Fragment>
        <h5 className='mtz-h5'>Total files:{state.documents.length}</h5>

        <Grid container justifyContent="flex-end" style={{ display: "flex" }}>
          <Grid item>
            <IconButton
              variant="contained"
              color="secondary"
              onClick={() => deleteNoteFile(state.currentDocument.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={previousDocument} disabled={state.currentFileNo === 0}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={nextDocument}
              disabled={state.currentFileNo >= state.documents.length - 1}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {note === null && "Loading..."}
      {note !== null && (
        <div>
          <div className="d-flex flex-column mtz-gap-16">
            <div className='alert alert-primary'>
              This is a private note for your records only and will not be shared with the
              company.
            </div>

            <div className='mtz-p-16 mtz-rounded-16 bg-light d-flex flex-column mtz-gap-16'>
              <div>
                <label>
                  <b>
                    Title <small className="text-danger">*required</small>
                  </b>
                </label>
                <input
                  className="form-control"
                  required
                  value={changes.title || note.title || ""}
                  onChange={(e) => setChanges({ ...changes, title: e.target.value })}
                />
              </div>

              <div>
                <LimitedCharReactQuill
                  label={
                    <label>
                      Content: Max 2000 characters <small className="text-danger">*required</small>
                    </label>
                  }
                  onChange={(event) => setChanges({ ...changes, content: event.target.value })}
                  required={true}
                  value={note.content || ""}
                  limit={2000}
                />
              </div>

            </div>

            {note && note.id && (
              <>
                <div className='mtz-p-16 mtz-rounded-16 bg-light'>
                  <form className='d-flex flex-column mtz-gap-16'
                    autoComplete="off"
                    onSubmit={(event) => {
                      event.preventDefault();
                      uploadNoteFiles(selectedFiles);
                    }}
                  >
                    <div>
                      <input
                        type="file"
                        ref={fileRef}
                        name="uploadFile"
                        onChange={onSelectHandler}
                        required
                        multiple
                      />
                    </div>

                    {note.id && (
                      <div>
                        <button
                          className="btn btn-sm btn-primary text-white mtz-rounded-8"
                          type="submit"
                        >
                          Upload note file(s)
                        </button>
                      </div>
                    )}
                  </form>

                  <div className=''>
                    <hr />
                    {
                      noteFiles && noteFiles.length > 0 && (
                        <DocViewer className='flex-grow-1'
                          pluginRenderers={DocViewerRenderers}
                          documents={
                            !!noteFiles && noteFiles.length > 0
                              ? noteFiles
                                .filter((noteFile) => getUrlExtension(noteFile.fileUrl) !== "mp3")
                                .map((file) => ({
                                  uri: companyService.getBackendHost() + file.fileUrl,
                                  fileName: file.name,
                                  id: file.id,
                                }))
                              : []
                          }
                          config={{
                            header: {
                              overrideComponent: MyHeader,
                            },
                          }}
                        />
                      )
                    }
                  </div>
                </div>

                <div className='bg-light mtz-p-16 mtz-rounded-16 d-flex flex-column mtz-gap-16'>
                  {!isRecording ? (
                    <button
                      className="btn btn-sm btn-primary text-white mtz-rounded-8"
                      onClick={start}
                    >
                      Create voice note
                    </button>
                  ) : (
                    <button className="btn btn-sm btn-danger text-white" onClick={stop}>
                      Stop - Current recording length: {elapseTime} second(s)
                    </button>
                  )}

                  <div>
                    {!!noteFiles &&
                      noteFiles.length > 0 &&
                      noteFiles.map(
                        (noteFile) =>
                          getUrlExtension(noteFile.fileUrl) === "mp3" && (
                            <div className="d-flex" key={noteFile.id}>
                              <div className='flex-fill'>
                                <audio
                                  src={companyService.getBackendHost() + noteFile.fileUrl}
                                  controls="controls"
                                />
                              </div>

                              <button
                                className='btn btn-sm close'
                                onClick={() => deleteNoteFile(noteFile.id)}
                              >
                                <span className='fa fa-trash'></span>
                              </button>
                            </div>
                          )
                      )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
