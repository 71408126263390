import commonUtil from "../common";
import {
    PAYMENT_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const getMtzSellerPayoutHolds = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-seller-payout-holds?${queries}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}

const getMtzSellerPayoutHoldStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-seller-payout-holds/stats?${queries}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}

const getMtzSellerPayoutHoldById = id => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-seller-payout-holds/${id}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}

export {
    getMtzSellerPayoutHoldById, getMtzSellerPayoutHolds,
    getMtzSellerPayoutHoldStats
};

