import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import Moment from 'react-moment';
import mtzApis from '../../../../services';
import { PAYMENT_SERVER_URL } from '../../../../services/config';
import { getMyMtzCustomer } from '../../../../services/payment/mtzCustomerApi';

const { paymentService } = mtzApis;

let stripePromise = null;
if (PAYMENT_SERVER_URL.includes('localhost'))
  stripePromise = loadStripe('pk_test_51MC6L3A61qE8cuHxPJFWqpVDc6GtgDks7LvFuafSLWtBzqhTY3ZEyJBvPVSryYZ91tkgkzEsAEJb4zKetppjR5XY007pkmKWnV');
else if (PAYMENT_SERVER_URL.includes('mytradezone.com'))
  stripePromise = loadStripe('pk_live_51LuGhqH6fkJtSoVxsSVoDiSV7KpR3EhT0rCBwCHZmZVhgeBm140UiXiHstO3GG5F57KxGqopWT2c8bf7bVMrr2y700fViKFBYd');
else
  stripePromise = loadStripe('pk_test_51MC6L3A61qE8cuHxPJFWqpVDc6GtgDks7LvFuafSLWtBzqhTY3ZEyJBvPVSryYZ91tkgkzEsAEJb4zKetppjR5XY007pkmKWnV');


function CardManager({ onChange, onSelect, value }) {
  let [customer, setCustomer] = React.useState();
  let [cards, setCards] = React.useState();
  let [selectedCard, setSelectedCard] = React.useState();
  const modalNewCardRef = React.useRef();
  const collapseCardsRef = React.useRef();

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    customer = await getMyMtzCustomer();

    if (customer) {
      setCustomer(customer);

      cards = await paymentService.getCards();
      if (cards) {
        cards = cards.sort((x, y) => ((x.asDefault === y.asDefault) ? 0 : x.asDefault ? -1 : 1));
        setCards(cards);

        setSelectedCard(cards.find(c => c.asDefault));
        // if (onChange) onChange(cards);
        // if (onSelect) onSelect(cards.find(c => c.asDefault));
      }
    }
  }

  const onNewCard = newCard => {
    if (cards) {
      cards = [newCard, ...cards];
      cards = cards.sort((x, y) => ((x.asDefault === y.asDefault) ? 0 : x.asDefault ? -1 : 1));
      setCards(cards);
      modalNewCardRef.current.click();
      if (onChange) onChange(cards);
      if (onSelect) onSelect(newCard);
    }
  };

  const onDelCard = async id => {
    const ok = await window.createMtzConfirm('Are you sure to delete this card?');
    if (!ok) return;

    mtzApis.startSpinningIcon();
    let del = await paymentService.deleteCardById(id);
    mtzApis.stopSpinningIcon();

    if (del) {
      alert('Card deleted');
      cards = cards.filter(c => c.id !== del.id);
      cards = cards.sort((x, y) => ((x.asDefault === y.asDefault) ? 0 : x.asDefault ? -1 : 1));
      setCards(cards);
      if (onChange) onChange(cards);
    }
  };

  const onCardDefault = async card => {
    const ok = await window.createMtzConfirm('Are you sure to set this card as default?');
    if (!ok) return;


    mtzApis.startSpinningIcon();
    let updated = await paymentService.updateCardById(card.id, { asDefault: true });
    mtzApis.stopSpinningIcon();

    if (updated) {
      alert('Card updated');
      cards = cards.map(c => {
        if (c.id !== updated.id) {
          if (c.asDefault)
            c.asDefault = false;
          return c;
        }

        return updated;
      });
      cards = cards.sort((x, y) => ((x.asDefault === y.asDefault) ? 0 : x.asDefault ? -1 : 1));
      setCards(cards);
      if (onChange) onChange(cards);
    }
  };

  const onSelectCard = card => {
    setSelectedCard(card);
    if (onSelect) onSelect(card);
    collapseCardsRef.current.click();
  }

  return (
    <div className='d-flex flex-column mtz-gap-16'>
      {customer === null && 'Loading...'}

      {
        customer &&
        <>
          <div>
            <div>
              <b className=''>* Card in use: <span className='text-info'>
                {selectedCard ? selectedCard.name : 'n/a'} (last 4: {selectedCard ? '**** ' + selectedCard.last4 : '-'})</span></b>
            </div>
            <div>
              <button ref={collapseCardsRef}
                data-toggle='collapse' data-target='#collapse-cards'
                className='btn btn-link btn-sm'>
                Use another card <i className="fa fa-chevron-down"></i>
              </button>
            </div>
          </div>

          <div className='collapse border rounded p-2 bg-light' id='collapse-cards'>
            <div className='d-flex flex-column mtz-gap-4'>
              <div className='w-100 text-right'>
                <div className='d-flex align-items-center justify-content-between'>
                  <span className='mtz-h5'>My Cards:</span>
                  <button data-toggle='modal' data-target='#modal-new-card'
                    className='btn btn-primary btn-sm' ref={modalNewCardRef}>
                    New Card <span className="fa fa-plus"></span></button>
                </div>

                <div className="modal" id='modal-new-card'>
                  <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header d-flex align-items-center justify-content-between">
                        <h5 className='mtz-h5'>New Card</h5>
                        <button className="btn fa fa fa-close close"
                          onClick={() => modalNewCardRef.current.click()}></button>
                      </div>

                      <div className="modal-body">
                        <Elements stripe={stripePromise}>
                          <CardForm onCreate={onNewCard} />
                        </Elements>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {cards === null && 'Loading...'}

              {
                cards && cards.length === 0 && (
                  <>
                    <div>You have no card.</div>
                  </>
                )
              }

              {
                cards && cards.filter(c => !!c).length > 0 &&
                <div className='list-group'>
                  {
                    cards.map(c => (
                      <div className='list-group-item' key={c.id}>
                        <div className=''>
                          <b>Card Name: </b> {c.name} - (Last 4: *{c.last4})
                        </div>

                        <div className='d-flex flex-wrap align-items-center mtz-gap-4'>
                          <button className='btn btn-outline-danger btn-sm'
                            onClick={() => onDelCard(c.id)}>
                            <i className='fa fa-trash'></i>
                          </button>
                          <button className='btn btn-outline-primary btn-sm text-nowrap'
                            onClick={() => onSelectCard(c)}>
                            Use this card to pay
                          </button>
                        </div>

                        {
                          !c.asDefault &&
                          <div className='form-check'>
                            <input type='checkbox' className='form-check-input' checked={c.asDefault}
                              onChange={() => onCardDefault(c)} />
                            <label className='form-check-label text-success'><small>Default source for future payments?</small></label>
                          </div>
                        }

                        <div className='text-right'>
                          <small>Created: <i><Moment fromNow>{c.createdAt && new Date(c.createdAt).toLocaleString()}</Moment></i></small>
                        </div>
                      </div>
                    ))
                  }
                </div>
              }
            </div>

            <div className='w-100 text-right'>
              <button className='btn btn-secondary btn-sm mt-2' data-toggle='collapse' data-target='#collapse-cards'>Close</button>
            </div>
          </div>
        </>
      }
    </div >
  );
}

function CardForm({ onCreate }) {
  let [cardErr, setCardErr] = React.useState();
  let [cardName, setCardName] = React.useState();
  const stripe = useStripe();
  const elements = useElements();

  const createCard = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      setCardErr(result.error.message);
    } else {
      let card = {
        name: cardName,
        tmpToken: result.token.id
      };

      mtzApis.startSpinningIcon();
      card = await paymentService.createCard(card);
      mtzApis.stopSpinningIcon();

      if (card) {
        alert('Card created');
        setCardName('');
        setCardErr('');
        if (onCreate)
          onCreate(card);
      }
    }
  };

  const onCardChange = async (event) => {
    setCardErr(event.error ? event.error.message : "");
  };

  return <div className='text-left'>
    <form onSubmit={createCard} className='d-flex flex-column mtz-gap-16'>
      {
        cardErr &&
        <div className='text-danger font-weight-bold'>
          <small>{"* " + cardErr}</small>
        </div>
      }

      <div className='form-group'>
        <label>Card nickname:</label>
        <input className='form-control' onChange={e => setCardName(e.target.value)} />
      </div>

      <div className='form-control mtz-p-8'>
        <CardElement onChange={onCardChange} />
      </div>

      <div className='text-right'>
        <button className='btn btn-primary'>Submit</button>
      </div>
    </form>
  </div>
}

export default CardManager;