import React from "react";
import mtzApis from "../../../../services";

import { connect } from "react-redux";
import AuthContext from "../../../../AuthContext";
import { connectionEventPublish } from "../../../../redux/actions/connectionEventActions";

const { companyService } = mtzApis;

function ConnectButton({ targetUserId, newConnEvent, publishConnectionEvent, className, size }) {
  const me = React.useContext(AuthContext);
  let [targetUser, setTargetUser] = React.useState(null);
  let [connectionWithMe, setConnectionWithMe] = React.useState(null);

  React.useMemo(() => {
    if (newConnEvent.action === "CREATED") {
      let created = newConnEvent.resource;
      if (targetUser.id === created.fromUserId) {
        let connectionWithMe = created;
        setConnectionWithMe(connectionWithMe);
      }
    }

    if (newConnEvent.action === "DELETED") {
      let deleted = newConnEvent.resource;
      if (connectionWithMe && connectionWithMe.id === deleted.id) {
        let connectionWithMe = null;
        setConnectionWithMe(connectionWithMe);
      }
    }

    if (newConnEvent.action === "ACCEPTED") {
      let updated = newConnEvent.resource;
      if (targetUser.id === updated.toUserId) {
        let connectionWithMe = updated;
        setConnectionWithMe(connectionWithMe);
      }
    }
  }, [newConnEvent]);

  React.useEffect(() => {
    init();
  }, [targetUserId]);

  const init = async () => {
    const targetUser = await companyService.getUserById(targetUserId);
    if (targetUser) {
      const conns = await companyService.getConnections(
        new URLSearchParams(`userIds=${[me.userId, targetUser.id]}`)
      );
      setTargetUser(targetUser);
      setConnectionWithMe(conns.length > 0 ? conns[0] : null);
    }
  };

  const resendConnect = async (targetUser) => {
    if (!targetUser) alert("Invalid target user");

    if (!me || Object.keys(me).length === 0 || !me.userId) {
      let ok = await window.createMtzConfirm("Please login to connect");
      if (ok) window.location.href = `/login?redirectUrl=${window.location.pathname}`;
      return;
    }

    if (me) {
      let created = await createConnection(targetUser);
      if (created) {
        alert("Connection request resent");
        setConnectionWithMe(created);
      }
    } else {
      let login = await window.createMtzConfirm("You need to login");
      if (login) {
        let currentPathName = window.location.pathname;
        window.location.href = "/login?redirectUrl=" + currentPathName;
      }
    }
  };

  const createConnection = async (targetUser) => {
    if (!targetUser) alert("Invalid target user");

    if (!me || Object.keys(me).length === 0 || !me.userId) {
      let ok = await window.createMtzConfirm("Please login to connect");
      if (ok) window.location.href = `/login?redirectUrl=${window.location.pathname}`;
      return;
    }

    const connection = { fromUserId: me.userId, toUserId: targetUser.id };

    if (me) {
      let created = await companyService.createConnection(connection);
      if (created) {
        setConnectionWithMe(created);

        // notify redux-subscribers
        publishConnectionEvent({ resource: created, action: "CREATE" });

        return created;
      }
    } else {
      let login = await window.createMtzConfirm("You need to login");
      if (login) {
        let currentPathName = window.location.pathname;
        window.location.href = "/login?redirectUrl=" + currentPathName;
      }
    }
  };

  const deleteConnection = async () => {
    let ok = await window.createMtzConfirm(
      `Do you want to cancel the connection with "${targetUser.firstname + " " + targetUser.lastname
      }"?`
    );
    if (!ok) return;

    let deleted = await companyService.deleteConnectionById(connectionWithMe.id);
    if (deleted) {
      setConnectionWithMe(null);

      // notify redux-subscribers
      publishConnectionEvent({ resource: deleted, action: "DELETE" });
    }
  };

  const acceptConnection = async () => {
    const connId = connectionWithMe.id;
    let updated = await companyService.updateConnectionById(connId, { activated: true });
    if (updated) setConnectionWithMe(updated);

    // notify redux-subscribers
    publishConnectionEvent({ resource: updated, action: "UPDATE" });
  };

  const renderRelationship = () => {
    return (
      <>
        {me.userId !== targetUser.id &&
          (connectionWithMe.activated === true
            ? renderActivatedRelationship()
            : renderUnActivatedRelationship())}
      </>
    );
  };

  const renderActivatedRelationship = () => {
    return (
      <button
        className={"btn btn-outline-primary" + (size === 'sm' ? ' btn-sm' : '')}
        onClick={deleteConnection}
      >
        <span className="connected-button-font">Connected</span>
      </button>
    );
  };

  const renderUnActivatedRelationship = () => {
    return (
      <>
        {connectionWithMe.fromUserId === me.userId
          ? renderSenderButtons()
          : renderReceiverButtons()}
      </>
    );
  };

  const renderSenderButtons = () => {
    return (
      <div className="btn-group">
        <button className={"btn btn-outline-secondary text-nowrap flex-fill border-right-0" + (size === 'sm' ? ' btn-sm' : '')}
          onClick={deleteConnection}
        >
          Connection sent
        </button>

        <span className="d-flex mtz-gap-4 align-items-center">
          <span data-toggle="tooltip" title="Resend connection request">
            <button className={"btn fa fa-repeat btn-outline-primary" + (size === 'sm' ? ' btn-sm' : '')}
              onClick={() => resendConnect(targetUser)}>
            </button>
          </span>
        </span>
      </div>
    );
  };

  const renderReceiverButtons = () => {
    return (
      <span className="d-flex mtz-gap-4 align-items-center">
        <span className="">
          Connection request from {(targetUser && targetUser.firstname) + " " + (targetUser && targetUser.lastname)}
        </span>
        -
        <a href="#"
          className="text-primary"
          onClick={acceptConnection}
        >
          Accept
        </a>
        /
        <a href="#"
          className="text-danger"
          onClick={deleteConnection}
        >
          Reject
        </a>

      </span>
    );
  };

  const renderNonRelationship = () => {
    return (me && targetUser && me.userId !== targetUser.id) ||
      JSON.stringify(targetUser) === "{}" ? (
      <button
        className={"btn btn-primary" + (size === 'sm' ? ' btn-sm' : '')}
        onClick={() => createConnection(targetUser)}
      >
        <span className="connect-button-font">Connect</span>
      </button>
    ) : (
      ""
    );
  };

  return <span>{connectionWithMe ? renderRelationship() : renderNonRelationship()}</span>;
}

const mapStateToProps = (state) => ({
  newConnEvent: state.connection_topic.new_uievent,
});

const mapDispatchToProps = (dispatch) => ({
  publishConnectionEvent: (event) => dispatch(connectionEventPublish(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectButton);
