import React from 'react';
import AuthContext from '../../../../AuthContext';
import mtzApis from '../../../../services';

const { companyService } = mtzApis;

function CGMemSubHeader({ group }) {
    let [text, setText] = React.useState();
    let [openEdit, setOpenEdit] = React.useState(false);
    let me = React.useContext(AuthContext);

    React.useEffect(() => {
        if (group?.meta?.memSubHeader)
            setText(group.meta.memSubHeader);
    }, [group]);

    const updateGroup = async e => {
        e.preventDefault();

        mtzApis.startSpinningIcon();
        let updated = await companyService.updateCompanyGroupById(group.id, { meta: { memSubHeader: text } });
        mtzApis.stopSpinningIcon();

        if (updated) {
            alert('Text updated');
            setOpenEdit(false);
            setText(updated.meta.memSubHeader);
        }
    };

    if (me.userId !== group.ownerId && !group.meta.memSubHeader)
        return <></>

    return (
        <div className="alert alert-info">
            {
                me.userId === group.ownerId &&
                <div className='mb-2'>
                    <button className="btn btn-sm btn-outline-primary"
                        onClick={() => setOpenEdit(!openEdit)}>
                        Add/Edit new member welcome text <i className="fa fa-edit"></i>
                    </button>
                </div>
            }

            {
                !openEdit && !!text &&
                <div>
                    {text}
                </div>
            }

            {
                openEdit &&
                <form onSubmit={updateGroup}>
                    <div className="form-group">
                        <label className="form-group-label">Edit text:</label>
                        <textarea className="form-control"
                            placeholder="E.g. Welcome to the group"
                            value={text}
                            onChange={e => setText(e.target.value)}></textarea>
                    </div>

                    <button className="btn btn-sm btn-outline-primary">
                        Save
                    </button>
                </form>
            }
        </div>
    );
}

export default CGMemSubHeader;