
import ImageResize from 'quill-image-resize-module-react';
import ImageUploader from "quill-image-uploader";
import React from "react";
import ReactQuill, { Quill } from 'react-quill';
import toDiffableHtml from 'diffable-html';
import 'react-quill/dist/quill.snow.css';

const options = { indent: 2, newline: '\n' }
const Delta = Quill.import("delta");
const Break = Quill.import("blots/break");
const Embed = Quill.import("blots/embed");
const lineBreakMatcher = () => {
  let newDelta = new Delta();
  newDelta.insert({ break: "" });
  return newDelta;
};
class SmartBreak extends Break {
  length() {
    return 1;
  }
  value() {
    return "\n";
  }

  insertInto(parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref);
  }
}
SmartBreak.blotName = "break";
SmartBreak.tagName = "BR";

const default_modules = {
  toolbar: {
    container: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ],
  },
  clipboard: {
    matchVisual: false,
    matchers: [['BR', lineBreakMatcher]]
  },
  keyboard: {
    bindings: {
      linebreak: {
        key: 13,
        shiftKey: true,
        handler: function (range) {
          let currentLeaf = this.quill.getLeaf(range.index)[0]
          let nextLeaf = this.quill.getLeaf(range.index + 1)[0]

          this.quill.insertEmbed(range.index, 'break', true, 'user');

          // Insert a second break if:
          // At the end of the editor, OR next leaf has a different parent (<p>)
          if (nextLeaf === null || (currentLeaf.parent !== nextLeaf.parent)) {
            this.quill.insertEmbed(range.index, 'break', true, 'user');
          }

          // Now that we've inserted a line break, move the cursor forward
          this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
        }
      }
    }
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }
}

export default function LimitedCharReactQuill({
  value,
  onChange,
  onBlur,
  className,
  limit,
  label,
  modules
}) {
  let [htmlMode, setHtmlMode] = React.useState(false);
  const [descContent, setDescContent] = React.useState(value);
  const [counter, setCounter] = React.useState(0);
  const [errorMessage, setMessage] = React.useState("");

  React.useEffect(() => {
    setCounter(getPlainText(value).length);
    setDescContent(value);
  }, [value]);

  function getPlainText(html) {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText;
  }

  const onKeyPressHandler = (event) => {
    if (counter >= limit && event.key !== "Backspace") event.preventDefault();
  };

  const onChangeHandler = (content, delta, source, editor) => {
    let charLength = getPlainText(content).length;
    setDescContent(content);
    setCounter(charLength);

    if (charLength > limit)
      setMessage(
        " (you have pasted/written more than 1000 characters, below description will not save) "
      );
    else if (!htmlMode && (charLength === 0 || editor.getText() === "")) {
      content = "";
      setMessage("");
    } else if (charLength <= limit && charLength !== 0) setMessage("");

    if (onChange) onChange({ target: { value: content } });
  };

  const onBlurHandler = () => {
    onBlur({ target: { value: descContent } });
  };

  const switchHtmlMode = () => {
    setHtmlMode(!htmlMode);
    setDescContent(toDiffableHtml(descContent));
  }

  return (
    <div className='form-group'>
      <div className='d-flex justify-content-between'>
        <label>{label || "Content"}:</label>
        {errorMessage && <small className={"text-danger"}>{errorMessage}</small>}

        <small className={counter <= limit ? "text-success " : "text-danger"}>
          {counter}/{limit}
        </small>
      </div>

      <div id="desc" onBlur={onBlurHandler}>
        <div className='my-1'>
          <button className='btn-sm btn-link btn' onClick={() => switchHtmlMode(!htmlMode)} type="button">
            Switch to {htmlMode ? "editor" : "HTML"} mode <i className='fa fa-code'></i>
          </button>
        </div>

        {
          !htmlMode ? (
            <ReactQuill
              value={descContent}
              onChange={onChangeHandler}
              onKeyDown={onKeyPressHandler}
              modules={modules || default_modules}
              placeholder="Write something..."
              className={className}
            />
          ) : (
            <textarea rows="20"
              value={descContent}
              onChange={e => onChangeHandler(e.target.value)}
              onKeyDown={onKeyPressHandler}
              placeholder="E.g. <p>Write something...</p>"
              className={'form-control ' + className}
            />
          )
        }
      </div>
    </div>
  );
}

LimitedCharReactQuill.defaultProps = {
  onChange: () => undefined,
  onBlur: () => undefined,
  value: "",
  className: "",
  limit: 1000,
};

const Link = Quill.import("formats/link");
// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = ["http", "https", "mailto", "tel", "radar", "rdar", "smb", "sms"];

class CustomLinkSanitizer extends Link {
  static sanitize(url) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url);

    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === "about:blank") return sanitizedUrl;

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (protocol) {
      return sanitizedUrl.startsWith(protocol);
    });

    if (hasWhitelistedProtocol) return sanitizedUrl;

    // if not, then append only 'http' to not to be a relative URL
    return `https://${sanitizedUrl}`;
  }
  static create(value) {
    const node = super.create(value);
    node.setAttribute("rel", "noopener nofollow"); // Set rel attribute to "noopener nofollow"
    return node;
  }
}

// Quill.register(SmartBreak);
Quill.register(CustomLinkSanitizer, true);
Quill.register("modules/imageUploader", ImageUploader);
Quill.register('modules/imageResize', ImageResize);