import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGCommRate = (CGCommRate) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups/cg-comm-rates`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, CGCommRate, headers);
};

export const deleteCGCommRateById = (CGCommRateId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups/cg-comm-rates/${CGCommRateId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGCommRateById = (CGCommRateId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups/cg-comm-rates/${CGCommRateId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGCommRates = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups/cg-comm-rates?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGCommRateStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups/cg-comm-rates/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCGCommRateById = (CGCommRateId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/company-groups/cg-comm-rates/${CGCommRateId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

