import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const createEmailCampaign = emailCampaign => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, emailCampaign, headers);
}

const getEmailCampaignById = emailCampaignId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns/${emailCampaignId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getEmailCampaigns = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

const getMarketingEmailDynamicFields = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns/dynamic-fields`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

const getEmailCampaignStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers, true, false);
}

export {
  createEmailCampaign, getEmailCampaignById, getEmailCampaigns,
  getEmailCampaignStats, getMarketingEmailDynamicFields
};
