import { COMPANY_SERVER_URL } from "../config";

import * as captchaCheckApi from './captchaCheckApi';
import * as companyExtraQuestionAnswerApi from './companyExtraQuestionAnswerApi';
import * as companyExtraQuestionApi from './companyExtraQuestionApi';
import * as companyExtraQuestionChoiceApi from './companyExtraQuestionChoiceApi';
import * as productApi from './productApi';
import * as productUploadEventApi from './productUploadEventApi';
import * as productWatchlistApi from './productWatchlistApi';
import * as rfiApi from './rfiApi';
import * as rfiType from './rfiRequestTypeApi';
import * as rfqApi from './rfqApi';
import * as rfqGroupApi from './rfqGroupApi';
import * as rfqImageApi from './rfqImageApi';
import * as rfqSearchApi from './rfqSearchApi';
import * as syncedMtzPlanSubscriptionApi from './syncedMtzPlanSubscriptionApi';
import * as tagAlertApi from './tagAlertApi';


import {
  createResourceLimit,
  deleteResourceLimitById,
  getResourceLimitById,
  getResourceLimits,
  getResourceLimitStats,
  updateResourceLimitById
} from "./resourceLimitApi";

import {
  createPrice,
  deletePriceById,
  deletePrices,
  getPriceById,
  getPrices,
  updatePriceById,
} from "./priceApi";

import {
  generateCompanySiteMap,
  generateProductSiteMap,
} from "./siteMapApi";

import * as companyApi from "./companyApi";
import * as companyMemberApi from "./companyMemberApi";
import * as companyMemberInviteApi from "./companyMemberInviteApi";
import { getCompanySearches, getCompanySearchStats } from "./companySearchApi";
import * as coProfilePercentApi from './coProfilePercentApi';
import * as productImageApi from './productImageApi';
import * as productSearchApi from "./productSearchApi";
import * as resourceSyncApi from './resourceSyncApi';
import * as syncedUserApi from './syncedUserApi';

import {
  createCategory,
  deleteCategories,
  deleteCategoryById,
  getCategories,
  getCategoryById,
  getCategoryCount,
  updateCategoryById
} from "./categoryApi";

import {
  createCompanyWatchlist,
  deleteCompanyWatchlistById,
  deleteCompanyWatchlists,
  getCompanyWatchlistById,
  getCompanyWatchlists,
  getCompanyWatchlistStats,
  shareCompanyWatchlist,
  updateCompanyWatchlistById,
} from "./companyWatchlistApi";

import { getEmployeeRangeCodes } from "./employeeRangeCodes";

import { getPhoneCode, getPhoneCodeCountries } from "./phoneCodeApi";

import {
  createCompanyNote,
  deleteCompanyNoteById,
  getCompanyNoteById,
  getCompanyNotes,
  getCompanyNoteStats,
  updateCompanyNoteById
} from "./companyNoteApi";

import {
  createCompanyFile,
  deleteCompanyFileById,
  getCompanyFileById,
  getCompanyFiles,
  getCompanyFileStats,
  updateCompanyFileById,
} from "./companyFile";
import {
  createCompanyNoteTodo,
  deleteCompanyNoteTodoById,
  getCompanyNoteTodos,
  getCompanyNoteTodoStats,
  updateCompanyNoteTodoById
} from "./companyNoteTodoApi";

import {
  createNoteFile,
  deleteNoteFileById,
  getNoteFileById,
  getNoteFiles,
  getNoteFileStats,
  updateNoteFileById
} from "./noteFileApi";

import {
  createCompanyName,
  deleteCompanyNameById,
  getCompanyNameById,
  getCompanyNames,
  getCompanyNameStats,
  updateCompanyNameById
} from "./companyNameApi";

import {
  acceptFileAccess,
  deleteFileAccess,
  requestFileAccess
} from "./fileAccessApi";

import * as adApi from "./adApi";
import * as adCampaignApi from "./adCampaignApi";
import * as adCampaignBudgetApi from "./adCampaignBudgetApi";
import * as adCampaignClickChargeApi from "./adCampaignClickChargeApi";
import * as adCampaignDepositApi from "./adCampaignDepositApi";
import * as adClickApi from "./adClickApi";
import * as adSearchApi from "./adSearchApi";
import * as cgSponsorPlanPriceApi from "./cgSponsorPlanPriceApi";
import * as cgSponsorPlanSubscriptionApi from "./cgSponsorPlanSubscriptionApi";
import * as companyActionApi from "./companyActionApi";
import * as companyAdKeywordApi from "./companyAdKeywordApi";
import * as companyAdKeywordSearchApi from "./companyAdKeywordSearchApi";
import * as companyMtzFileApi from "./companyMtzFileApi";
import * as companySearchReportApi from "./companySearchReportApi";
import * as mtzFileRequestApi from "./mtzFileRequestApi";
import * as productActionApi from "./productActionApi";
import * as productMenuApi from "./productMenuApi";
import * as productMenuItemApi from "./productMenuItemApi";
import * as productSearchReportApi from "./productSearchReportApi";

// network
import {
  generateCGEventSiteMap,
  generateCompanyGroupPostSiteMap,
  generateCompanyGroupSiteMap,
  generateIndexSiteMap,
  generateStaticPagesSiteMap
} from './siteMapApi';

import {
  createTrackingCode,
  // updateTrackingCodeById,
  deleteTrackingCodeById,
  getTrackingCodeById,
  getTrackingCodes,
  getTrackingCodeStats,
} from "./trackingCodeApi";

import {
  createTrackingCodeLog,
  getTrackingCodeLogs,
  getTrackingCodeStatStats
} from "./trackingCodeLogApi";

import {
  deleteCronEmails,
  getCronEmails,
  getCronEmailStats,
  sendCronEmails,
  viewSampleCronEmailById
} from './cronEmailApi';

import * as contactApi from "./contactApi";
import * as contactGroupApi from "./contactGroupApi";
import * as contactUploadEventApi from "./contactUploadEventApi";
import * as formBuilderApi from "./formBuilderApi";

import {
  createFormBuilderFeedback,
  deleteFormBuilderFeedbackById,
  getFormBuilderFeedbackById,
  getFormBuilderFeedbackReport,
  getFormBuilderFeedbacks,
  getFormBuilderFeedbackStats
} from "./formBuilderFeedbackApi";

import {
  createFormBuilderItem,
  deleteFormBuilderItemById,
  getFormBuilderItemById,
  getFormBuilderItems,
  getFormBuilderItemStats,
  updateFormBuilderItemById
} from "./formBuilderItemApi";

import {
  createConnection,
  deleteConnectionById,
  deleteConnections,
  getConnectionById,
  getConnections,
  getConnectionStats,
  updateConnectionById
} from "./connectionApi";

import {
  createFollow,
  deleteFollowById,
  getFollows,
  getFollowStats
} from "./followApi";

import {
  getCompanyGroupPostCaches,
  getCompanyGroupPostCacheStats,
} from "./companyGroupPostCacheApi";

import * as cgCommRateApi from "./cgCommRateApi";
import * as cgDonationApi from './cgDonationApi';
import * as cgDonationPaymentApi from './cgDonationPaymentApi';
import * as cgDonationPriceApi from './cgDonationPriceApi';
import * as cgEventSponsorApi from "./cgEventSponsorApi";
import * as cgEventSponsorPlanApi from "./cgEventSponsorPlanApi";
import * as cgEventSponsorPlanOrderApi from "./cgEventSponsorPlanOrderApi";
import * as cgEventSponsorPlanPriceApi from "./cgEventSponsorPlanPriceApi";
import * as cgEventTicketApi from './cgEventTicketApi';
import * as cgEventTicketOrderApi from './cgEventTicketOrderApi';
import * as cgEventTicketOrderQRCodeApi from './cgEventTicketOrderQRCodeApi';
import * as cgMemberInviteApi from './cgMemberInviteApi';
import * as cgMemberPlanApi from "./cgMemberPlanApi";
import * as cgMemberPlanOrderApi from "./cgMemberPlanOrderApi";
import * as cgMemberPlanOTPriceApi from "./cgMemberPlanOTPriceApi";
import * as cgMemberPlanPriceApi from "./cgMemberPlanPriceApi";
import * as cgMemberPlanSubscriptionApi from "./cgMemberPlanSubscriptionApi";
import * as cgMemberRequestApi from './cgMemberRequestApi';
import * as cgSponsorInviteApi from './cgSponsorInviteApi';
import * as cgSponsorPlanApi from "./cgSponsorPlanApi";
import * as companyGroupApi from "./companyGroupApi";
import * as companyGroupCategoryApi from "./companyGroupCategoryApi";
import * as companyGroupMemberApi from "./companyGroupMemberApi";
import * as couponUseApi from './couponUseApi';
import * as emailCampaignApi from "./emailCampaignApi";
import * as mtzPointBalanceApi from './mtzPointBalanceApi';
import * as mtzPointPromotionApi from './mtzPointPromotionApi';
import * as mtzPointRateApi from './mtzPointRateApi';
import * as mtzPointRewardApi from './mtzPointRewardApi';
import * as mtzScoreApi from './mtzScoreApi';
import * as referralTrackingCodeApi from './referralTrackingCodeApi';
import * as referralTrackingCodeRewardApi from './referralTrackingCodeRewardApi';
import * as rewardPointRedeemApi from './rewardPointRedeemApi';
import * as syncedCompanyApi from './syncedCompanyApi';
import * as syncedProductApi from './syncedProductApi';

import {
  acceptInviteCompanyGroupMember,
  acceptRequestCompanyGroupMember,
  inviteCompanyGroupMember,
  inviteCompanyGroupMemberByEmail,
  joinCompanyGroupMember
} from "./companyGroupMemberActionApi";

import {
  createCompanyGroupUserPref,
  deleteCompanyGroupUserPrefById,
  getCompanyGroupUserPrefById,
  getCompanyGroupUserPrefs,
  getCompanyGroupUserPrefStats,
  updateCompanyGroupUserPrefById,
} from "./companyGroupUserPrefApi";


import {
  createCompanyGroupPostFile,
  deleteCompanyGroupPostFileById,
  getCompanyGroupPostFileById,
  getCompanyGroupPostFiles,
  getCompanyGroupPostFileStats,
  updateCompanyGroupPostFileById
} from "./companyGroupPostFileApi";

import {
  createCompanyGroupPostLike,
  deleteCompanyGroupPostLikeById,
  getCompanyGroupPostLikeById,
  getCompanyGroupPostLikes,
  getCompanyGroupPostLikeStats,
  updateCompanyGroupPostLikeById
} from "./companyGroupPostLikeApi";

import {
  createCompanyGroupPostComment,
  deleteCompanyGroupPostCommentById,
  getCompanyGroupPostCommentById,
  getCompanyGroupPostComments,
  getCompanyGroupPostCommentStats,
  updateCompanyGroupPostCommentById
} from "./companyGroupPostCommentApi";

import {
  createCompanyGroupPostCommentReply,
  deleteCompanyGroupPostCommentReplyById,
  getCompanyGroupPostCommentReplies,
  getCompanyGroupPostCommentReplyById,
  getCompanyGroupPostCommentReplyStats,
  updateCompanyGroupPostCommentReplyById
} from "./companyGroupPostCommentReplyApi";

import {
  createCompanyGroupPostCommentLike,
  deleteCompanyGroupPostCommentLikeById,
  getCompanyGroupPostCommentLikeById,
  getCompanyGroupPostCommentLikes,
  getCompanyGroupPostCommentLikeStats,
  updateCompanyGroupPostCommentLikeById
} from "./companyGroupPostCommentLikeApi";

import {
  createCompanyGroupPostCommentReplyLike,
  deleteCompanyGroupPostCommentReplyLikeById,
  getCompanyGroupPostCommentReplyLikeById,
  getCompanyGroupPostCommentReplyLikes,
  getCompanyGroupPostCommentReplyLikeStats,
  updateCompanyGroupPostCommentReplyLikeById
} from "./companyGroupPostCommentReplyLikeApi";

import * as cgAdBannerApi from "./cgAdBannerApi";
import * as cgAdBannerAuctionApi from "./cgAdBannerAuctionApi";
import * as cgEventApi from "./cgEventApi";
import * as cgEventInterestApi from "./cgEventInterestApi";
import * as cgEventResourceApi from "./cgEventResourceApi";
import * as cgEventSearchApi from "./cgEventSearchApi";
import * as cgPostApi from "./cgPostApi";
import * as cgResourceApi from "./cgResourceApi";
import * as cgSponsorAdApi from "./cgSponsorAdApi";
import * as cgSponsorClickApi from './cgSponsorClickApi';
import * as companyGroupActionApi from "./companyGroupActionApi";
import * as companyGroupBannerAdTemplateApi from "./companyGroupBannerAdTemplateApi";
import * as companyGroupPostActionApi from "./companyGroupPostActionApi";
import * as companyGroupPostSearchApi from "./companyGroupPostSearchApi";
import * as companyGroupPostSearchReportApi from "./companyGroupPostSearchReportApi";
import * as companyGroupSearchApi from "./companyGroupSearchApi";
import * as companyGroupSearchReportApi from "./companyGroupSearchReportApi";
import * as companyGroupSponsorAdTemplateApi from "./companyGroupSponsorAdTemplateApi";
import * as companyGroupSponsorApi from "./companyGroupSponsorApi";
import * as companyGroupSponsorshipApi from './companyGroupSponsorshipApi';
import * as companyGroupTypeApi from "./companyGroupTypeApi";
import * as companyGroupUploadEventApi from './companyGroupUploadEventApi';
import * as companyPostApi from "./companyPostApi";
import * as connectionQRApi from "./connectionQRApi";
import * as emailSchemaApi from './emailSchemaApi';
import * as emailSchemaLayoutApi from './EmailSchemaLayoutApi';
import * as postApi from "./postApi";
import * as postCacheApi from "./postCacheApi";
import * as postCommentApi from "./postCommentApi";
import * as postFileApi from "./postFileApi";
import * as postLikeApi from "./postLikeApi";

// network end

// user

import * as authReqApi from './authReqApi';
import * as healthCheckApi from './healthCheckApi';
import * as healthCheckLogApi from './healthCheckLogApi';
import * as mtzFileApi from './mtzFileApi';
import * as mtzFileSecretApi from './mtzFileSecretApi';
import * as mtzSharedFileApi from './mtzSharedFileApi';
import * as userApi from './userApi';
import * as userBioApi from './userBioApi';
import * as userBioBannerApi from './userBioBannerApi';
import * as userSearchApi from './userSearchApi';

import {
  createEmails,
  getEmailCount,
  getEmails
} from "./emailApi";

import * as geoApi from "./geoApi";
import * as mtzExceptionApi from "./mtzExceptionApi";
import * as mtzFaqApis from "./mtzFaqApi";
import * as mtzWalletTransactionApi from "./mtzWalletTransactionApi";
import * as sitemapApi from "./siteMapApi";
import * as socialAuthReqApi from './socialAuthReqApi';
import * as userExtraQuestionAnswerApi from './userExtraQuestionAnswerApi';
import * as userExtraQuestionApi from './userExtraQuestionApi';
import * as userExtraQuestionChoiceApi from './userExtraQuestionChoiceApi';
// user end

const getBackendHost = () => COMPANY_SERVER_URL;

export default {
  updateCompanyWatchlistById,
  getCompanyWatchlistById,
  getCompanyWatchlists,
  getCompanyWatchlistStats,
  createCompanyWatchlist,
  deleteCompanyWatchlistById,
  deleteCompanyWatchlists,
  shareCompanyWatchlist,

  getCategories,
  // getCategoryStats,
  getCategoryCount,
  getCategoryById,
  deleteCategoryById,
  deleteCategories,
  updateCategoryById,
  createCategory,

  getPriceById,
  getPrices,
  createPrice,
  updatePriceById,
  deletePriceById,
  deletePrices,

  getCompanySearches,
  getCompanySearchStats,

  getBackendHost,

  getEmployeeRangeCodes,

  getPhoneCode,
  getPhoneCodeCountries,

  createCompanyNote,
  deleteCompanyNoteById,
  getCompanyNoteById,
  getCompanyNotes,
  getCompanyNoteStats,
  updateCompanyNoteById,

  createCompanyNoteTodo,
  deleteCompanyNoteTodoById,
  updateCompanyNoteTodoById,
  getCompanyNoteTodos,
  getCompanyNoteTodoStats,

  createCompanyFile,
  updateCompanyFileById,
  deleteCompanyFileById,
  getCompanyFileById,
  getCompanyFileStats,
  getCompanyFiles,

  generateProductSiteMap,
  generateCompanySiteMap,

  getNoteFiles,
  getNoteFileStats,
  getNoteFileById,
  createNoteFile,
  deleteNoteFileById,
  updateNoteFileById,

  getCompanyNameById,
  getCompanyNames,
  getCompanyNameStats,
  updateCompanyNameById,
  deleteCompanyNameById,
  createCompanyName,

  requestFileAccess,
  acceptFileAccess,
  deleteFileAccess,

  createResourceLimit,
  getResourceLimitById,
  getResourceLimits,
  getResourceLimitStats,
  updateResourceLimitById,
  deleteResourceLimitById,

  ...companyExtraQuestionApi,
  ...companyExtraQuestionChoiceApi,
  ...companyExtraQuestionAnswerApi,
  ...syncedUserApi,
  ...coProfilePercentApi,
  ...productWatchlistApi,
  ...resourceSyncApi,
  ...companyAdKeywordApi,
  ...companyAdKeywordSearchApi,
  ...productUploadEventApi,
  ...companyActionApi,
  ...productActionApi,
  ...companySearchReportApi,
  ...productSearchReportApi,
  ...adCampaignApi,
  ...adCampaignBudgetApi,
  ...adApi,
  ...adClickApi,
  ...adSearchApi,
  ...adCampaignDepositApi,
  ...adCampaignClickChargeApi,
  ...companyMtzFileApi,
  ...mtzFileRequestApi,
  ...rfqApi,
  ...rfiApi,
  ...rfiType,
  ...rfqImageApi,
  ...rfqSearchApi,
  ...rfqGroupApi,
  ...syncedMtzPlanSubscriptionApi,
  ...productSearchApi,
  ...productImageApi,
  ...companyApi,
  ...companyMemberApi,
  ...companyMemberInviteApi,
  ...productApi,
  ...tagAlertApi,
  ...captchaCheckApi,
  ...postApi,
  ...postCacheApi,
  ...cgPostApi,
  ...companyPostApi,
  ...postFileApi,
  ...postCommentApi,
  ...postLikeApi,
  ...productMenuApi,
  ...productMenuItemApi,

  // network
  getFollows,
  createFollow,
  deleteFollowById,
  getFollowStats,

  getBackendHost,

  createConnection,
  deleteConnectionById,
  deleteConnections,
  updateConnectionById,
  getConnectionStats,
  getConnections,
  getConnectionById,

  generateStaticPagesSiteMap,
  generateIndexSiteMap,
  generateCompanyGroupPostSiteMap,
  generateCompanyGroupSiteMap,
  generateCGEventSiteMap,

  createResourceLimit,
  getResourceLimitById,
  getResourceLimits,
  getResourceLimitStats,
  updateResourceLimitById,
  deleteResourceLimitById,

  ...contactApi,
  ...contactUploadEventApi,
  ...contactGroupApi,

  ...formBuilderApi,

  createFormBuilderFeedback,
  getFormBuilderFeedbackStats,
  getFormBuilderFeedbacks,
  getFormBuilderFeedbackById,
  deleteFormBuilderFeedbackById,
  getFormBuilderFeedbackReport,

  createFormBuilderItem,
  getFormBuilderItemStats,
  getFormBuilderItems,
  getFormBuilderItemById,
  deleteFormBuilderItemById,
  updateFormBuilderItemById,

  getTrackingCodes,
  getTrackingCodeStats,
  getTrackingCodeById,
  createTrackingCode,
  deleteTrackingCodeById,

  createTrackingCodeLog,
  getTrackingCodeLogs,
  getTrackingCodeStatStats,

  getCompanyGroupPostCacheStats,
  getCompanyGroupPostCaches,

  ...companyGroupApi,
  ...emailCampaignApi,
  ...cgCommRateApi,
  ...companyGroupCategoryApi,
  ...companyGroupMemberApi,
  ...cgMemberPlanApi,
  ...cgMemberPlanPriceApi,
  ...cgMemberPlanOTPriceApi,
  ...cgMemberPlanOrderApi,
  ...cgMemberPlanSubscriptionApi,

  joinCompanyGroupMember,
  inviteCompanyGroupMember,
  acceptInviteCompanyGroupMember,
  acceptRequestCompanyGroupMember,
  inviteCompanyGroupMemberByEmail,

  createCompanyGroupUserPref,
  getCompanyGroupUserPrefById,
  getCompanyGroupUserPrefStats,
  getCompanyGroupUserPrefs,
  deleteCompanyGroupUserPrefById,
  updateCompanyGroupUserPrefById,

  getCompanyGroupPostFileById,
  getCompanyGroupPostFileStats,
  getCompanyGroupPostFiles,
  updateCompanyGroupPostFileById,
  createCompanyGroupPostFile,
  deleteCompanyGroupPostFileById,

  getCronEmails,
  getCronEmailStats,
  viewSampleCronEmailById,
  deleteCronEmails,
  sendCronEmails,

  getCompanyGroupPostCommentReplyById,
  getCompanyGroupPostCommentReplyStats,
  deleteCompanyGroupPostCommentReplyById,
  createCompanyGroupPostCommentReply,
  updateCompanyGroupPostCommentReplyById,
  getCompanyGroupPostCommentReplies,

  getCompanyGroupPostLikeById,
  getCompanyGroupPostLikeStats,
  deleteCompanyGroupPostLikeById,
  updateCompanyGroupPostLikeById,
  createCompanyGroupPostLike,
  getCompanyGroupPostLikes,

  getCompanyGroupPostCommentById,
  getCompanyGroupPostCommentStats,
  deleteCompanyGroupPostCommentById,
  updateCompanyGroupPostCommentById,
  createCompanyGroupPostComment,
  getCompanyGroupPostComments,

  getCompanyGroupPostCommentLikeById,
  getCompanyGroupPostCommentLikeStats,
  getCompanyGroupPostCommentLikes,
  deleteCompanyGroupPostCommentLikeById,
  updateCompanyGroupPostCommentLikeById,
  createCompanyGroupPostCommentLike,

  getCompanyGroupPostCommentReplyLikeById,
  getCompanyGroupPostCommentReplyLikeStats,
  getCompanyGroupPostCommentReplyLikes,
  deleteCompanyGroupPostCommentReplyLikeById,
  updateCompanyGroupPostCommentReplyLikeById,
  createCompanyGroupPostCommentReplyLike,

  ...companyGroupSponsorApi,
  ...cgResourceApi,
  ...cgEventApi,
  ...cgEventInterestApi,
  ...cgEventResourceApi,
  ...cgEventSearchApi,
  ...companyGroupSearchApi,
  ...companyGroupSponsorshipApi,
  ...emailSchemaApi,
  ...emailSchemaLayoutApi,
  ...syncedUserApi,
  ...syncedMtzPlanSubscriptionApi,
  ...companyGroupUploadEventApi,
  ...companyGroupTypeApi,
  ...syncedCompanyApi,
  ...syncedProductApi,
  ...companyGroupSponsorAdTemplateApi,
  ...connectionQRApi,
  ...companyGroupPostSearchReportApi,
  ...companyGroupPostSearchApi,
  ...companyGroupPostActionApi,
  ...companyGroupActionApi,
  ...companyGroupSearchReportApi,
  ...companyGroupBannerAdTemplateApi,
  ...companyGroupSearchReportApi,
  ...cgAdBannerApi,
  ...cgAdBannerAuctionApi,
  ...cgSponsorAdApi,
  ...referralTrackingCodeApi,
  ...cgSponsorClickApi,
  ...referralTrackingCodeRewardApi,
  ...mtzPointPromotionApi,
  ...mtzPointRateApi,
  ...mtzPointBalanceApi,
  ...mtzPointRewardApi,
  ...mtzScoreApi,
  ...couponUseApi,
  ...rewardPointRedeemApi,
  ...cgMemberInviteApi,
  ...cgMemberRequestApi,
  ...cgSponsorPlanApi,
  ...cgSponsorPlanPriceApi,
  ...cgSponsorPlanSubscriptionApi,
  ...cgSponsorInviteApi,
  ...cgEventTicketApi,
  ...cgEventTicketOrderApi,
  ...cgEventTicketOrderQRCodeApi,
  ...cgEventSponsorPlanApi,
  ...cgEventSponsorPlanPriceApi,
  ...cgEventSponsorPlanOrderApi,
  ...cgEventSponsorApi,

  ...cgDonationApi,
  ...cgDonationPriceApi,
  ...cgDonationPaymentApi,
  // network end

  // user
  ...authReqApi,
  ...userApi,
  ...userSearchApi,
  ...userBioApi,
  ...userBioBannerApi,

  createEmails,
  getEmails,
  getEmailCount,

  ...mtzFaqApis,
  ...mtzWalletTransactionApi,
  ...resourceSyncApi,
  ...geoApi,
  ...socialAuthReqApi,
  ...mtzExceptionApi,
  ...sitemapApi,
  ...healthCheckApi,
  ...healthCheckLogApi,
  ...mtzFileApi,
  ...mtzFileSecretApi,
  ...mtzSharedFileApi,
  ...userExtraQuestionApi,
  ...userExtraQuestionAnswerApi,
  ...userExtraQuestionChoiceApi


  // user end
};
