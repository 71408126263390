import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getCompanyMemberInvites = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/members/invites?${queries}`;
  let params = { spinnerOn: true };
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

const getCompanyMemberInviteStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/members/invites/stats?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, { spinnerOn: false }, headers);
};

const createCompanyMemberInvite = company => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/members/invites`;
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, company, headers);
};

const getCompanyMemberInviteById = companyId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/members/invites/` + companyId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, companyId, headers);
};

const updateCompanyMemberInviteById = (companyId, company) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/members/invites/${companyId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, company, headers);
};

const deleteCompanyMemberInviteById = companyId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/companies/members/invites/${companyId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, companyId, headers);
};

export {
  createCompanyMemberInvite,
  deleteCompanyMemberInviteById,
  getCompanyMemberInviteById,
  getCompanyMemberInvites,
  getCompanyMemberInviteStats,
  updateCompanyMemberInviteById
};

