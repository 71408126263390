import React from "react";

import Moment from "react-moment";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../../../../AuthContext";
import mtzApis from '../../../../services';
import PaymentSelector from "../../../payment-service/general/PaymentSelector";
import OneClickLoginSignUp from "../../../user-service/users/OneClickLoginSignUp";
import CompanyGroupMemberCategoryManager from "../../companyGroupCategories/CompanyGroupMemberCategoryManager";
import CGMemSubHeader from "../../companyGroupMembers/CGMemSubHeader";
const { companyService, paymentService } = mtzApis;

export default function CGJoinButton({ profileName, className }) {
  let [group, setGroup] = React.useState(null);
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    let mounted = true;
    init();
    return () => { mounted = false; };
  }, []);

  const init = async () => {
    let groups = await companyService.getCompanyGroups(new URLSearchParams(`profileName=${profileName}`));
    if (groups && groups.length > 0) {
      group = groups[0];

      let prices = await companyService.getCGMemberPlanPrices(new URLSearchParams(`companyGroupIds=${group.id}&asDeactivated=false`));
      if (prices) {
        prices.sort((a, b) => a.cents - b.cents);
        group.prices = prices;
      }

      let otPrices = await companyService.getCGMemberPlanOTPrices(new URLSearchParams(`companyGroupIds=${group.id}`));
      if (otPrices) {
        otPrices.sort((a, b) => a.cents - b.cents);
        group.otPrices = otPrices;
      }

      setGroup(group || undefined);
    }
  };

  return (
    <>
      {group === null && 'Loading...'}

      {
        group && me.userId !== group.ownerId &&
        (
          group.asPremium ?
            <PremiumJoinButtons group={group} /> :
            <FreeJoinButton group={group} />
        )
      }
    </>
  );
}

function PremiumJoinButtons({ group }) {
  let [mem, setMem] = React.useState(null);
  let [sub, setSub] = React.useState(null);
  let [order, setOrder] = React.useState(null);
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    let mounted = true;
    init(mounted);
    return () => { mounted = false };
  }, []);

  const init = async (mounted) => {
    if (mounted) {
      let mems = await companyService.getCompanyGroupMembers(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));
      setMem(mems[0]);

      let subs = await companyService.getCGMemberPlanSubscriptions(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));
      if (subs && subs.length > 0) {
        sub = subs[0];
        sub.price = await companyService.getCGMemberPlanPriceById(sub.cgMemberPlanPriceId);
        setSub(sub);
      }

      let orders = await companyService.getCGMemberPlanOrders(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}&asCompleted=false`));
      if (orders && orders.length > 0)
        setOrder(orders[0]);
    }
  };

  const leaveGroup = async () => {
    const ok = await window.createMtzConfirm(`Are you sure? If you have a group subscription, it will be cancelled immediately. Also, It will removed in the next billing cycle`);
    if (!ok) return;

    mtzApis.startSpinningIcon();
    let del = await companyService.deleteCompanyGroupMemberById(mem.id);

    if (del) {
      alert(`You have lost access to this group`);
      setTimeout(() => window.location.reload(), 500);
    }
    mtzApis.stopSpinningIcon();
  };

  if (mem === null)
    return 'loading...';

  if (mem && mem.role === 'ADMIN')
    return <></>;

  if (!!mem)
    return <>
      <button className="btn btn-secondary"
        data-target='#modal-manage-membership' data-toggle='modal'>
        group joined
      </button>

      <div className="modal" id='modal-manage-membership'>
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="mtz-h5">Group membership</h5>
              <button className="close fa fa-close" data-dismiss='modal'></button>
            </div>

            <div className="modal-body">
              <div>
                <span className="text-success">
                  Thank you for being a member of the group
                </span> <NavLink className="mtz-h5 text-primary"
                  to={`/groups/${group.profileName}`} target="_blank">
                  {group.name}</NavLink>
              </div>

              <div>
                {
                  sub &&
                  <>
                    <div>Membership payment type: <b className="mtz-h5">Subscription</b></div>
                    <div>Price: <b>${sub.price.cents / 100}/{sub.price.type}</b></div>
                    <div>Next billing date: <b><Moment fromNow>{sub.nextExpiredAt}</Moment></b></div>
                    <hr />
                    <div>
                      * Manage this membership subscription? <NavLink className="text-primary" target="_blank"
                        to={`/me/billing/subscriptions/cg-member-subscriptions/${sub?.id}`}>CLICK HERE</NavLink>
                    </div>
                  </>
                }

                {
                  order &&
                  <>
                    <div>Membership payment type: <b className="mtz-h5">One-time (No recurring)</b></div>
                    <div>Begin date: <b><Moment fromNow>{order.createdAt}</Moment></b></div>
                    <div>End date: <b><Moment fromNow>{order.expiredAt}</Moment></b> (<small className="text-danger">
                      * Membership will be removed automatically on the end date.
                    </small>)</div>
                  </>
                }
              </div>
            </div>

            <div className="modal-footer">
              <button className="btn btn-secondary" data-dismiss='modal'>close</button>
            </div>
          </div>
        </div>
      </div>
    </>

  return (
    <>
      {
        !mem && mem !== null &&
        <>
          <button data-target='#group-price-modal' data-toggle='modal' className={`btn btn-${mem ? 'secondary' : 'primary'}`}>
            join group
          </button>

          <div className="modal" id='group-price-modal' data-backdrop='static'>
            <div className="modal-dialog modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="d-flex w-100">
                    <h5 className="flex-fill mtz-h5">
                      {
                        me.userId ?
                          'Select Membership Payment Plan:' :
                          'First, you need to have an account on MyTradeZone, it is FREE.  Then you can join the group.'
                      }
                    </h5>

                    <button className="close fa fa-close" data-dismiss='modal'></button>
                  </div>
                </div>

                <div className="modal-body">
                  <CGMemSubHeader group={group} />

                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <button className="nav-link active" data-toggle="tab" data-target="#sub" role="tab">Subscription Prices</button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link" data-toggle="tab" data-target="#one" role="tab">One-Time Prices</button>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <br />
                    <div className="tab-pane active" id="sub" role="tabpanel">
                      <SubscribeButton mem={mem} group={group} />
                    </div>
                    <div className="tab-pane" id="one" role="tabpanel">
                      <OneTimeButton mem={mem} group={group} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

function OneTimeButton({ mem, group }) {
  let [selected, setSelected] = React.useState(null);
  let [cards, setCards] = React.useState(null);
  let [billAddrs, setBillAddrs] = React.useState(null);
  let [order, setOrder] = React.useState(null);
  let [wallet, setWallet] = React.useState(null);
  let [coupon, setCoupon] = React.useState('');
  let [calc, setCalc] = React.useState(null);
  let [couponObjs, setCouponObjs] = React.useState([]);
  let [myCustomer, setMyCustomer] = React.useState(null);
  let [paymentMethod, setPaymentMethod] = React.useState();
  let [paymentInstrumentId, setPaymentInstrumentId] = React.useState();
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    init();
  }, [group, mem]);

  const init = async () => {
    if (!me.userId)
      return;

    // load my customer
    myCustomer = await paymentService.getMyMtzCustomer();
    if (myCustomer) {
      setMyCustomer(myCustomer);
      if (myCustomer.paymentMethod) setPaymentMethod(myCustomer.paymentMethod);

      if (myCustomer.paymentMethod === 'CARD') {
        let cards = await paymentService.getCards(new URLSearchParams(``));
        if (cards) {
          let defaultCard = cards.find(c => c.asDefault);
          if (defaultCard) {
            setPaymentInstrumentId(defaultCard.id);
          }
        }
      }
    }

    // load my order
    let orders = await companyService.getCGMemberPlanOrders(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));
    if (orders && orders.length > 0) {
      order = orders[0];
      setOrder(order);
    }

    // load cards
    cards = await paymentService.getCards(new URLSearchParams(``));
    setCards(cards);

    // load billing addresses
    billAddrs = await paymentService.getBillingAddresses(new URLSearchParams(``));
    setBillAddrs(billAddrs);

    // get wallet
    wallet = await paymentService.getMyWallet();
    setWallet(wallet || undefined);
  };

  const selectPrice = async pr => {
    selected = pr;
    setSelected(selected);
    calcSubPrice();
  };

  const calcSubPrice = async () => {
    if (!selected) return;

    let codes = [];
    if (couponObjs)
      codes = couponObjs.map(c => c.code);
    if (coupon)
      codes.push(coupon);

    let params = {
      cents: selected.cents,
      sellerUserId: group.ownerId,
      buyerUserEmail: me.email,
      couponCodes: [...new Set(codes)],
      _dryRun: true
    };
    if (paymentMethod)
      params.paymentMethod = paymentMethod;
    if (paymentInstrumentId)
      params.paymentInstrumentId = paymentInstrumentId;

    let created = await paymentService.createMtzCharge(params);

    if (created) {
      setCalc(created);
      setCoupon('');
      let couponData = await paymentService.getMtzCoupons(new URLSearchParams(`codes=${codes}`));
      setCouponObjs(couponData);
    }
  };

  const pay = async () => {
    const params = { cgMemberPlanPriceId: selected.id };
    if (paymentMethod) params.paymentMethod = paymentMethod;
    if (paymentInstrumentId) params.paymentInstrumentId = paymentInstrumentId;
    if (couponObjs) params.couponCodes = couponObjs.map(c => c.code);

    mtzApis.startSpinningIcon();
    let created = await companyService.createCGMemberPlanOrder(params);
    mtzApis.stopSpinningIcon();

    if (created) {
      alert(`Payment success! Thank you for your payment to join the group ${group.name}.`);
      window.location.href = `/groups/${group.profileName}`;
    }
  };

  if (!group) return <>Invalid group</>;

  if (!group.otPrices || group.otPrices.length === 0)
    return <>One-time payment not available</>

  if (!me.userId)
    return <OneClickLoginSignUp showRegisterButton={true} />

  if (mem)
    return (
      <button className="btn btn-secondary">joined</button>
    );
  else
    return (
      <>
        {
          !selected ?
            <div className="list-group">
              {
                group.otPrices.map(pr => (
                  <div key={pr.id} className="list-group-item">
                    <div><b>Price:</b> ${pr.cents / 100}</div>
                    <div><b>Description:</b> {pr.description}</div>
                    <div><b>Number of month(s):</b> {pr.monthNumber}</div>
                    <div className="text-right">
                      <button onClick={() => selectPrice(pr)} className="btn btn-sm btn-outline-primary">Select</button>
                    </div>
                  </div>
                ))
              }
            </div> :
            <div className="d-flex flex-column mtz-gap-16">
              <h5 className="text-center text-primary mtz-h5">Review & Payment</h5>

              {/* <div className="alert alert-info mtz-rounded-16">
                    <b>Please read:</b> Your wallet will be used to deduct money for the payment. In case your wallet balance is below the payment amount, your default credit card on file will be used to fill the balance of your wallet then deduct the payment. Note the minimum balance refill amount is $15. By clicking on Confirm & Pay, I agree to this terms and conditions.
                  </div> */}

              <PaymentSelector onChange={async paymentInfo => {
                if (paymentInfo.paymentMethod) {
                  paymentMethod = paymentInfo.paymentMethod;
                  setPaymentMethod(paymentMethod);
                  calcSubPrice();

                  // refresh wallet state
                  if (paymentMethod === 'WALLET') {
                    wallet = await paymentService.getMyWallet();
                    if (wallet)
                      setWallet(wallet);
                  }

                  if (paymentMethod === 'CARD') {
                    cards = await paymentService.getCards();
                    if (cards)
                      setCards(cards);
                  }
                }

                if (paymentInfo.paymentInstrumentId) {
                  paymentInstrumentId = paymentInfo.paymentInstrumentId;
                  setPaymentInstrumentId(paymentInstrumentId);
                }
              }} />

              {/* coupon */}
              <div className="border mtz-p-16 mtz-rounded-16 mtz-gap-8 d-flex flex-column">
                <h6 className="mtz-h6 text-muted">Apply coupon(s):</h6>
                <div>
                  <div className="w-100 w-md-50">
                    <div className="d-flex mtz-gap-4">
                      <input onChange={e => {
                        // sanitize coupon 6-12 chars
                        let c = e.target.value.replace(/[^a-z0-9]/g, '').trim().toLowerCase();
                        setCoupon(c);
                      }} value={coupon || ''}
                        className="form-control" type="text" placeholder="E.g. abc123" />
                      <button className="btn btn-primary" onClick={calcSubPrice}>
                        Apply</button>
                    </div>
                  </div>
                </div>
                <small>* Coupon only has lowercased letters and numbers</small>
                {
                  couponObjs && couponObjs.length > 0 &&
                  <div>
                    <h6 className="mtz-h6"><u>Coupon applied:</u></h6>
                    <div>
                      {
                        couponObjs.map(c => (
                          <div key={c} className="rounded">
                            &emsp;- {c.code} (discount value: {
                              c.type === 'PERCENTAGE' ? `${c.value}%` : `$${c.value / 100}`
                            }) <i onClick={() => {
                              couponObjs = couponObjs.filter(cc => cc.id !== c.id);
                              setCouponObjs(couponObjs);
                              calcSubPrice();
                            }} className="fa fa-trash text-danger mtz-cursor-pointer"></i>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                }
              </div>

              <div className='d-flex'>
                <span className='flex-grow-1'></span>
                <div className="alert alert-light mtz-rounded-16">
                  <div><b>- Group:</b> <span className='text-primary h5'>{group.name}</span></div>
                  <div><b>- Price:</b> ${calc?.cents / 100}/{selected?.type}</div>
                  {
                    couponObjs && couponObjs.length > 0 &&
                    <div><b>- After Discount:</b> ${(calc?.finalCents - calc?.tranxCents) / 100}</div>
                  }
                  <div><b>- Transaction Fee:</b> ${calc?.tranxCents / 100}</div>
                  <div className='mtz-h5 border-top'><b>- Total Amount:</b> <span className='text-primary'>${calc && calc.finalCents / 100}</span></div>
                </div>
              </div>

              <div className="d-flex">
                <div>
                  <button onClick={() => setSelected(null)} className="btn btn-secondary text-nowrap">Back</button>
                </div>

                <span className="flex-fill"></span>

                {
                  (paymentMethod === 'CARD') && (
                    (calc && calc.finalCents > 0 && (!cards || cards.length === 0)) ?
                      <div className="text-danger">
                        You need to add a card to make payment
                      </div> :
                      <div>
                        <button onClick={pay} className="btn btn-primary text-nowrap">
                          Confirm & Subscribe
                        </button>
                      </div>
                  )
                }

                {
                  (paymentMethod === 'WALLET') && (
                    (calc && calc.finalCents > 0 && (!wallet || wallet.cents < calc.finalCents)) ?
                      <div className="text-danger">
                        Your wallet balance is not enough to make payment
                      </div> :
                      <div>
                        <button onClick={pay} className="btn btn-primary text-nowrap">
                          Confirm & Subscribe
                        </button>
                      </div>
                  )
                }

                {
                  !paymentMethod &&
                  <div className="text-danger">
                    Please select a payment method
                  </div>
                }
              </div>
            </div>
        }
      </>
    );
}


function SubscribeButton({ group, mem }) {
  let [selected, setSelected] = React.useState(null);
  let [cards, setCards] = React.useState(null);
  let [billAddrs, setBillAddrs] = React.useState(null);
  let [sub, setSub] = React.useState(null);
  let [wallet, setWallet] = React.useState(null);
  let [coupon, setCoupon] = React.useState('');
  let [calc, setCalc] = React.useState(null);
  let [couponObjs, setCouponObjs] = React.useState([]);
  let [myCustomer, setMyCustomer] = React.useState(null);
  let [paymentMethod, setPaymentMethod] = React.useState();
  let [paymentInstrumentId, setPaymentInstrumentId] = React.useState();
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    init();
  }, [group]);

  const init = async () => {
    if (!me.userId)
      return;

    // load my customer
    let myCustomer = await paymentService.getMyMtzCustomer();
    if (myCustomer) {
      setMyCustomer(myCustomer);
      if (myCustomer.paymentMethod) setPaymentMethod(myCustomer.paymentMethod);

      if (myCustomer.paymentMethod === 'CARD') {
        let cards = await paymentService.getCards(new URLSearchParams(``));
        if (cards) {
          let defaultCard = cards.find(c => c.asDefault);
          if (defaultCard) {
            setPaymentInstrumentId(defaultCard.id);
          }
        }
      }
    }

    // load my sub
    let subs = await companyService.getCGMemberPlanSubscriptions(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));
    if (subs && subs.length > 0) {
      sub = subs[0];
      sub.price = await companyService.getCGMemberPlanPriceById(sub.cgMemberPlanPriceId);
      setSub(sub);
    }

    // load cards
    cards = await paymentService.getCards(new URLSearchParams(``));
    setCards(cards);

    // load billing addresses
    billAddrs = await paymentService.getBillingAddresses(new URLSearchParams(``));
    setBillAddrs(billAddrs);

    // get wallet
    wallet = await paymentService.getMyWallet();
    setWallet(wallet || undefined);
  };

  const subscribe = async () => {
    const params = {
      cgMemberPlanPriceId: selected.id
    };
    if (paymentMethod) params.paymentMethod = paymentMethod;
    if (paymentInstrumentId) params.paymentInstrumentId = paymentInstrumentId;
    if (couponObjs) params.couponCodes = couponObjs.map(c => c.code);

    mtzApis.startSpinningIcon();
    let created = await companyService.createCGMemberPlanSubscription(params);
    mtzApis.stopSpinningIcon();

    if (created) {
      alert(`Payment success! Thank you for your subscription to the group ${group.name}.`);
      window.location.href = `/groups/${group.profileName}`;
    }
  };

  const updatePrice = async updateParams => {
    const ok = await window.createMtzConfirm(`This new plan will start in the next billing cycle, proceed?`);
    if (!ok) return;

    mtzApis.startSpinningIcon();
    let updated = await companyService.updateCGMemberPlanSubscriptionById(sub.id, updateParams);
    mtzApis.stopSpinningIcon();

    if (updated) {
      alert(`Membership plan updated`);
      window.location.reload();
    }
  };

  const selectPrice = async pr => {
    selected = pr;
    setSelected(selected);
    calcSubPrice();
  };

  const calcSubPrice = async () => {
    if (!selected) return;

    let codes = [];
    if (couponObjs)
      codes = couponObjs.map(c => c.code);
    if (coupon)
      codes.push(coupon);

    let params = {
      cents: selected.cents,
      sellerUserId: group.ownerId,
      buyerUserEmail: me.email,
      couponCodes: [...new Set(codes)],
      _dryRun: true
    };
    if (paymentMethod)
      params.paymentMethod = paymentMethod;
    if (paymentInstrumentId)
      params.paymentInstrumentId = paymentInstrumentId;

    let created = await paymentService.createMtzCharge(params);

    if (created) {
      setCalc(created);
      setCoupon('');
      let couponData = await paymentService.getMtzCoupons(new URLSearchParams(`codes=${codes}`));
      setCouponObjs(couponData);
    }
  };

  return (
    <>
      {
        (!group || !group.prices || group.prices.length === 0) ?
          ('Subscription plan is not available.') :
          (
            me.userId ?
              <>
                {
                  !selected ?
                    <div className="list-group">
                      {
                        group.prices.map(pr => (
                          <div key={pr.id} className="list-group-item">
                            <div><b>Price:</b> ${pr.cents / 100}</div>
                            <div><b>Payment cycle:</b> {pr.type === 'month' ? 'MONTHLY' : 'YEARLY'}</div>
                            <div className="text-right">
                              <button onClick={() => selectPrice(pr)} className="btn btn-sm btn-outline-primary">Select</button>
                            </div>
                          </div>
                        ))
                      }
                    </div> :
                    <div className="d-flex flex-column mtz-gap-16">
                      <h5 className="text-center text-primary mtz-h5">Review & Payment</h5>

                      {/* <div className="alert alert-info mtz-rounded-16">
                                <b>Please read:</b> Your wallet will be used to deduct money for the payment. In case your wallet balance is below the payment amount, your default credit card on file will be used to fill the balance of your wallet then deduct the payment. Note the minimum balance refill amount is $15. By clicking on Confirm & Pay, I agree to this terms and conditions.
                              </div> */}

                      <PaymentSelector onChange={async paymentInfo => {
                        if (paymentInfo.paymentMethod) {
                          paymentMethod = paymentInfo.paymentMethod;
                          setPaymentMethod(paymentMethod);
                          calcSubPrice();

                          // refresh wallet state
                          if (paymentMethod === 'WALLET') {
                            wallet = await paymentService.getMyWallet();
                            if (wallet)
                              setWallet(wallet);
                          }

                          if (paymentMethod === 'CARD') {
                            cards = await paymentService.getCards();
                            if (cards)
                              setCards(cards);
                          }
                        }

                        if (paymentInfo.paymentInstrumentId) {
                          paymentInstrumentId = paymentInfo.paymentInstrumentId;
                          setPaymentInstrumentId(paymentInstrumentId);
                        }
                      }} />

                      {/* coupon */}
                      <div className="border mtz-p-16 mtz-rounded-16 mtz-gap-8 d-flex flex-column">
                        <h6 className="mtz-h6 text-muted">Apply coupon(s):</h6>
                        <div>
                          <div className="w-100 w-md-50">
                            <div className="d-flex mtz-gap-4">
                              <input onChange={e => {
                                // sanitize coupon 6-12 chars
                                let c = e.target.value.replace(/[^a-z0-9]/g, '').trim().toLowerCase();
                                setCoupon(c);
                              }} value={coupon || ''}
                                className="form-control" type="text" placeholder="E.g. abc123" />
                              <button className="btn btn-primary" onClick={calcSubPrice}>
                                Apply</button>
                            </div>
                          </div>
                        </div>
                        <small>* Coupon only has lowercased letters and numbers</small>
                        {
                          couponObjs && couponObjs.length > 0 &&
                          <div>
                            <h6 className="mtz-h6"><u>Coupon applied:</u></h6>
                            <div>
                              {
                                couponObjs.map(c => (
                                  <div key={c} className="rounded">
                                    &emsp;- {c.code} (discount value: {
                                      c.type === 'PERCENTAGE' ? `${c.value}%` : `$${c.value / 100}`
                                    }) <i onClick={() => {
                                      couponObjs = couponObjs.filter(cc => cc.id !== c.id);
                                      setCouponObjs(couponObjs);
                                      calcSubPrice();
                                    }} className="fa fa-trash text-danger mtz-cursor-pointer"></i>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        }
                      </div>

                      <div className='d-flex'>
                        <span className='flex-grow-1'></span>
                        <div className="alert alert-light mtz-rounded-16">
                          <div><b>- Group:</b> <span className='text-primary h5'>{group.name}</span></div>
                          <div><b>- Price:</b> ${calc?.cents / 100}/{selected?.type}</div>
                          {
                            couponObjs && couponObjs.length > 0 &&
                            <div><b>- After Discount:</b> ${(calc?.finalCents - calc?.tranxCents) / 100}</div>
                          }
                          <div><b>- Transaction Fee:</b> ${calc?.tranxCents / 100}</div>
                          <div className='mtz-h5 border-top'><b>- Total Amount:</b> <span className='text-primary'>${calc && calc.finalCents / 100}</span></div>
                        </div>
                      </div>

                      <div className="d-flex">
                        <div>
                          <button onClick={() => setSelected(null)} className="btn btn-secondary text-nowrap">Back</button>
                        </div>

                        <span className="flex-fill"></span>

                        {
                          (paymentMethod === 'CARD') && (
                            (calc && calc.finalCents > 0 && (!cards || cards.length === 0)) ?
                              <div className="text-danger">
                                You need to add a card to make payment
                              </div> :
                              <div>
                                <button onClick={subscribe} className="btn btn-primary text-nowrap">
                                  Confirm & Subscribe
                                </button>
                              </div>
                          )
                        }

                        {
                          (paymentMethod === 'WALLET') && (
                            (calc && calc.finalCents > 0 && (!wallet || wallet.cents < calc.finalCents)) ?
                              <div className="text-danger">
                                Your wallet balance is not enough to make payment
                              </div> :
                              <div>
                                <button onClick={subscribe} className="btn btn-primary text-nowrap">
                                  Confirm & Subscribe
                                </button>
                              </div>
                          )
                        }

                        {
                          !paymentMethod &&
                          <div className="text-danger">
                            Please select a payment method
                          </div>
                        }
                      </div>
                    </div>
                }
              </> :
              <OneClickLoginSignUp showRegisterButton={true} />
          )
      }

      {/* {
        sub &&
        <>
          {
            mem ?
              <>
                <div className="dropdown">
                  <button data-toggle='dropdown'
                    className={`btn btn-${mem ? 'secondary' : 'primary'}`}>
                    {sub ? (sub.cancelled ? <span>group joined, but membership unsubscribed</span> : 'group subscribed') : 'group joined'}
                  </button>

                  <div className="dropdown-menu">
                    {
                      sub &&
                      <a className="dropdown-item" href="#" data-toggle='modal' data-target='#membership-manage-popup'>Manage member subscription</a>
                    }

                    {
                      sub && sub.cancelled &&
                      <a href='' className="dropdown-item" onClick={() => updateCancel({ cancelled: false })}>
                        Re-subscribe membership <span className="text-danger">(access removed <Moment fromNow>{sub.nextExpiredAt}</Moment>)</span>
                      </a>
                    }

                    <a className="dropdown-item text-danger" onClick={() => leaveGroup()} href="#">Leave group</a>
                  </div>
                </div>

                {
                  sub &&
                  <div className="modal" id='membership-manage-popup'>
                    <div className="modal-dialog modal-dialog-scrollable modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <div className="d-flex w-100">
                            <h5 className="mtz-h5 flex-fill">Update group membership subscription:</h5>
                            <button className="btn-sm btn" data-dismiss='modal'>
                              <span className="fa fa-close"></span>
                            </button>
                          </div>
                        </div>

                        <div className="modal-body">
                          <div>
                            <h5 className="">Current subscription:</h5>
                            <div><b>Plan price:</b> ${sub.price && sub.price.cents / 100} {sub.type.toUpperCase() + 'LY'}</div>
                            <div><b>Subscription status:</b> {sub.cancelled ? <span className="text-danger">UNSUBSCRIBED</span> : <span className="text-success">SUBSCRIBED</span>}</div>
                            <div><b>Date expired:</b> <Moment fromNow>{sub.nextExpiredAt}</Moment></div>
                            <div><b>Date started:</b> <Moment fromNow>{sub.createdAt}</Moment></div>
                            <div>
                              <b>Next billing cycle plan:</b> <span className='text-primary'> ${sub.price?.cents / 100}/{sub.price?.type.toLowerCase()}ly</span>
                            </div>
                          </div>

                          <hr />

                          <div>
                            <h5 className="">Change subscription plan:</h5>

                            <div className="d-flex mtz-gap-4">
                              {
                                group.prices.map(pr => (
                                  <div onClick={() => updatePrice({ _switchToPriceId: pr.id })}
                                    key={pr.id} className="btn btn-outline-primary">
                                    <b>Price:</b> ${pr.cents / 100} - {pr.type === 'month' ? 'MONTHLY' : 'YEARLY'}
                                  </div>
                                ))
                              }
                            </div>

                            <hr />
                            <div className="text-right">
                              {
                                !sub.cancelled ?
                                  <button className="btn btn-outline-danger" onClick={() => updateCancel({ cancelled: true })}>
                                    Unsubscribe membership
                                  </button> :
                                  <button className="btn btn-outline-success" onClick={() => updateCancel({ cancelled: false })}>
                                    Re-subscribe membership <span className="text-danger">(access removed <Moment fromNow>{sub.nextExpiredAt}</Moment>)</span>
                                  </button>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </> :
              <>
                <div>
                  {
                    sub.cancelled &&
                    <div>
                      <button className="btn btn-outline-danger" onClick={() => updateCancel({ cancelled: !sub.cancelled })}>
                        membership removed, subscription cancelled. Click to re-join
                      </button>
                    </div>
                  }

                  {
                    sub.paymentError &&
                    <div>
                      <div className="my-1 alert alert-danger mtz-rounded-16">
                        <div><b>Member-subscription error</b></div>
                        <div>{sub.paymentError}.</div>
                        <div>
                          Please click <NavLink to='/me/billing/payment-accounts' target='_blank'>
                            HERE
                          </NavLink> to update payment info.
                        </div>
                        <div>
                          Then click <button className="btn btn-sm btn-outline-primary" onClick={retryPayment}>Retry payment</button>.
                        </div>
                      </div>
                    </div>
                  }

                </div>
              </>
          }
        </>
      } */}
    </>
  );
}

function FreeJoinButton({ group }) {
  let [req, setReq] = React.useState(null);
  let [mem, setMem] = React.useState(null);
  let [showCategory, setShowCategory] = React.useState(false);
  const modalAddAccountRef = React.useRef();
  let me = React.useContext(AuthContext);


  React.useEffect(() => {
    let mounted = true;
    init(mounted);
    return () => { mounted = false };
  }, []);

  const init = async mounted => {
    let mems = await companyService.getCompanyGroupMembers(new URLSearchParams(`userIds=${me.userId}&companyGroupIds=${group.id}`));
    if (mounted) setMem(mems[0] || undefined);

    let reqs = await companyService.getCGMemberRequests(new URLSearchParams(`ownerId=${me.userId}&companyGroupId=${group.id}`));
    if (mounted) setReq(reqs[0] || undefined);
  };

  const joinGroup = async () => {
    if (!me || !me.userId) {
      const ok = await window.createMtzConfirm(<div>
        Please login or sign-up to join group. Proceed?
      </div>, 'Sure, take me to login', 'No, later');
      if (ok)
        window.open('/login', '_blank').focus();
      return;
    }

    mtzApis.startSpinningIcon();
    let created = await companyService.createCGMemberRequest({ companyGroupId: group.id });
    mtzApis.stopSpinningIcon();

    if (created) {
      if (!group.asMemberApprovalRequired) {
        let mems = await companyService.getCompanyGroupMembers(new URLSearchParams(`companyGroupIds=${group.id}&userIds=${me.userId}`));
        if (mems && mems.length > 0) {
          setMem(mems[0]);
          setShowCategory(true);
        }

      }
      else
        setReq(created);
    }
  };

  const leaveGroup = async () => {
    const ok = await window.createMtzConfirm(`We are sorry to see you leave. Are you sure?`);
    if (!ok) return;

    mtzApis.startSpinningIcon();
    let del = await companyService.deleteCompanyGroupMemberById(mem.id);
    mtzApis.stopSpinningIcon();

    if (del) {
      setMem(undefined);
    }
  };

  const deleteReq = async () => {
    const ok = await window.createMtzConfirm(`Do you want to cancel this group-join request?`);
    if (!ok) return;

    mtzApis.startSpinningIcon();
    let del = await companyService.deleteCGMemberRequestById(req.id);
    mtzApis.stopSpinningIcon();

    if (del) {
      setReq(null);
    }
  };

  return (
    <>
      {
        !req && !mem &&
        (
          (!me || !me.userId) ?
            <>
              <button data-target='#modal-add-account' data-toggle='modal' className="btn btn-primary">
                join group
              </button>

              <div className="modal" id='modal-add-account' ref={modalAddAccountRef} data-backdrop='static'>
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="d-flex w-100">
                        <h5 className="mtz-h5 flex-fill">
                          First, you need to have an account on MyTradeZone, it is FREE.  Then you can join the group.
                        </h5>
                        <button className="btn btn-sm close" onClick={() => modalAddAccountRef.current.click()}>
                          <span className="fa fa-close"></span>
                        </button>
                      </div>
                    </div>

                    <div className="modal-body">
                      <div className="">
                        <h5 className='mtz-h5 text-center'>{'Welcome to MyTradeZone, the business networking platform'}</h5>

                        <NavLink to="/register" className="w-100 btn py-3 btn-warning">
                          No account? register here
                        </NavLink>

                        <OneClickLoginSignUp />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </> :
            <button onClick={joinGroup} className="btn btn-primary">
              join group
            </button>
        )
      }

      {
        req && !mem &&
        <button className="btn btn-secondary" onClick={deleteReq}>
          request sent
        </button>
      }

      {
        mem &&
        <button onClick={leaveGroup} className="btn btn-secondary">
          leave group
        </button>
      }
      {
        mem && showCategory &&
        <CompanyGroupMemberCategoryManager companyGroupId={mem.companyGroupId} companyGroupMemberId={mem.id} />

      }
    </>
  );
}