import React from "react";
import DirectRooms from './DirectRooms';
import Invites from './Invites';
import RegularRooms from './RegularRooms';
import RfqRooms from './RfqRooms';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import mtzApis from "../../../../services";

const { chatService, companyService } = mtzApis;

function RoomManager({ room, mode, onChange, onModeChange }) {
    React.useEffect(() => {
        let mounted = true;

        return () => mounted = false;
    }, [room, mode]);

    return (
        <div className="d-flex flex-column mtz-gap-16 h-100 overflow-auto">
            <div className="d-flex justify-content-between border border-bottom bg-light p-2 align-items-center"
                style={{ borderRadius: '8px 8px 0 0' }}>
                <h5 className="mtz-h5 m-0 d-flex align-items-center" style={{ height: '40px' }}>
                    Chat Rooms:
                </h5>

                {/* <div className="text-right dropdown">
                    <button data-toggle='dropdown' className="btn btn-sm btn-outline-secondary border">
                        <span className="fa fa-bars"></span> Menu
                    </button>

                    <div className="dropdown-menu">
                        <div onClick={() => {
                            if (onModeChange)
                                onModeChange('');
                        }} className="btn dropdown-item">
                            <span className="fa fa-arrow-left"></span> Back
                        </div>

                        <div onClick={() => {
                            if (onModeChange)
                                onModeChange('CHAT_RFQ');
                        }} className="btn dropdown-item">
                            RFQ conversations
                        </div>
                    </div>
                </div> */}
            </div>

            <div className="rounded p-2 d-flex flex-column mtz-gap-16 overflow-auto flex-fill">
                <Invites />

                {
                    !mode &&
                    <>
                        <RegularRooms onChange={onChange} room={room} />
                        <DirectRooms onChange={onChange} room={room} />
                        <RfqRooms onChange={onChange} room={room} />
                    </>
                }
            </div>
        </div>
    );
}

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

const RoomsWrapper = connect(stateToProps, dispatchToProps)(withRouter(RoomManager));

export default RoomsWrapper;