import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGDonationPrice = (price) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-donation-prices`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, price, headers);
};

export const deleteCGDonationPriceById = (priceId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-donation-prices/${priceId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGDonationPriceById = (priceId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-donation-prices/${priceId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGDonationPrices = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-donation-prices?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGDonationPriceStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-donation-prices/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCGDonationPriceById = (priceId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-donation-prices/${priceId}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
};