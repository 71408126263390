import React from "react";
import { useLocation, withRouter } from "react-router-dom";
import mtzApis from "../../../../services";
import { MyTabScrollButton, StyledTab, StyledTabs } from "../../../shared2/TabStyledComponents";
import "./style.css";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import AuthContext from "../../../../AuthContext";
import ImageUploader from "../../../shared2/ImageUploader";
import LimitedCharTextField from "../../../shared2/LimitedCharTextField";

const { companyService } = mtzApis;


function CompanyDetailNavBar(props) {
  let [profileName, setProfileName] = React.useState(props.match.params.profileName);
  let [productSearchStats, setProductSearchStats] = React.useState(null);
  let [companyGroupStats, setCompanyGroupStats] = React.useState(null);
  let [myCompany, setMyCompany] = React.useState(null);
  let [currCompany, setCurrCompany] = React.useState(null);
  const location = useLocation();
  const currentTab = location.pathname;
  const [rfiDialogOpen, setRfiDialogOpen] = React.useState(false);
  const [rfi, setRfi] = React.useState({});
  let [files, setFiles] = React.useState([]);
  const [rfiTypes, setRfiTypes] = React.useState([]);
  const [selectedRfiTypes, setSelectedRfiTypes] = React.useState([]);
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    (async () => {
      let profileName = props.match.params.profileName;
      setProfileName(profileName);

      const companies = await companyService.getCompanies(
        new URLSearchParams(`profileName=${profileName}`)
      );
      let currCompany = companies.length > 0 ? companies[0] : null;


      if (currCompany === null) return;

      setCurrCompany(currCompany);

      let productSearchStats = await companyService.getProductSearchStats(
        new URLSearchParams(`companyIds=${currCompany.id}`)

      );
      setProductSearchStats(productSearchStats);

      let companyGroupStats = await companyService.getCompanyGroupMemberStats(
        new URLSearchParams(`userIds=${me.userId}`)
      );
      setCompanyGroupStats(companyGroupStats);

      let myCompany = await companyService.getMyCompany();

      setMyCompany(myCompany);

      let rfiTypes = await companyService.getRfiTypes();
      setRfiTypes(rfiTypes)

    })();
  }, [props.match.params]);

  const handleRfiDialogOpen = async () => {
    if (!me || !me.userId) {
      let ok = await window.createMtzConfirm("You need to login to create RFI. Do you want to login now?");
      if (!ok) return;

      window.open("/login", "_blank");
      return;
    }


    setRfiDialogOpen(true);
  };

  const handleRfiDialogClose = () => {
    setRfiDialogOpen(false);
    // Reset the RFI state
    setRfi({});
  };

  const handleCreateRfi = async () => {
    const formData = new FormData();
    if (files)
      files.forEach(f => formData.append('files', f.file));
    formData.append('rfi', new Blob([JSON.stringify({ ...rfi, companyId: currCompany.id, requestTypeIds: selectedRfiTypes, })], { type: 'application/json' }));
    // Call the createRfi function from the companyService
    const created = await companyService.createRfi(formData);


    // Close the dialog
    handleRfiDialogClose();
  };


  return (
    <div className="navbar">
      <StyledTabs
        className="w-100"
        ScrollButtonComponent={MyTabScrollButton}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
      >
        <StyledTab
          label={<span>Overview</span>}
          value={`/profile/${profileName}`}
          onClick={() => props.history.push(`/profile/${profileName}`)}
        />
        {productSearchStats && productSearchStats.all.count > 0 && (
          <StyledTab
            label={<span>Products</span>}
            value={`/profile/${profileName}/products`}
            onClick={() => props.history.push(`/profile/${profileName}/products`)}
          />
        )}

        <StyledTab
          label={<span>Posts</span>}
          value={`/profile/${profileName}/posts`}
          onClick={() => props.history.push(`/profile/${profileName}/posts`)}
        />

        {companyGroupStats && companyGroupStats.all.count > 0 && (
          <StyledTab
            label={<span>Groups</span>}
            value={`/profile/${profileName}/groups`}
            onClick={() => props.history.push(`/profile/${profileName}/groups`)}
          />
        )}
        {currCompany && myCompany && ((currCompany.images && currCompany.images.length > 0) ||
          currCompany.id === myCompany.id) && (
            <StyledTab
              label={<span>Images</span>}
              value={`/profile/${profileName}/images`}
              onClick={() => props.history.push(`/profile/${profileName}/images`)}
            />
          )}
        <StyledTab
          className={`company-product-menu`}
          label={<span>Menus</span>}
          value={`/profile/${profileName}/product-menus`}
          onClick={() => props.history.push(`/profile/${profileName}/product-menus`)}
        />
        <StyledTab
          label={<span>Files</span>}
          value={`/profile/${profileName}/resources`}
          onClick={() => props.history.push(`/profile/${profileName}/resources`)}
        />
        {currCompany && (
          <StyledTab
            label={<span>Contact/RFI</span>}

            onClick={handleRfiDialogOpen} />)}
      </StyledTabs>

      {/* RFI Dialog */}
      {currCompany &&
        <Dialog
          open={rfiDialogOpen}
          onClose={handleRfiDialogClose}
          aria-labelledby="rfi-dialog-title"
        >
          <DialogTitle id="rfi-dialog-title">RFI - {currCompany.name}</DialogTitle>
          <DialogContent>
            <div className="form-group">
              <label>Title:</label>
              <input
                className="form-control w-100"
                placeholder={'Title'}
                onChange={(event) =>
                  setRfi({ ...rfi, title: event.target.value })
                }
              />
            </div>
            <LimitedCharTextField
              label="Short Description"
              limit={200}
              fullWidth
              value={rfi.shortDesc}
              onChange={(e) =>
                setRfi({ ...rfi, shortDesc: e.target.value })
              }
            />
            <LimitedCharTextField
              label="Long Description"
              limit={1000}
              fullWidth
              multiline
              minRows={4}
              maxRows={8}
              value={rfi.longDesc}
              onChange={(e) =>
                setRfi({ ...rfi, longDesc: e.target.value })
              }
            />
            {/* Dropdown with Checkboxes */}
            <div className="mt-2">
              <label>Choose options:</label>
              <div>
                {rfiTypes.map((rfiType) => (
                  <div key={rfiType.id} className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`rfi-type-${rfiType.id}`}
                      checked={selectedRfiTypes.includes(rfiType.id)}
                      onChange={() => {
                        const updatedRfiTypes = selectedRfiTypes.includes(rfiType.id)
                          ? selectedRfiTypes.filter((id) => id !== rfiType.id)
                          : [...selectedRfiTypes, rfiType.id];
                        setSelectedRfiTypes(updatedRfiTypes);
                      }}
                    />
                    <label className="form-check-label" htmlFor={`rfi-type-${rfiType.id}`}>
                      {rfiType.description}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-2">
              <label>RFI images (optional):</label>
              <ImageUploader onChange={data => setFiles(data)} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRfiDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCreateRfi} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>


  );
}

export default withRouter(CompanyDetailNavBar);
