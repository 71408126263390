import commonUtil from "../common";
import { METRIC_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = METRIC_SERVER_URL;

export const createEmailCampaign = (params) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, params, headers);
};

export const getEmailCampaigns = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns?${queries.toString()}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getEmailCampaignStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns/stats?${queries.toString()}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const deleteEmailCampaignById = async id => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return await commonUtil.httpclient(method, url, {}, headers);
};

export const updateEmailCampaignById = async (id, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return await commonUtil.httpclient(method, url, params, headers);
};

export const getEmailCampaignById = async id => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/email-campaigns/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return await commonUtil.httpclient(method, url, {}, headers);
};