import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import mtzApis from "../../../../services";
import localStorageUtil from "../../../../utils/localStorageUtil";

const { companyService } = mtzApis;

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      firstname: "",
      lastname: "",
      email: "",
      passwordType: "password",
      passwordValidationErrors: ["min", "uppercase", "digits", "letters"],
      password: "",
      confirm_password: "",
      companyName: "",
      hasCompany: true,
      extraQuestion: null,
      selectedBusinessTypes: [],
      isOpenDropdown: false,
      categories: [],
      selectedCategories: [],
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    let extraQuestions = await companyService.getUserExtraQuestions(
      new URLSearchParams(`keyword=offers`)
    );


    if (extraQuestions && extraQuestions.length > 0) {
      let businessTypeQuestion = extraQuestions[0];
      let questionId = businessTypeQuestion.id;
      let choices = await companyService.getUserExtraQuestionChoices(
        new URLSearchParams(`questionIds=${questionId}`)
      );

      businessTypeQuestion.choices = choices;

      this.setState({ extraQuestion: businessTypeQuestion });

      // load cats
      let categories = await companyService.getCategories(new URLSearchParams(`type=SIMPLE`));
      this.setState({ categories: categories });
    }
  };

  handlePasswordVisibility = () =>
    this.setState(({ passwordType }) => ({
      passwordType: passwordType === "text" ? "password" : "text",
      showPassword: !this.state.showPassword,
    }));

  passwordValidate = (password) => {
    let errors = this.findErrors(password);
    if (errors.length === 0) {
      this.setState({ password: password });
    }
    this.setState({ passwordValidationErrors: errors, password: password });
  };

  confirmPasswordValidate = (confirm_password) => {
    this.validate(confirm_password);
    this.setState({ confirm_password: confirm_password });
  };

  validate(confirm_password) {
    let { password } = this.state;
    let errors = {};
    let isValid = true;
    if (password !== "" && confirm_password !== "") {
      if (password !== confirm_password) {
        isValid = false;
        errors["confirm_password"] = "Password does not match!";
      } else {
        errors["confirm_password"] = "";
      }
    } else {
      errors["confirm_password"] = "Enter Password and Confirm Password!";
      isValid = false;
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }

  findErrors(password) {
    let errors = [];
    let uppercase = /[A-Z]/g;
    let specialCharacter = /[^a-zA-Z\d]/g;
    let digit = /[0-9]/g;
    let letter = /[a-zA-Z]/g;

    if (password.length < 8) errors.push("min");
    else {
      let index = errors.indexOf("min");
      if (index !== -1) errors.splice(index, 1);
    }

    if (!uppercase.test(password)) errors.push("uppercase");
    else {
      let index = errors.indexOf("uppercase");
      if (index !== -1) errors.splice(index, 1);
    }

    if (!digit.test(password)) errors.push("digits");
    else {
      let index = errors.indexOf("digits");
      if (index !== -1) errors.splice(index, 1);
    }

    if (!letter.test(password)) errors.push("letters");
    else {
      let index = errors.indexOf("letters");
      if (index !== -1) errors.splice(index, 1);
    }

    return errors;
  }

  createUserHandler = async (event) => {
    mtzApis.stopSpinningIcon();

    try {
      event.preventDefault();

      let {
        email,
        password,
        confirm_password,
        passwordValidationErrors,
        firstname,
        lastname,
        companyName,
        selectedCategories,
      } = this.state;

      if (this.state.hasCompany && companyName.length === 0) {
        alert("Company Name is required!!");
        return;
      }

      if (selectedCategories.length === 0) {
        alert("Please select at least one business type.");
        return;
      }

      if (this.validate(confirm_password) && passwordValidationErrors.length === 0) {
        let user = {};
        user["firstname"] = firstname;
        user["lastname"] = lastname;
        user["password"] = password;
        user["email"] = email;

        // Check local storage for tracking code
        let referralGroupTcId = localStorageUtil.get(
          localStorageUtil.COMPANY_SERVICE,
          localStorageUtil.TRACKING_CODES,
          'tcs'
        );

        if (referralGroupTcId) {
          referralGroupTcId = referralGroupTcId[referralGroupTcId.length - 1];
          const tc = await companyService.getTrackingCodeById(referralGroupTcId);
          // Check if companyGroupIds exists in tracking code metadata
          if (tc && tc.meta && tc.meta.companyGroupIds) {
            if (!user.meta)
              user.meta = {};
            user.meta = { referralCompanyGroupIds: tc.meta.companyGroupIds };
          }
        }

        if (companyName) {
          if (!user.meta)
            user.meta = {};

          user.meta.companyName = companyName;
          user.meta.categoryIds = selectedCategories.map((category) => category.id).join(",");
        }

        let confirmType = this.props.confirmType;
        user.confirmType = confirmType;

        mtzApis.startSpinningIcon();
        let createdUser = await companyService.createUser(user);
        if (createdUser) {
          let params = new URLSearchParams(window.location.search);
          let coupon = params.get("coupon");
          let tc = params.get("tc")

          if (!!coupon) {
            let res = await companyService.createCouponUse({ userId: createdUser.id, email: createdUser.email, code: coupon });
          }

          // if (this.state.hasCompany) {
          //   alert(selectedCategories.length)
          //   let created = await companyService.createCompany({
          //     name: companyName,
          //     categoryIds: selectedCategories.map((category) => category.id),
          //   });
          //   alert(created.id)
          //   return;
          // }

          // if (this.state.hasCompany) {
          //   let a = await companyService.createCompanyName({
          //     userId: createdUser.id,
          //     companyName: companyName,
          //   });
          // }

          // let answers = selectedBusinessTypes.map((choice) => ({
          //   choiceId: choice.id,
          //   questionId: extraQuestion.id,
          //   userId: createdUser.id,
          // }));

          // await Promise.all(
          //   answers.map((answer) => companyService.createUserExtraQuestionAnswer(answer))
          // );

          if (this.props.onCreate) {
            this.props.onCreate(createdUser);
            return;
          }

          this.props.history.push("/register-success");
        }
      } else alert("Check password requirements!!");
    } catch (error) {
      console.log(error);
    } finally {
      mtzApis.stopSpinningIcon();
    }
  };

  resetForm = () => {
    this.setState({
      errors: {},
      firstname: null,
      lastname: null,
      email: null,
      password: null,
      confirm_password: null,
      showPassword: false,
    });
  };

  addBusinessType = (e) => {
    let typeId = e.target.value;
    this.setState({
      selectedBusinessTypes: [
        ...this.state.selectedBusinessTypes,
        this.state.extraQuestion.choices.find((choice) => choice.id === typeId)
      ]
    });
  };

  removeBusinessType = typeObj => {
    this.setState({
      selectedBusinessTypes: this.state.selectedBusinessTypes.filter((type) => type.id !== typeObj.id)
    });
  };

  addCategory = (e) => {
    let typeId = e.target.value;

    if (this.state.selectedCategories.find((category) => category.id === typeId))
      return;

    let selecteds = [
      ...this.state.selectedCategories,
      this.state.categories.find((category) => category.id === typeId)
    ];
    this.setState({
      selectedCategories: selecteds
    });
  };

  removeCategory = categoryObj => {
    this.setState({
      selectedCategories: this.state.selectedCategories.filter((category) => category.id !== categoryObj.id)
    });
  };

  render() {
    let { passwordType, errors, passwordValidationErrors, extraQuestion, selectedBusinessTypes } =
      this.state;
    return (
      <div className="mtz-card">
        <h5 className="mtz-h5 text-center">Welcome to MyTradeZone, the business networking platform</h5>

        <form
          className="d-flex w-100 flex-column"
          onSubmit={(event) => {
            this.createUserHandler(event);
          }}
        >
          <div className="form-group">
            <label>First Name:</label>
            <input
              className="form-control"
              required
              onChange={(event) => this.setState({ firstname: event.target.value })}
              value={this.state.firstname || ""}
              placeholder="First Name"
            />
          </div>

          <div className="form-group">
            <label>Last Name:</label>
            <input
              className="form-control"
              required
              onChange={(event) => this.setState({ lastname: event.target.value })}
              value={this.state.lastname || ""}
              placeholder="Last Name"
            />
          </div>

          <div className="form-group">
            <label>Email:</label>
            <input
              className="form-control"
              required
              onChange={(event) => this.setState({ email: event.target.value })}
              value={this.state.email || ""}
              placeholder="Business Email"
            />
          </div>

          <div className="form-group">
            <label>Password:</label>
            <div className="input-group">
              <input className="form-control"
                required
                type={passwordType}
                value={this.state.password || ""}
                placeholder="Password"
                onChange={(event) => this.passwordValidate(event.target.value)}
              />
              <div className="input-group-append">
                <button type="button"
                  className={
                    passwordType === "password"
                      ? "btn btn-outline-secondary"
                      : "btn btn-outline-primary"
                  }
                  onClick={() => this.handlePasswordVisibility()}
                >
                  <span className="fa fa-eye"></span>
                </button>
              </div>
            </div>
            <div className="text-danger">{errors.password}</div>
          </div>

          <div className="form-group">
            <label>Confirm Password:</label>
            <input className="form-control"
              required
              onChange={(event) => this.confirmPasswordValidate(event.target.value)}
              value={this.state.confirm_password || ""}
              type={passwordType}
              placeholder="Confirm Password"
            />
            <div className="text-danger">{errors.confirm_password}</div>
          </div>

          <div className="alert alert-danger">
            {passwordValidationErrors.includes("min") === true ? (
              <small className="font-weight-bold">** Minimum length-8</small>
            ) : (
              <small className="text-success">✔ Minimum length-8</small>
            )}
            <br />
            {passwordValidationErrors.includes("uppercase") === true ? (
              <small className="font-weight-bold">** Uppercase-1</small>
            ) : (
              <small className="text-success">✔ Uppercase-1</small>
            )}
            <br />
            {passwordValidationErrors.includes("letters") === true ? (
              <small className="font-weight-bold">** Letter-1</small>
            ) : (
              <small className="text-success">✔ Letter-1</small>
            )}
            <br />
            {passwordValidationErrors.includes("digits") === true ? (
              <small className="font-weight-bold">** Number-1</small>
            ) : (
              <small className="text-success">✔ Number-1</small>
            )}
          </div>

          <hr />

          <div className="form-group">
            <div className="form-check">
              <label
                className="form-check-label"
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  checked={!this.state.hasCompany}
                  onChange={(event) => this.setState({ hasCompany: !event.target.checked })}
                />

                Sign up as a business professional, without a company.
              </label>
            </div>
          </div>

          {
            this.state.hasCompany &&
            <div className="form-group">
              <label>Company Name:</label>
              {this.state.hasCompany && (
                <div className="w-100">
                  <input
                    className="form-control"
                    required
                    onChange={(event) => this.setState({ companyName: event.target.value })}
                    value={this.state.companyName || ""}
                    placeholder="E.g MyTradeZone"
                  />
                </div>
              )}
            </div>
          }

          <div className="form-group">
            <label>Select up to 5 categories that match you or your business:</label>
            <select className="form-control"
              onChange={this.addCategory}>
              <option value="">== Business categories ==</option>
              {this.state.categories?.map((cat) => (
                <option key={cat.id} value={cat.id}>{cat.name}</option>
              ))}
            </select>
            {
              this.state.selectedCategories.length > 0 &&
              <>
                <h6 className="mt-2"><i><u><small>Selected categories:</small></u></i></h6>
                <ul>
                  {this.state.selectedCategories.map((category) => (
                    <li key={category.id} className="d-flex align-items-center">
                      {category.name} <button type="button" className="btn btn-link text-danger btn-sm"
                        onClick={() => this.removeCategory(category)}>
                        <i className="fas fa-times"></i>
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            }
          </div>

          <div className="alert alert-secondary text-center">
            <p className="txt-367">
              By clicking “Register”, you agree to our{" "}
              <a href="/terms" target="_blank" className="txt-3672">
                terms of service
              </a>{" "}
              and{" "}
              <a href="privacy" target="_blank" className="txt-3672">
                privacy policy
              </a>
            </p>
          </div>

          <button
            className="btn btn-primary"
            type='submit'
          >
            Register
          </button>
        </form>
      </div>
    );
  }
}

export default withRouter(RegisterForm);
