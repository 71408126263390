import React from 'react';

import { Redirect } from 'react-router-dom';
import AuthContext from '../../AuthContext';
import ParentLayout from '../ParentLayout';

function ParentAuthLayout(props) {
  const me = React.useContext(AuthContext);

  if (me && me.userId)
    return (
      <>
        <ParentLayout {...props} />
      </>
    );
  else
    return <Redirect to="/login" />;
}

export default ParentAuthLayout;
