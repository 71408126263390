import React from "react";
import { Route, BrowserRouter as Router, Switch, useHistory, useLocation, withRouter } from "react-router-dom";
import VideoCallPopup from './components/chat-service/calls/VideoCallPopup';
import withAuth from './hocWrappers/withAuth';
import mtzRoutes from "./mtz_routes";

import AuthContext from "./AuthContext";
import mtzApis from './services';
import localStorageUtil from './utils/localStorageUtil';

const { companyService } = mtzApis;

class MTZSiteMap extends React.Component {

  componentDidMount() {
    this.track();
  }

  track = async () => {
    const params = new URLSearchParams(window.location.search);
    const tcId = params.get("tc");

    if (tcId != null) {
      // // set to localStorage
      // let tcId = localStorageUtil.get(localStorageUtil.COMPANY_SERVICE, localStorageUtil.TRACKING_CODES, 'referal_group_tc');
      // // console.log(tcIds)
      // // if (!tcIds)
      // //   tcIds = [];
      // // tcIds.push(tcId);
      // // tcIds = [...new Set(tcIds)];
      // // localStorageUtil.set(localStorageUtil.COMPANY_SERVICE, localStorageUtil.TRACKING_CODES, 'referal_group_tc', tcId);
      // companyService.createTrackingCodeLog({ trackingCodeId: tcId, url: window.location.href });

      // set to localStorage
      let tcIds = localStorageUtil.get(localStorageUtil.COMPANY_SERVICE, localStorageUtil.TRACKING_CODES, 'tcs');
      if (!tcIds)
        tcIds = [];
      tcIds.push(tcId);
      tcIds = [...(new Set(tcIds))];
      localStorageUtil.set(localStorageUtil.COMPANY_SERVICE, localStorageUtil.TRACKING_CODES, 'tcs', tcIds);
      companyService.createTrackingCodeLog({ trackingCodeId: tcId, url: window.location.href });
    }
  }

  render() {
    return (
      <React.Suspense fallback={<p>Loading page...</p>}>
        <React.Fragment>
          <Router>
            <PageRefListenerWrapper />
            <Switch>
              {
                mtzRoutes && Object.keys(mtzRoutes).length > 0 &&
                Object.keys(mtzRoutes).map(path => {
                  let route = mtzRoutes[path];
                  let Component = route.component;
                  let MTZSuccessHandler = route.MTZSuccessHandler;
                  let MTZFailureHandler = route.MTZFailureHandler;
                  let Layout = route.layout;
                  return (
                    <Route exact key={JSON.stringify(path)} path={path}>
                      <React.Fragment>
                        <VideoCallPopup />
                        <CheckGlobalAdmin />
                        {
                          !Layout ?
                            <Component MTZSuccessHandler={MTZSuccessHandler} MTZFailureHandler={MTZFailureHandler} /> :
                            <Layout component={Component} />
                        }
                      </React.Fragment>
                    </Route>
                  );
                })
              }
            </Switch>
          </Router>
        </React.Fragment>
      </React.Suspense>
    );
  }
}

function PageRefListener({ history }) {
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    init();
  }, [history.location, me.sessionId]);

  const init = () => {
    let count = 10;
    let interval = setInterval(() => {
      if (count === 0) {
        clearInterval(interval);
        return;
      }

      const URL = window.location.href;
      if (URL.includes("##")) {
        const pattern = /##.+##/g;
        const contained = pattern.test(URL);
        if (contained) {
          const p1 = URL.indexOf("##") + 2;
          const p2 = URL.indexOf("##", p1 + 2);
          const id = URL.substring(p1, p2);
          const el = document.getElementById(id);
          if (!el) {
            count--;
            return;
          }

          const yOffset = -100;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
          el.style.border = "4px dashed red";
          el.style.boxShadow = "5px 5px 5px";
          el.onmouseover = () => {
            el.style.border = '';
            el.style.boxShadow = '';
          };

          clearInterval(interval);
        }
      }
    }, 1000)
  };

  return <></>
}

function CheckGlobalAdmin() {
  const me = React.useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();
  const currentPath = location.pathname;

  // React.useEffect(() => {

  // }, [location]);

  if (me && JSON.stringify(me) !== '{}') {
    if (me.role === 'ADMIN')
      if (!currentPath.startsWith("/mtz-admin") &&
        !currentPath.startsWith("/login") &&
        !currentPath.startsWith("/register") &&
        !currentPath.startsWith("/logout")) {
        alert('ADMIN can only access admin pages, and the login page');
        history.push('/mtz-admin');
        return null;
      }
  }
  return <></>;
}

const PageRefListenerWrapper = withRouter(PageRefListener);

export default withAuth(MTZSiteMap);