import React from "react";
import { NavLink } from "react-router-dom";
import AuthContext from "../../../AuthContext";
import mtzApis from "../../../services";
import "./style.css";

const { companyService } = mtzApis;

class MyCRMPage extends React.Component {
    constructor(props) {
        const params = new URLSearchParams(window.location.search);
        const welcome = params.get("welcome") || false;

        super(props);
        this.state = {
            myCompany: null,
            trackingCodeStats: null,
            notesStats: null,
            contactStats: null,
            contactGroupStats: null,
            formBuilderStats: null,
            emailLayoutsStats: null,
            emailCampaignStats: null,
            rfiStats: null
        }
    }

    componentDidMount() {
        this.init();
    }

    init = async () => {
        let me = this.props.me || {};

        let myCompany = await companyService.getMyCompany();
        if (myCompany) {
            let rfiStats = await companyService.getRfiStats(new URLSearchParams(`companyIds=${myCompany.id}`));
            this.setState({ myCompany })

            let trackingCodeStats = await companyService.getTrackingCodeStats(new URLSearchParams(`ownerIds=${me.userId}`));
            if (trackingCodeStats) {
                this.setState({ trackingCodeStats })
            }

            let notesStats = await companyService.getCompanyNoteStats(new URLSearchParams(`ownerIds=${me.userId}`));
            if (notesStats) {
                this.setState({ notesStats })
            }

            let contactStats = await companyService.getContactStats(new URLSearchParams(`ownerIds=${me.userId}`));
            if (contactStats) {
                this.setState({ contactStats })
            }

            let contactGroupStats = await companyService.getContactGroupStats(new URLSearchParams(``));
            if (contactGroupStats) {
                this.setState({ contactGroupStats })
            }

            let formBuilderStats = await companyService.getFormBuilderStats(new URLSearchParams(`ownerIds=${me.userId}`));
            if (formBuilderStats) {
                this.setState({ formBuilderStats })
            }

            let emailLayoutsStats = await companyService.getEmailSchemaLayoutStats(new URLSearchParams(``));
            if (emailLayoutsStats) {
                this.setState({ emailLayoutsStats })
            }

            let emailCampaignStats = await companyService.getEmailCampaignStats(new URLSearchParams(`ownerIds=${me.userId}`));
            if (emailCampaignStats) {
                this.setState({ emailCampaignStats })
            }

            if (rfiStats) {
                this.setState({ rfiStats })

            }
        }
    }

    render() {
        let { myCompany, trackingCodeStats, notesStats, contactStats,
            contactGroupStats, formBuilderStats, emailLayoutsStats,
            emailCampaignStats, rfiStats } = this.state;
        const params = new URLSearchParams(window.location.search);

        let avatarUrl = myCompany && myCompany.logoUrl;
        avatarUrl = avatarUrl &&
            (
                avatarUrl.startsWith("http") ?
                    avatarUrl :
                    companyService.getBackendHost() + avatarUrl
            );

        return (
            <div className="mtz-card">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="/me">Dashboard</a>
                    </li>

                    <li className="breadcrumb-item active">
                        CRM
                    </li>
                </ol>

                <div className="row d-flex mtz-p-8 align-items-stretch">
                    <div className="col-12 col-md-6 mtz-p-8">
                        <NavLink to="/me/crm/tracking-codes">
                            <div className="mtz-rounded-16 mtz-p-16 btn btn-outline-primary h-100 w-100">
                                <div className="mtz-h5">
                                    {trackingCodeStats && trackingCodeStats.all ? trackingCodeStats.all.count : 0}
                                </div>
                                <div>Tracking Codes</div>
                            </div>
                        </NavLink>
                    </div>

                    <div className="col-12 col-md-6 mtz-p-8">
                        <NavLink to="/me/crm/company-notes">
                            <div className="mtz-rounded-16 mtz-p-16 btn btn-outline-primary h-100 w-100">
                                <div className="mtz-h5">
                                    {notesStats && notesStats.all ? notesStats.all.count : 0}
                                </div>
                                <div>Notes</div>
                            </div>
                        </NavLink>
                    </div>

                    <div className="col-12 col-md-6 mtz-p-8">
                        <NavLink to="/me/crm/contacts">
                            <div className="mtz-rounded-16 mtz-p-16 btn btn-outline-primary h-100 w-100">
                                <div className="mtz-h5">
                                    {contactStats && contactStats.all ? contactStats.all.count : 0}
                                </div>
                                <div>Contacts</div>
                            </div>
                        </NavLink>
                    </div>

                    <div className="col-12 col-md-6 mtz-p-8">
                        <NavLink to="/me/crm/contact-groups">
                            <div className="mtz-rounded-16 mtz-p-16 btn btn-outline-primary h-100 w-100">
                                <div className="mtz-h5">
                                    {contactGroupStats && contactGroupStats.all ? contactGroupStats.all.count : 0}
                                </div>
                                <div>Contact Groups</div>
                            </div>
                        </NavLink>
                    </div>

                    <div className="col-12 col-md-6 mtz-p-8">
                        <NavLink to="/me/crm/form-builders">
                            <div className="mtz-rounded-16 mtz-p-16 btn btn-outline-primary h-100 w-100">
                                <div className="mtz-h5">
                                    {formBuilderStats && formBuilderStats.all ? formBuilderStats.all.count : 0}
                                </div>
                                <div>Form Builders</div>
                            </div>
                        </NavLink>
                    </div>

                    <div className="col-12 col-md-6 mtz-p-8">
                        <NavLink to="/me/crm/email-layouts">
                            <div className="mtz-rounded-16 mtz-p-16 btn btn-outline-primary h-100 w-100">
                                <div className="mtz-h5">
                                    {emailLayoutsStats && emailLayoutsStats.all ? emailLayoutsStats.all.count : 0}
                                </div>
                                <div>Email Layouts</div>
                            </div>
                        </NavLink>
                    </div>

                    <div className="col-12 col-md-6 mtz-p-8">
                        <NavLink to="/me/crm/email-campaigns">
                            <div className="mtz-rounded-16 mtz-p-16 btn btn-outline-primary h-100 w-100">
                                <div className="mtz-h5">
                                    {emailCampaignStats && emailCampaignStats.all ? emailCampaignStats.all.count : 0}
                                </div>
                                <div>Email Campaigns</div>
                            </div>
                        </NavLink>
                    </div>

                    <div className="col-12 col-md-6 mtz-p-8">
                        <NavLink to="/me/crm/rfis">
                            <div className="mtz-rounded-16 mtz-p-16 btn btn-outline-primary h-100 w-100">
                                <div className="mtz-h5">
                                    {rfiStats && rfiStats.all ? rfiStats.all.count : 0}
                                </div>
                                <div>RFIs</div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }
}

export default props => (
    <AuthContext.Consumer>
        {
            value => (
                <MyCRMPage {...props} me={value} />
            )
        }
    </AuthContext.Consumer>
)
