import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createContactUploadEvent = contactUploadEvent => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/upload-events`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, contactUploadEvent, headers);
}

export const deleteContactUploadEventById = contactUploadEventId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/upload-events/${contactUploadEventId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getContactUploadEventById = contactUploadEventId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/upload-events/${contactUploadEventId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getContactUploadEvents = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/upload-events?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getContactUploadEventStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/upload-events/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const updateContactUploadEventById = (contactUploadEventId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/upload-events/${contactUploadEventId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

export const getContactUploadEventTemplate = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/contacts/upload-events/template`;
  let headers = {};

  return commonUtil.httpclient(method, url, null, headers);
}