import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGEventSponsorPlanPrice = (cgEventSponsorPlanPrice) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plan-prices`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, cgEventSponsorPlanPrice, headers);
};

export const deleteCGEventSponsorPlanPriceById = (cgEventSponsorPlanPriceId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plan-prices/${cgEventSponsorPlanPriceId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCGEventSponsorPlanPriceById = (cgEventSponsorPlanPriceId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plan-prices/${cgEventSponsorPlanPriceId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export const getCGEventSponsorPlanPriceById = (cgEventSponsorPlanPriceId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plan-prices/${cgEventSponsorPlanPriceId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventSponsorPlanPrices = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plan-prices?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventSponsorPlanPriceStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plan-prices/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};