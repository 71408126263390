import React, { useEffect } from 'react';
import googleOneTap from 'google-one-tap';
import $ from 'jquery';
import { NavLink, withRouter } from 'react-router-dom';
import HomeSearchBoxNew from '../../components/shared2/HomeSearchBoxNew';
import mtzApis from "../../services";
import { GOOGLE_CLIENT_ID } from '../../services/config';
import './NonLoginHeader.css';

const { companyService, commonUtil } = mtzApis;

function NonLoginHeader({ asSimple }) {

    useEffect(() => {
        $('.dropdown-toggle').click(function (e) {
            e.stopPropagation();
            let $button = $(this);
            let $dropdownMenu = $button.next('.dropdown-menu');
            // Hide any open dropdown menus
            $('.dropdown-menu').not($dropdownMenu).hide();

            // Toggle current dropdown
            $dropdownMenu.toggle();
        });

        // Hide dropdown when clicking outside
        $(document).click(function () {
            $('.dropdown-menu').hide();
        });
    }, []);

    const renderMenuItemsXl = () => (
        <div className="w-100 d-flex mtz-gap-16 align-items-center">
            {!asSimple ? (
                <div className="d-flex mtz-gap-16">
                    {window.location.pathname.startsWith('/search') ? (
                        <div className="d-none d-xl-inline-block flex-fill">
                            <HomeSearchBoxNew />
                        </div>
                    ) : (
                        <>
                            {/* Products Dropdown */}
                            <div className="dropdown d-inline-block">
                                <button
                                    className="btn dropdown-toggle mx-2 font-weight-bold mtz-h6 text-nowrap"
                                    id="productsDropdown"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Products
                                </button>
                                <div className="dropdown-menu zoom-dropdown shadow-lg" aria-labelledby="productsDropdown">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <h5 className="dropdown-title"><strong>Business Networking</strong></h5>
                                                <p> <a href='#'>Virtual Trade Booth</a></p>
                                                <p><a href="#">Business Networking Groups Manager</a></p>
                                                <p><a href="#">Event Manager</a></p>
                                                <p><a href="#">AI business matchmaking</a></p>
                                                <p><a href="#">Text, Video and Group chat</a></p>
                                                <p><a href="#">Follow and Connect</a></p>
                                                <p><a href="#">Company and Group discussions</a></p>
                                                <p><a href="#">Text, video and group chat</a></p>
                                                <p><a href="#">B2B Search</a></p>
                                                <p><a href="#">Share a wishlist with a connection</a></p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="dropdown-title"><strong>Advertisement</strong></h5>
                                                <p><a href="#">Organic listing</a></p>
                                                <p><a href="#">Pay-per-Click Listing</a></p>
                                                <p><a href="#">Banner display</a></p>
                                                <p><a href="#">Group and events sponsorship</a></p>
                                                <p><a href="#">Ad template builder</a></p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="dropdown-title"><strong>Business Productivity</strong></h5>
                                                <p><a href="#">CRM, invite and categorize contacts</a></p>
                                                <p><a href="#">Note-Taking</a></p>
                                                <p><a href="#">Form & survey builder</a></p>
                                                <p><a href="#">Email campaign marketing manager</a></p>
                                                <p><a href="#">Email template builder</a></p>
                                                <p><a href="#">File manager</a></p>
                                                <p><a href="#">Tracking and QR Code generator</a></p>
                                                <p><a href="#">Point program and coupons</a></p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="dropdown-title"><strong>Quote Requests & Bidding</strong></h5>
                                                <p><a href="#">Add your products and services</a></p>
                                                <p><a href="#">Post quote requests (private or public)</a></p>
                                                <p><a href="#">Vendors bid on quote request (private or public)</a></p>
                                                <p><a href="#">Receive automatic alert</a></p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="dropdown-title"><strong>Monetization</strong></h5>
                                                <p><a href="#">membership / Subscription</a></p>
                                                <p><a href="#">Event ticket Sale</a></p>
                                                <p><a href="#">Donation tools</a></p>
                                                <p><a href="#">Sponsorship sale</a></p>
                                                <p><a href="#">Wallet tools</a></p>
                                                <p><a href="#">Coupon code tools</a></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Solutions Dropdown */}
                            <div className="dropdown d-inline-block">
                                <button
                                    className="btn dropdown-toggle mx-2 font-weight-bold mtz-h6 text-nowrap"
                                    id="solutionsDropdown"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Solutions
                                </button>
                                <div className="dropdown-menu zoom-dropdown shadow-lg" aria-labelledby="solutionsDropdown">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <h5 className="dropdown-title"><strong>By Audience</strong></h5>
                                                <p><a href="#">Networking groups organizers</a></p>
                                                <p><a href="#">Event organizers</a></p>
                                                <p><a href="#">Trade show organizers</a></p>
                                                <p><a href="#">Chamber of Commerce organizers</a></p>
                                                <p><a href="#">Non-profit group organizers</a></p>
                                                <p><a href="#">Trade association organizers</a></p>
                                                <p><a href="#">Industry associations organizers</a></p>
                                                <p><a href="#">Government trade mission networking organizers</a></p>
                                                <p><a href="#">Small and mid-sized businesses</a></p>
                                                <p><a href="#">Enterprises</a></p>
                                                <p><a href="#">Any product and service companies</a></p>
                                                <p><a href="#">Content creators and influencers</a></p>
                                                <p><a href="#">Donation group organizers</a></p>
                                                <p><a href="#">Marketing and sales leaders</a></p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="dropdown-title"><strong>By Industry</strong></h5>
                                                <p><a href="#">Wholesale distribution</a></p>
                                                <p><a href="#">Manufacturing</a></p>
                                                <p><a href="#">Vendors and suppliers</a></p>
                                                <p><a href="#">Importers and exporters</a></p>
                                                <p><a href="#">Government</a></p>
                                                <p><a href="#">Service / solution providers</a></p>
                                                <p><a href="#">Software</a></p>
                                                <p><a href="#">B2B procurement</a></p>
                                                <p><a href="#">Healthcare</a></p>
                                                <p><a href="#">Retail</a></p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="dropdown-title"><strong>For Creators and Influencers</strong></h5>
                                                <p><a href="#">Creators</a></p>
                                                <p><a href="#">Influencers</a></p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="dropdown-title"><strong>For Partners</strong></h5>
                                                <p><a href="#">coming soon</a></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Resources Dropdown */}
                            <div className="dropdown d-inline-block">
                                <button
                                    className="btn dropdown-toggle mx-2 font-weight-bold mtz-h6 text-nowrap"
                                    id="resourcesDropdown"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Resources
                                </button>
                                <div className="dropdown-menu zoom-dropdown shadow-lg" aria-labelledby="resourcesDropdown">
                                    <h5 className="dropdown-title"><strong>Explore</strong></h5>
                                    <a className="dropdown-item" href="/faqs">FAQs</a>
                                    <a className="dropdown-item" href="#">Customer support</a>
                                    <a className="dropdown-item" href="#">Training (coming soon)</a>
                                    <a className="dropdown-item" href="#">MyTradeZone discussion forum</a>
                                    <a className="dropdown-item" href="#">MyTradeZone event calendar</a>
                                </div>
                            </div>

                            <NavLink className="mx-2 btn font-weight-bold mtz-h6 text-nowrap" to="/pricing">
                                Plans and Pricing
                            </NavLink>
                        </>
                    )}
                </div>
            ) : (
                <div className="w-100 flex-fill"></div>
            )}

            <div className="d-flex mtz-gap-4 justify-content-center align-items-center">
                <NavLink className="btn btn-primary btn font-weight-bold mtz-h6 text-nowrap" to="/login">
                    Sign In
                </NavLink>
                <NavLink className="btn btn-outline-primary font-weight-bold mtz-h6 text-nowrap" to="/register">
                    Join Free
                </NavLink>
            </div>
        </div>
    );

    const renderMenuItemsXs = () => (
        <>
            <div className="d-flex mtz-gap-4">
                <div className="flex-fill">
                    {window.location.pathname.startsWith('/search') && !asSimple ? <HomeSearchBoxNew /> : ''}
                </div>
                <button data-toggle="collapse" data-target="#collapse-top-nav" className="btn btn-sm">
                    <span className="fa fa-bars"></span>
                </button>
            </div>
        </>
    );

    return (
        <div className="header-container bg-white border justify-content-center d-flex flex-column shadow-sm py-1">
            <div className="w-100 d-flex mtz-gap-4 justify-content-center align-items-center">
                <div className="mx-2">
                    <NavLink to="/">
                        <img src="/assets/images/Logos.png" height="40" alt="Logo" />
                    </NavLink>
                </div>
                <div style={{ width: '850px' }} className="d-flex flex-column d-xl-none">
                    {renderMenuItemsXs()}
                </div>
                <div style={{ width: '850px' }} className="d-xl-flex d-none align-items-center">
                    {renderMenuItemsXl()}
                </div>
            </div>

            <div className='collapse ml-4 mt-4 mb-2' id='collapse-top-nav'>
                <div className='d-flex flex-column mtz-gap-8 align-items-center'>
                    <NavLink to='/search'>Search</NavLink>

                    {/* Products Section for Mobile */}
                    <button
                        className="btn font-weight-bold mtz-h6 text-nowrap"
                        data-toggle="collapse"
                        data-target="#productsCollapse"
                        aria-expanded="false"
                        aria-controls="productsCollapse"
                    >
                        Products
                    </button>
                    <div className="collapse w-100 px-3" id="productsCollapse">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <h6 className="dropdown-title">Business Networking</h6>
                                    <p className="m-0">Business Virtual Trade Booth</p>
                                    <p className="m-0">Creating Networking Groups</p>
                                    <p className="m-0">Creating Events</p>
                                    <p className="m-0">Monetization and Wallet tools</p>
                                    <p className="m-0">Subscription/membership billing</p>
                                    <p className="m-0">Donations and event ticket sale</p>
                                    <p className="m-0">AI business Match making</p>
                                    <p className="m-0">Text, video and group chat</p>
                                    <p className="m-0">Follow and Connect</p>
                                    <p className="m-0">Company and Group discussion posts (Forums)</p>
                                    <p className="m-0">Search, companies, products, Groups, Events and RFQs</p>
                                    <p className="m-0">Advanced metrics for lead generation</p>
                                    <p className="m-0">Share a wishlist with a connection</p>
                                    <p className="m-0">B2B search (Companies, products, RFQs, Groups, and events)</p>
                                </div>
                                <div className="col-12 col-md-3">
                                    <h6 className="dropdown-title">Advertisement</h6>
                                    <p className="m-0">Organic listing</p>
                                    <p className="m-0">Pay-per-Click Listing</p>
                                    <p className="m-0">Banner display</p>
                                    <p className="m-0">Group and events sponsorship</p>
                                    <p className="m-0">Ad template builder</p>
                                </div>
                                <div className="col-12 col-md-3">
                                    <h6 className="dropdown-title">Business Productivity</h6>
                                    <p className="m-0">CRM, invite and categorize contacts</p>
                                    <p className="m-0">Notes Taking</p>
                                    <p className="m-0">Forms & Surveys Builder</p>
                                    <p className="m-0">Email Campaign Marketing Manager</p>
                                    <p className="m-0">Email Template builder</p>
                                    <p className="m-0">File Manager</p>
                                    <p className="m-0">Tracking and QR Code generator</p>
                                    <p className="m-0">Point program and coupons</p>
                                </div>
                                <div className="col-12 col-md-3">
                                    <h6 className="dropdown-title">Quote Requests & bidding</h6>
                                    <p className="m-0">Add your products and services</p>
                                    <p className="m-0">post quote requests (private or public)</p>
                                    <p className="m-0">Vendors bid on quote request (private or public)</p>
                                    <p className="m-0">Receive automatic alert</p>
                                </div>
                                <div className="col-12 col-md-3">
                                    <h6 className="dropdown-title">Quote Requests & bidding</h6>
                                    <p className="m-0">Add your products and services</p>
                                    <p className="m-0">post quote requests (private or public)</p>
                                    <p className="m-0">Vendors bid on quote request (private or public)</p>
                                    <p className="m-0">Receive automatic alert</p>
                                </div>
                                <div className="col-12 col-md-3">
                                    <h5 className="dropdown-title">Monetization</h5>
                                    <p className="m-0">membership / Subscription</p>
                                    <p className="m-0">Event ticket Sale</p>
                                    <p className="m-0">Donation tools</p>
                                    <p className="m-0">Sponsorship sale</p>
                                    <p className="m-0">Wallet tools</p>
                                    <p className="m-0">Coupon code tools</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Solutions Section for Mobile */}
                    <button
                        className="btn font-weight-bold mtz-h6 text-nowrap"
                        data-toggle="collapse"
                        data-target="#solutionsCollapse"
                        aria-expanded="false"
                        aria-controls="solutionsCollapse"
                    >
                        Solutions
                    </button>
                    <div className="collapse w-100 px-3" id="solutionsCollapse">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <h6 className="dropdown-title">By Audience</h6>
                                    <p className="m-0">Networking groups organizers</p>
                                    <p className="m-0">Event organizers</p>
                                    <p className="m-0">Trade show organizers</p>
                                    <p className="m-0">Chamber of Commerce organizers</p>
                                    <p className="m-0">Non-profit group organizers</p>
                                    <p className="m-0">Trade associations organizers</p>
                                    <p className="m-0">industry associations organizers</p>
                                    <p className="m-0">Government trade mission networking organizers</p>
                                    <p className="m-0">Small and Mid sized businesses</p>
                                    <p className="m-0">Enterprises</p>
                                    <p className="m-0">Any Products and Service companies</p>
                                    <p className="m-0">Content creators and Influencers</p>
                                    <p className="m-0">Donations group organizers</p>
                                    <p className="m-0">Marketing and sales leaders</p>
                                </div>
                                <div className="col-12 col-md-3">
                                    <h6 className="dropdown-title">By Industry</h6>
                                    <p className="m-0">Wholesale distribution</p>
                                    <p className="m-0">Manufacturing</p>
                                    <p className="m-0">Vendors and Suppliers</p>
                                    <p className="m-0">Importers and exporters</p>
                                    <p className="m-0">Government</p>
                                    <p className="m-0">Service / Solution providers</p>
                                    <p className="m-0">Software</p>
                                    <p className="m-0">B2B procurement</p>
                                    <p className="m-0">Healthcare</p>
                                    <p className="m-0">Retail</p>
                                </div>
                                <div className="col-12 col-md-3">
                                    <h6 className="dropdown-title">For Creators and Influencers</h6>
                                    <p className="m-0">Creators</p>
                                    <p className="m-0">Influencers</p>
                                </div>
                                <div className="col-12 col-md-3">
                                    <h6 className="dropdown-title">For Partners</h6>
                                    <p className="m-0">coming soon</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Resources Section for Mobile */}
                    <button
                        className="btn font-weight-bold mtz-h6 text-nowrap"
                        data-toggle="collapse"
                        data-target="#resourcesCollapse"
                        aria-expanded="false"
                        aria-controls="resourcesCollapse"
                    >
                        Resources
                    </button>
                    <div className="collapse w-100 px-3" id="resourcesCollapse">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <a className="dropdown-item" href="/faqs">FAQs</a>
                                    <a className="dropdown-item" href="#">Customer support</a>
                                    <a className="dropdown-item" href="#">Training (coming soon)</a>
                                    <a className="dropdown-item" href="#">MyTradeZone discussion forum</a>
                                    <a className="dropdown-item" href="#">MyTradeZone event calendar</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <NavLink to='/pricing'>Pricing</NavLink>
                    <NavLink to='/contact'>Contact Us</NavLink>

                    <div>
                        <NavLink className='btn btn-outline-primary btn-sm' to='/login'>
                            Sign In
                        </NavLink>
                        &nbsp;
                        <NavLink className='btn btn-primary btn-sm' to='/register'>
                            Join Free
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

const options = {
    client_id: GOOGLE_CLIENT_ID,
    auto_select: false,
    cancel_on_tap_outside: false,
    context: 'signin',
};

googleOneTap(options, (response) => {
    console.log(response);
});

export default withRouter(NonLoginHeader);
