import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getProducts = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/products?${decodeURIComponent(queries)}`;
  let params = {};
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

const getProductById = productId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/products/${productId}`;
  let params = {};
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, params, headers);
};

const getProductStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/products/stats?${decodeURIComponent(queries)}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
};

const createProduct = productFormData => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/products`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, productFormData, headers);
}

const updateProductById = (id, product) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/products/` + id;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, product, headers);
};

const deleteProductById = (productId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/products/` + productId;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, productId, headers);
};

const deleteProducts = queries => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/products?${decodeURIComponent(queries)}`;
  let params = {};
  let headers = {
    "Content-Type": "application/json",
  };

  return commonUtil.httpclient(method, url, params, headers);
};

const uploadProductImagesById = (productId, base64Imgs) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/products/${productId}/images`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, { base64Imgs }, headers);
};

export {
  createProduct,
  deleteProductById,
  deleteProducts, getProductById, getProducts, getProductStats,
  updateProductById,
  uploadProductImagesById
};
