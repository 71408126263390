import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getPosts = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/posts?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const getPostStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/posts/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getPostById = (id) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/posts/${id}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};

export const createPost = (data) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/posts`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, data, headers);
};

export const updatePostById = (id, data) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/posts/${id}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, data, headers);
}

export const deletePostById = (id) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/posts/${id}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, null, headers);
};