import commonUtil from "../common";
import {
  PAYMENT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const getMtzCustomers = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-customers?${queries}`;
  let params = { spinnerOn: true };
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

const createMtzCustomer = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-customers`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

const updateMtzCustomerById = (id, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-customers/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

const getMyMtzCustomer = () => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-customer`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

export {
  createMtzCustomer, getMtzCustomers, getMyMtzCustomer, updateMtzCustomerById
};
