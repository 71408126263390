import commonUtil from "../common";
import {
  PAYMENT_SERVER_URL,
  SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const getCards = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cards?${queries}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const createCard = params => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cards`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

const deleteCardById = id => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cards/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

const updateCardById = (id, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cards/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

const getCardById = id => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cards/${id}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, null, headers);
}

export {
  createCard,
  deleteCardById, getCardById, getCards, updateCardById
};
