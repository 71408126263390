import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGDonationPayment = (payment) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-donation-payments`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, payment, headers);
};

export const deleteCGDonationPaymentById = (paymentId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-donation-payments/${paymentId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGDonationPaymentById = (paymentId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-donation-payments/${paymentId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGDonationPayments = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-donation-payments?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGDonationPaymentStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-donation-payments/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};