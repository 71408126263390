import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthContext from '../../../../AuthContext';
import withAuth from '../../../../hocWrappers/withAuth';
import mtzApis from '../../../../services';
import MessageManager from "../../messages/MessageManager";
import RoomManager from '../../rooms/RoomManager';

const { chatService } = mtzApis;

function InstantChatManager({ newRoomEvent, newMessageEvent, clearRoomEvent }) {
    const [active, setActive] = React.useState(null);
    const [unreadStats, setUnreadStats] = React.useState();
    const [openChat, setOpenChat] = React.useState(false);
    const collapseOpenInstantChatRef = React.useRef();
    const me = React.useContext(AuthContext);

    React.useEffect(() => {
        if (!me || !me.sessionId) return;
        init();
    }, [me.sessionId]);

    const init = async () => {
        const stats = await chatService.getMessageStats(
            new URLSearchParams(`notSeenBy=${me.userId}`)
        );
        if (stats) setUnreadStats(stats);
    };

    // Process room events.
    React.useEffect(() => {
        if (newRoomEvent && newRoomEvent.action === 'VIEWED' && newRoomEvent.resource) {
            setActive(newRoomEvent.resource);
            if (!openChat && collapseOpenInstantChatRef.current) {
                collapseOpenInstantChatRef.current.click();
            }
            // Reuse the VIEWED event convention by sending a VIEWED event with null resource.
            clearRoomEvent();
        }
    }, [newRoomEvent, openChat, clearRoomEvent]);

    // Process message events.
    React.useEffect(() => {
        if (!newMessageEvent || !newMessageEvent.resource) return;
        const msg = newMessageEvent.resource;

        if (newMessageEvent.action === 'DELETED') {
            if (!msg.viewerIds.includes(me.userId)) return;
            setUnreadStats(prev => ({
                all: { count: prev.all.count <= 0 ? 0 : prev.all.count - 1 }
            }));
        }

        if (newMessageEvent.action === 'CREATED') {
            if (msg.viewerIds.includes(me.userId)) return;
            setUnreadStats(prev => ({
                all: { count: prev.all.count < 100 ? prev.all.count + 1 : prev.all.count }
            }));
        }

        if (newMessageEvent.action === 'UPDATED') {
            (async () => {
                const stats = await chatService.getMessageStats(
                    new URLSearchParams(`notSeenBy=${me.userId}&limit=100`)
                );
                setUnreadStats(stats);
            })();
        }
    }, [newMessageEvent]);

    return (
        <div className="d-flex flex-column" style={{ width: '600px' }}>
            {!!me && !!me.userId && !!me.sessionId && (
                <>
                    <div className="d-flex align-items-center">
                        {openChat && (
                            <button
                                onClick={() => {
                                    setTimeout(() => {
                                        setOpenChat(!openChat);
                                    }, 0);
                                }}
                                className="btn btn-secondary btn-sm"
                                data-toggle="collapse"
                                data-target="#collapse-instant-chat"
                            >
                                <span className="fa fa-times"></span>
                            </button>
                        )}

                        <span className="flex-fill"></span>

                        {unreadStats && unreadStats.all && unreadStats.all.count > 0 && (
                            <span>
                                <b
                                    style={{ position: 'relative', top: '-23px', right: '-5px' }}
                                    className="btn btn-danger badge mtz-b3 text-white"
                                >
                                    {unreadStats.all.count < 100 ? unreadStats.all.count : '99+'}
                                </b>
                            </span>
                        )}

                        <div
                            ref={collapseOpenInstantChatRef}
                            onClick={() => setOpenChat(!openChat)}
                            data-toggle="collapse"
                            data-target="#collapse-instant-chat"
                            className="rounded-top border border-dark px-4 py-2 bg-primary text-white mtz-gap-8 d-flex align-items-center mtz-cursor-pointer"
                        >
                            Messages <span className="fa fa-message"></span>
                        </div>

                        <span data-toggle="tooltip" title="Open large chat">
                            <NavLink to="/chat" className="ml-auto">
                                <button className="btn">
                                    <span className="fa fa-external-link"></span>
                                </button>
                            </NavLink>
                        </span>
                    </div>

                    <div
                        id="collapse-instant-chat"
                        className="collapse p-1 mtz-rounded-8 border border-dark bg-white"
                    >
                        {!unreadStats ? (
                            'Chat service not available'
                        ) : active ? (
                            <>
                                <button
                                    onClick={() => setActive(null)}
                                    className="btn btn-link btn-sm font-weight-bold"
                                >
                                    <i className="fa fa-arrow-left"></i> Back to rooms
                                </button>
                                <div className="bg-light h-100 vh-75">
                                    <MessageManager room={active} />
                                </div>
                            </>
                        ) : (
                            <div className="h-100 vh-75">
                                <RoomManager room={active} onChange={updated => setActive(updated)} />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

const mapStateToProps = state => ({
    newMessageEvent: state.message_topic.new_uievent,
    newRoomEvent: state.room_topic.new_uievent,
});

// Instead of using a new event type, we reuse the VIEWED event by sending a VIEWED event with null resource.
const mapDispatchToProps = dispatch => ({
    clearRoomEvent: () =>
        dispatch({ type: 'ROOM_PUBLISH', event: { action: 'VIEWED', resource: null } }),
});

const InstantChatManagerWrapper = connect(
    mapStateToProps,
    mapDispatchToProps
)(InstantChatManager);

export default withAuth(InstantChatManagerWrapper);
