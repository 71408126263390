import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { roomEventPublish } from "../../../../redux/actions/roomEventActions.js";

import AuthContext from "../../../../AuthContext.js";
import mtzApis from "../../../../services";
import "./style.css";

const { chatService } = mtzApis;

function ChatButton2({ targetUserId, publishRoomEvent, className, title, history }) {
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    subscribeEvents();
  }, [className, targetUserId]);

  const subscribeEvents = async () => { };

  const startMessaging = async () => {
    if (!me || Object.keys(me).length === 0 || !me.userId) {
      const ok = await window.createMtzConfirm("Please login to chat");
      if (!ok) return;
      else {
        window.location.href = "/login?redirectUrl=" + window.location.pathname;
        return;
      }
    }

    let dRooms = await chatService.getRooms(new URLSearchParams(`meta.type=DIRECTROOM&memberIds=${targetUserId}`));
    let room = dRooms[0];
    if (!room)
      room = await chatService.createDirectRoom({ toMemberId: targetUserId });
    if (room) {
      if (window.innerWidth < 992) {
        publishRoomEvent({ resource: room, action: "VIEWED" });
        // For small and medium screens, redirect to chat/room.id
        history.push(`/chat/${room.id}`);
      } else {
        // For large screens, use the current event publish mechanism
        publishRoomEvent({ resource: room, action: "VIEWED" });
      }
    }
  };

  if (me.userId === targetUserId) return <></>;

  return (
    <button className={className} onClick={startMessaging}>
      {title || "Message"}
    </button>
  );
}

const dispatchToProps = (dispatch) => ({
  publishRoomEvent: (event) => dispatch(roomEventPublish(event)),
});

const ChatButtonWrapper = connect(null, dispatchToProps)(withRouter(ChatButton2));

export default ChatButtonWrapper;
