import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createFormBuilder = formBuilder => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builders`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, formBuilder, headers);
}

export const deleteFormBuilderById = formBuilderId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builders/${formBuilderId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getFormBuilderById = formBuilderId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builders/${formBuilderId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getFormBuilders = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builders?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export const getFormBuilderStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builders/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}


export const updateFormBuilderById = (formBuilderId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builders/${formBuilderId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

export const uploadFormBuilderBannerById = (formBuilderId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/form-builders/${formBuilderId}/_uploadBanner`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
}

