import commonUtil from "../common";
import {
    SERVER_API_ALL,
    COMPANY_SERVER_URL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCaptchaCheck = captchaReq => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/captcha-check`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, captchaReq, headers);
};
