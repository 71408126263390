// auths structure: [{accessToken, refreshToken, email}, ...] - 1st is the current login

import localStorageUtil from "../utils/localStorageUtil";

export const setLogin = (email, tokens) => {
    let auths = localStorageUtil.get(localStorageUtil.COMPANY_SERVICE, "users", "auths") || [];
    auths = auths.filter(auth => auth.email !== email);
    auths.unshift({ email, ...tokens });
    localStorageUtil.set(localStorageUtil.COMPANY_SERVICE, "users", "auths", auths);
    return { email, ...tokens };
};

export const getLogins = () => {
    let auths = localStorageUtil.get(localStorageUtil.COMPANY_SERVICE, "users", "auths") || [];
    return auths;
};

export const logoutAll = redirectUrl => {
    localStorage.clear();
    window.location.href = "/login?redirectUrl=/me?justLoggedIn=true";
}

export const removeLogin = email => {
    let auths = localStorageUtil.get(localStorageUtil.COMPANY_SERVICE, "users", "auths") || [];
    auths = auths.filter(auth => auth.email !== email);
    localStorageUtil.set(localStorageUtil.COMPANY_SERVICE, "users", "auths", auths);
    return auths;
}

export const switchLogin = email => {
    let auths = getLogins();
    if (!auths) return;

    let switchingToken = auths.find(auth => auth.email === email);
    if (!switchingToken) return;

    auths = auths.filter(token => token.email !== email);
    auths.unshift(switchingToken);

    localStorageUtil.set(localStorageUtil.COMPANY_SERVICE, "users", "auths", auths);
    return switchingToken;
};

export const removeCurrentLogin = () => {
    let auths = getLogins();
    if (!auths) return;

    auths.shift();
    localStorageUtil.set(localStorageUtil.COMPANY_SERVICE, "users", "auths", auths);
};

export const updateLogin = (email, tokens) => {
    let auths = getLogins();
    if (!auths) return;

    let updatingToken = auths.find(auth => auth.email === email);
    if (!updatingToken) return;

    auths = auths.filter(auth => auth.email !== email);
    auths.unshift({ email, ...tokens });
    localStorageUtil.set(localStorageUtil.COMPANY_SERVICE, "users", "auths", auths);
    return { email, ...tokens };
}