import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeSearchBoxNew from "../../components/shared2/HomeSearchBoxNew";
import MainMenu from '../UserDashboardLayout/MainMenu';
import './Header.css';

import AuthContext from '../../AuthContext';
import NotificationButton from '../../components/metric-service/notifications2/NotificationButton';
import MyProfileButton from '../../components/user-service/users2/MyProfileButton';
import mtzApis from '../../services';

const { companyService, paymentService, chatService } = mtzApis;

export default function Header({ asSimple }) {
  const me = React.useContext(AuthContext);
  let [company, setCompany] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      if (!me) return;

      company = await companyService.getMyCompany();
      if (company) setCompany(company);
    })();
  }, []);

  return (
    <div className='bg-white border justify-content-center d-flex flex-column shadow-sm'>
      <div className="w-100 d-flex mtz-gap-4 justify-content-center align-items-center">
        <div className='mx-2'>
          <NavLink to="/">
            <img src='/assets/images/Logos.png' height='40' />
          </NavLink>
        </div>

        {
          asSimple ?
            <div className='searchbox'></div> :
            <div className={'searchbox text-center align-items-center mtz-gap-4'}>
              <div className='d-none d-xl-flex'>
                {
                  me.role !== 'ADMIN' ?
                    <>
                      <HomeSearchBoxNew />
                      &nbsp;&nbsp;
                      <span className='d-md-inline-block d-none'><PlanButton /></span>
                    </> :
                    <div className='text-primary mtz-h5'>Admin Portal</div>
                }
              </div>
            </div>
        }

        <div className={'d-flex right-buttons justify-content-end'}>
          <div className='d-flex mtz-gap-4 align-items-bottom justify-content-center'>
            {
              me.role !== 'ADMIN' &&
              <>
                <div className='d-none d-xl-flex align-items-center'>
                  <NavLink to='/welcome' className='btn btn-sm'>
                    <div className="align-items-center text-dark  d-flex flex-column">
                      <i className='fa fa-home'></i>
                      <small className="text-nowrap">Home</small>
                    </div>
                  </NavLink>
                </div>

                <div className='d-none d-xl-flex align-items-center'><NotificationButton color="#555" /></div>

                <div className='d-flex align-items-center'>
                  <button className='btn btn-sm' data-toggle='dropdown'>
                    <div className="align-items-center d-flex flex-column">
                      <i className='fa fa-bars'></i>
                      <small className="text-nowrap">Menu</small>
                    </div>
                  </button>

                  <div className='shadow mtz-rounded-16 dropdown-menu vh-75 overflow-auto'>
                    <MainMenu />
                  </div>
                </div>
              </>
            }

            <div className='py-1 align-items-center d-flex'><MyProfileButton /></div>
          </div>
        </div>
      </div>

      {
        !asSimple &&
        <div className='d-flex d-xl-none'>
          <div className='w-100 mx-2 pb-1'>
            <HomeSearchBoxNew />
          </div>
        </div>
      }
    </div >
  );
}

export function PlanButton() {
  const me = React.useContext(AuthContext);
  let [mySub, setMySub] = React.useState();

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let subs = await paymentService.getMtzPlanSubscriptions(new URLSearchParams(``));
    if (subs && subs.length > 0) {
      subs[0].plan = await paymentService.getMtzPlanById(subs[0].mtzPlanId);
      setMySub(subs[0]);
    }
  };

  if (!me)
    return <></>;

  return (
    <span className=''>
      {
        !mySub || !mySub.plan ?
          <NavLink to={`/pricing`}
            className='text-primary'><i><b><small><u>Try premium</u></small></b></i>
          </NavLink> :
          (
            <NavLink className='text-dark' to={`/me/billing/subscriptions/my-mtz-subscription`}>
              <i><b><small className='d-flex flex-column'>
                <span className='text-nowrap'>My Plan:</span>
                <span className='text-nowrap'>
                  {mySub.cancelled && <span className='text-danger'>cancelled</span>}
                  {!mySub.ready && <span className='text-danger'>inactive</span>}
                  {mySub.ready && !mySub.cancelled && <span className='text-info'>{mySub.plan.name.replaceAll(' ', '')}</span>}
                </span>
              </small></b></i>
            </NavLink>
          )
      }
    </span>
  );
}