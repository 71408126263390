import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGMemberPlanOTPrice = (companyGroupMemberPlanOTPrice) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-ot-prices`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, companyGroupMemberPlanOTPrice, headers);
};

export const deleteCGMemberPlanOTPriceById = (companyGroupMemberPlanOTPriceId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-ot-prices/${companyGroupMemberPlanOTPriceId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCGMemberPlanOTPriceById = (companyGroupMemberPlanOTPriceId, params) => {
  let method = "PATCH";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-ot-prices/${companyGroupMemberPlanOTPriceId}`;
  let headers = {
    "Content-Type": "application/json",
  };
  return commonUtil.httpclient(method, url, params, headers);
};

export const getCGMemberPlanOTPriceById = (companyGroupMemberPlanOTPriceId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-ot-prices/${companyGroupMemberPlanOTPriceId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGMemberPlanOTPrices = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-ot-prices?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGMemberPlanOTPriceStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-ot-prices/stats?${decodeURIComponent(
    queries
  )}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};