import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const deleteCGEventSponsorById = (cgEventSponsorId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsors/${cgEventSponsorId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventSponsorById = (cgEventSponsorId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsors/${cgEventSponsorId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventSponsors = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsors?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventSponsorStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsors/stats?${decodeURIComponent(
        queries
    )}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCGEventSponsorById = (cgEventSponsorId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsors/${cgEventSponsorId}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
};

export const getMyCGEventSponsors = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getMyCGEventSponsorStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor/stats?${decodeURIComponent(
        queries
    )}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};