import React from "react";
import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";

const { companyService } = mtzApis;

export default function CGEventInterestedButton({ cgEventId, profileName }) {
  let [cgEvent, setCGEvent] = React.useState(null);
  let [cgEventInterest, setCGEventInterest] = React.useState(null);
  let [cgEventInterestStats, setCGEventInterestStats] = React.useState(null);
  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    (async () => {
      let cgEvent;
      if (cgEventId) {
        const cgEvents = await companyService.getCGEvents(
          `cgEventIds=${cgEventId}`
        );
        cgEvent =
          cgEvents && cgEvents.length > 0 ? cgEvents[0] : null;
      }
      if (profileName) {
        const cgEvents = await companyService.getCGEvents(
          `profileName=${profileName}`
        );
        cgEvent =
          cgEvents && cgEvents.length > 0 ? cgEvents[0] : null;
      }

      if (cgEvent === null) return;

      setCGEvent(cgEvent);

      let cgEventInterests = await companyService.getCGEventInterests(
        new URLSearchParams(`cgEventIds=${cgEvent.id}&userIds=${me.userId}`)
      );

      const cgEventInterest =
        cgEventInterests && cgEventInterests.length > 0
          ? cgEventInterests[0]
          : null;

      setCGEventInterest(cgEventInterest);
      await loadCGEventInterestStats();
    })();
  }, [profileName, cgEventId]);

  const createCGEventInterest = async () => {
    if (!me || Object.keys(me).length === 0 || !me.userId) {
      let res = await window.createMtzConfirm("To show interest for the event, please login!");
      if (res) {
        window.location.href = "/login?redirectUrl=" + window.location.pathname;
      } else return;
    }
    mtzApis.startSpinningIcon();
    cgEventInterest = await companyService.createCGEventInterest({ cgEventId: cgEvent.id });
    mtzApis.stopSpinningIcon();
    if (cgEventInterest) {
      setCGEventInterest(cgEventInterest);
      await loadCGEventInterestStats();
    }
  };

  const removeCGEventInterest = async () => {
    mtzApis.startSpinningIcon();
    cgEventInterest = await companyService.deleteCGEventInterestById(cgEventInterest.id);
    mtzApis.stopSpinningIcon();
    if (cgEventInterest) {
      setCGEventInterest(null);
      await loadCGEventInterestStats();
    }
  };

  const loadCGEventInterestStats = async () => {
    let cgEventInterestStats = await companyService.getCGEventInterestStats(new URLSearchParams(`cgEventIds=${cgEventId}`));
    setCGEventInterestStats(cgEventInterestStats);
  };

  return (
    <div>
      {cgEventInterest ? (
        <button
          className="btn btn-secondary text-white"
          onClick={removeCGEventInterest}
        >
          <i className="fa fa-heart"></i>&nbsp;Interested&nbsp;{
            cgEventInterestStats && cgEventInterestStats.all.count > 0 && (
              <span>({cgEventInterestStats.all.count})</span>
            )
          }
        </button>
      ) : (
        <button className="btn btn-primary" onClick={createCGEventInterest}>
          <i className="fa fa-heart"></i>&nbsp;Interest&nbsp;{
            cgEventInterestStats && cgEventInterestStats.all.count > 0 && (
              <span>({cgEventInterestStats.all.count})</span>
            )
          }
        </button>
      )}
    </div>
  );
}
