import React from "react";
import AuthContext from "../../../../AuthContext";
import { directRoomEventPublish } from '../../../../redux/actions/directRoomEventActions';
import { roomEventPublish } from '../../../../redux/actions/roomEventActions';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import mtzApis from "../../../../services";

const { chatService, companyService } = mtzApis;

function RoomManager({ room, onChange, newRoomEvent, newMessageEvent }) {
    let [active, setActive] = React.useState(room);
    let [rooms, setRooms] = React.useState([]);
    const me = React.useContext(AuthContext);
    const modalAddDRoomDismissRef = React.useRef();

    React.useEffect(() => {
        let mounted = true;

        if (room)
            if (!active || active.id !== room.id)
                if (mounted)
                    setActive(room);

        mtzApis.startSpinningIcon();
        (async () => {
            rooms = await chatService.getRooms(new URLSearchParams(`meta.type=RFQROOM`));
            rooms = await loadRoomInfo(rooms);
            if (mounted)
                setRooms(rooms);
        })();
        mtzApis.stopSpinningIcon();
        return () => mounted = false;
    }, [room]);

    // room event
    React.useEffect(() => {
        let mounted = true;

        (async () => {
            if (newRoomEvent && newRoomEvent.resource && newRoomEvent.resource.meta.type === 'RFQROOM') {
                if (newRoomEvent.action === 'CREATED') {
                    let newRoom = newRoomEvent.resource;
                    newRoom = (await loadRoomInfo([newRoom]))[0];
                    newRoom = await chatService.getRoomById(newRoom.id);
                    setRooms([newRoom, ...rooms]);
                    if (onChange) onChange(newRoom);
                }

                if (newRoomEvent.action === 'UPDATED') {
                    let newRoom = newRoomEvent.resource;
                    newRoom = (await loadRoomInfo([newRoom]))[0];
                    if (newRoom.memberIds.includes(me.userId)) {
                        if (!rooms.find(r => r.id === newRoom.id))
                            setRooms([newRoom, ...rooms]);
                        else
                            setRooms(rooms.map(r => r.id === newRoom.id ? newRoom : r));
                        if (onChange) onChange(newRoom);
                    } else {
                        setRooms(rooms.filter(r => r.id !== newRoom.id));
                        if (onChange) onChange();
                    }
                }

                if (newRoomEvent.action === 'DELETED') {
                    let deleted = newRoomEvent.resource;
                    if (rooms)
                        if (mounted) setRooms(rooms.filter(r => r.id !== deleted.id));
                    if (active && active.id === deleted.id)
                        if (mounted) onChange();
                }
            }
        })();

        return () => mounted = false;
    }, [newRoomEvent]);

    // message event
    React.useEffect(() => {
        if (!newMessageEvent || !newMessageEvent.resource)
            return;
        let msg = newMessageEvent.resource;

        (async () => {
            if (newMessageEvent.action === 'CREATED') {
                if (!active || msg.roomId !== active.id) {
                    let room = rooms.find(r => r.id === msg.roomId);
                    if (!room)
                        return;
                    if (room.unseenMsgs && room.unseenMsgs.find(m => m.id === msg.id))
                        return;
                    if (msg.viewerIds.includes(me.userId))
                        return;
                    room.unseenMsgs = [msg, ...(room.unseenMsgs || [])];
                    if (room.unseenMsgs.length > 11)
                        room.unseenMsgs = room.unseenMsgs.slice(0, 10 + 1);

                    setRooms(prevRooms => prevRooms.map(r => r.id === msg.roomId ? room : r));
                }
            }

            if (newMessageEvent.action === 'UPDATED') {
                if (msg.viewerIds.includes(me.userId)) {
                    let foundRoom = rooms.find(r => r.id === msg.roomId);
                    if (!foundRoom)
                        return;
                    foundRoom.unseenMsgs = await chatService.getMessages(new URLSearchParams(`roomIds=${foundRoom.id}&notSeenBy=${me.userId}&limit=11`));
                    setRooms(prevRooms => prevRooms.map(r => r.id === msg.roomId ? foundRoom : r));
                    setActive(prevActive => prevActive.id === foundRoom.id ? foundRoom : prevActive);
                }
            }

            if (newMessageEvent.action === 'DELETED') {
                let room = rooms.find(r => r.id === msg.roomId);
                if (room) {
                    if (msg.viewerIds.includes(me.userId))
                        return;
                    if (room.unseenMsgs && room.unseenMsgs.length > 0)
                        room.unseenMsgs = room.unseenMsgs.filter(m => m.id !== msg.id);
                    setRooms(prevRooms => prevRooms.map(r => r.id === room.id ? room : r));
                }
            }
        })();
    }, [newMessageEvent]);


    const loadRoomInfo = async roomList => {
        // load unseen messages
        let reqs = roomList.map(r => chatService.getMessages(new URLSearchParams(`roomIds=${r.id}&notSeenBy=${me.userId}&limit=11`)));
        let data = await Promise.allSettled(reqs);
        let vals = data.map(res => res.value);

        let count = 0;
        roomList.forEach(r => {
            r.unseenMsgs = vals[count] || [];
            count++;
        });

        // load invites
        reqs = roomList.map(r => chatService.getRoomInvites(new URLSearchParams(`roomIds=${r.id}`)));
        data = await Promise.allSettled(reqs);
        vals = data.map(res => res.value);

        count = 0;
        roomList.forEach(r => {
            r.invite = vals[count] ? vals[count][0] : null;
            count++;
        });

        // load rfq
        reqs = roomList.map(r => companyService.getRfqs(new URLSearchParams(`rfqIds=${r.meta.rfqId}`)));
        data = await Promise.allSettled(reqs);
        vals = data.map(res => res.value);

        count = 0;
        roomList.forEach(r => {
            r.rfq = vals[count] ? vals[count][0] : null;
            count++;
        });

        // load rfq imgs
        let rfqIds = roomList.map(r => r.rfq.id);
        let rfqImgs = await companyService.getRfqImages(new URLSearchParams(`rfqIds=${rfqIds}`))
        let map = {};
        rfqImgs.forEach(img => {
            if (!map[img.rfqId])
                map[img.rfqId] = [];
            map[img.rfqId].push(img);
        });
        roomList.forEach(r => {
            if (map[r.rfq.id])
                r.rfq.images = map[r.rfq.id];
        });

        // load rfq owner
        let userIds = roomList.map(r => r.rfq.ownerId);
        let rfqOwners = await companyService.getUsers(new URLSearchParams(`userIds=${userIds}`));
        map = {};
        rfqOwners.forEach(u => map[u.id] = u);
        roomList.forEach(r => r.rfq.owner = map[r.rfq.ownerId]);

        // load supplier company
        let supplierIds = roomList.map(r => r.meta.supplierId);
        let comps = await companyService.getCompanies(new URLSearchParams(`ownerIds=${supplierIds}`));
        map = {};
        comps.forEach(c => map[c.ownerId] = c);
        roomList.map(r => r.supplierCompany = map[r.meta.supplierId]);

        return roomList;
    };

    const editRoom = async (room, changes) => {
        mtzApis.startSpinningIcon();
        if (changes._leave)
            await chatService.updateRoomById(room.id, changes);
        mtzApis.stopSpinningIcon();
    };

    return (
        <div className="">
            <div className="d-flex flex-column mtz-gap-8">
                <div className="d-flex align-items-center">
                    <h6 className="m-0 mtz-h6 flex-fill">RFQ Messages:</h6>
                </div>
                <div className="list-group">
                    {
                        rooms === null &&
                        <div className="list-group-item">Loading...</div>
                    }

                    {
                        rooms && rooms.length === 0 &&
                        <div className="list-group-item text-center">
                            No message found
                        </div>
                    }

                    {
                        rooms && rooms.length !== 0 && rooms.map(room => (
                            <div key={room.id}
                                className={"list-group-item p-1" + (active && room.id === active.id ? ' bg-light' : '')}>
                                <div className="d-flex align-items-center mtz-gap-8">
                                    <div className="flex-fill d-flex mtz-gap-8 align-items-center mtz-cursor-pointer"
                                        onClick={() => {
                                            if (!active || active.id !== room.id)
                                                if (onChange)
                                                    onChange(room);
                                        }} >
                                        {
                                            room.rfq && room.rfq.images && room.rfq.images.length > 0 ?
                                                <img style={{ maxWidth: '40px', maxHeight: '40px' }} className="rounded-circle"
                                                    src={companyService.getBackendHost() + room.rfq.images[0].path} /> :
                                                <span className="fa fa-image rounded-circle text-secondary d-flex algin-items-center justify-content-center"
                                                    style={{ fontSize: '25px', width: '40px', height: '40px' }}
                                                ></span>
                                        }

                                        <span className="d-flex flex-column">
                                            <span>
                                                RFQ Owner: {room.rfq && room.rfq.ownerId === me.userId ?
                                                    <b className="text-danger">me</b> :
                                                    <span>{room.rfq && room.rfq.owner && (room.rfq.owner.firstname + ' ' + room.rfq.owner.lastname)}</span>}
                                            </span>

                                            <span>
                                                Suppl Comp: {room.rfq && room.rfq.ownerId !== me.userId ?
                                                    <b className="text-danger">me</b> :
                                                    <a href={`/profile/${room.supplierCompany.profileName}`} target="_blank">{room.supplierCompany && room.supplierCompany.name}</a>}
                                            </span>

                                            <a target="_blank" href={`/rfqs/${room.meta.rfqId}`}>RFQ: {room.name}</a>
                                        </span>
                                    </div>

                                    <div className="dropdown">
                                        <button data-toggle='dropdown' className="btn" >
                                            <span className="fa fa-ellipsis-v"></span>
                                        </button>
                                        <div className="dropdown-menu">
                                            <div className="dropdown-item">
                                                <button onClick={async () => {
                                                    const ok = await window.createMtzConfirm(`Are you sure?`);
                                                    if (!ok) return;

                                                    editRoom(room, { _leave: true });
                                                }} className="btn text-danger">
                                                    <span className="fa fa-sign-out"></span> Leave
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        room && room.unseenMsgs && room.unseenMsgs.length > 0 ?
                                            <div>
                                                <div className="circle-rounded bg-danger badge text-white">
                                                    {room.unseenMsgs.length > 10 ? '10+' :
                                                        room.unseenMsgs.length}
                                                </div>
                                            </div> :
                                            ''
                                    }
                                </div>

                                {
                                    room.memberIds.length === 1 &&
                                    <div className="text-center">
                                        <b>
                                            <small>
                                                <i>
                                                    {
                                                        room.invite ?
                                                            'RFQ chat-invite sent' :
                                                            <>
                                                                <span className="text-danger">{room.rfq && room.rfq.ownerId === me.userId && room.memberIds.includes(me.userId) ?
                                                                    'Supplier'
                                                                    : 'RFQ owner'} has left</span>
                                                                &nbsp;-&nbsp;
                                                                <a href='#' onClick={async () => {
                                                                    const ok = await window.createMtzConfirm(`You are the last one leaving, so this room and its RFQ-related messages will be removed forever. Confirm?`);
                                                                    if (!ok) return;
                                                                    editRoom(room, { _leave: true });
                                                                }}>
                                                                    Leave chat?
                                                                </a>
                                                            </>
                                                    }
                                                </i>
                                            </small>
                                        </b>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

const stateToProps = (state) => ({
    newNotiEvent: state.notification_topic.new_uievent,
    newRoomEvent: state.room_topic.new_uievent,
    newRoomInviteEvent: state.roomInvite_topic.new_uievent,
    newPresenceEvent: state.presence_topic.new_uievent,
    newMessageEvent: state.message_topic.new_uievent,
});

const dispatchToProps = (dispatch) => ({
    publishRoomEvent: (e) => dispatch(directRoomEventPublish(e)),
    publishRoomEvent: (e) => dispatch(roomEventPublish(e)),
});

const RoomsWrapper = connect(stateToProps, dispatchToProps)(withRouter(RoomManager));

export default RoomsWrapper;