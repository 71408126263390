import localStorageUtil from '../utils/localStorageUtil';
import * as authUtil from './authUtil';
import companyService from './company';

const httpclient = async (method, url, params = {}, headers = {}) => {
  const auths = authUtil.getLogins();
  let currAuth = auths && auths.length > 0 ? auths[0] : null;
  let tokenStr = currAuth ? currAuth.accessToken : '';

  const requestOptions = {
    method,
    headers: {
      ...headers,
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${tokenStr}`,
      "Access-Control-Allow-Origin": "*",
      "Tracking-Codes": localStorageUtil.get(localStorageUtil.COMPANY_SERVICE, localStorageUtil.TRACKING_CODES, 'urlTrackingCodes')
    },
    mode: 'cors',
  };

  if (params instanceof FormData) {
    delete requestOptions.headers["Content-Type"];
    requestOptions.headers.enctype = 'multipart/form-data';
    requestOptions.body = params;
  } else
    if (method.toUpperCase() !== "GET") {
      requestOptions.body = JSON.stringify(params);
    } else {
      if (url.includes('?undefined')) {
        url = url.split("?")[0];
      }
    }

  let res = {};
  try {
    res = await fetch(url, requestOptions);
  } catch (e) {
    console.log(e);
    console.log('Service unavailable 503: ' + url);
    return;
  }

  if (res.headers.get('content-type') === 'application/octet-stream') {
    return res.blob();
  }

  res = await res.text();
  if (res)
    res = JSON.parse(res);

  if (res.error) {
    switch (res.statusCode) {
      case 401:
        if (params && params.type === 'REFRESH_TOKEN')
          return;

        if (currAuth !== null && currAuth.refreshToken !== null) {
          let refreshed = await companyService.createAuthReq({ type: 'REFRESH_TOKEN', data: { refreshToken: currAuth.refreshToken } });

          if (refreshed && refreshed.accessToken) {
            authUtil.updateLogin(currAuth.email, { accessToken: refreshed.accessToken, refreshToken: refreshed.refreshToken });
            return await httpclient(method, url, params, {});
          } else {
            authUtil.removeLogin(currAuth.email);
            window.location.reload();
            alert('Token expired. logging out...');
            window.location.href = '/login';
            return;
          }
        }

        // window.location.href = '/login';
        break;
      case 400, 403, 404:
        alert(res.message);
      default: // 500 and others
        console.log("DEV ONLY: " + JSON.stringify(res));
        alert(res.message);
        return;
    }
  }

  return res;
};

export default {
  httpclient,
};