import commonUtil from "../common";
import {
    COMPANY_SERVER_URL,
    SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCompanyNoteTodo = companyNoteTodo => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-notes/todos`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, companyNoteTodo, headers);
}

export const deleteCompanyNoteTodoById = companyNoteTodoId => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-notes/todos/${companyNoteTodoId}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getCompanyNoteTodoById = companyNoteTodoId => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-notes/${companyNoteTodoId}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getCompanyNoteTodos = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-notes/todos?${queries}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getCompanyNoteTodoStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-notes/todos/stats?${queries}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const updateCompanyNoteTodoById = (companyNoteTodoId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/company-notes/todos/${companyNoteTodoId}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
}