import React, { useEffect, useState } from 'react';

import AuthContext from '../../../../AuthContext';
import mtzApis from "../../../../services";
import CardManager from '../../../payment-service/cards/CardManager';

const { paymentService } = mtzApis

const MyWallet = ({ onChange }) => {
  let [wallet, setWallet] = useState(null);
  let [amount, setAmount] = useState(15);
  let [myMtzCustomer, setMyMtzCustomer] = React.useState(null);
  let [selectedCard, setSelectedCard] = React.useState(null);
  const me = React.useContext(AuthContext);
  const modalWalletDepBtn = React.useRef();

  useEffect(() => {
    if (!me || !me.userId) return;

    // get wallet
    async function getMyWallet() {
      const res = await paymentService.getMyWallet();
      if (res)
        setWallet(res);
    }
    getMyWallet();

    // get customer
    (async () => {
      myMtzCustomer = await paymentService.getMyMtzCustomer();
      setMyMtzCustomer(myMtzCustomer);
    })();
  }, []);

  const deposit = async e => {
    e.preventDefault();

    if (amount < 15) {
      alert(`Minimum deposit is $15`);
      return;
    }

    const ok = await window.createMtzConfirm('Do you want to deposit $' + amount + '? Click OK to confirm.');
    if (!ok) return;

    let params = {
      cents: amount * 100,
    };

    if (selectedCard) {
      params.paymentInstrumentId = selectedCard.id;
      params.paymentMethod = "CARD";
    }

    mtzApis.startSpinningIcon();
    let created = await paymentService.createMtzWalletDeposit(params);
    mtzApis.stopSpinningIcon();

    if (created) {
      alert(`You have successfully deposited $${amount} into your Wallet.`);
      wallet = await paymentService.getMyWallet();
      if (wallet) {
        setWallet(wallet);
        if (onChange) onChange(wallet);
      }
      modalWalletDepBtn.current.click();
    }
  };

  const onCardSelect = card => {
    setSelectedCard(card);
  }

  if (!me || !me.userId)
    return <div>
      Login to view your wallet. Click <a target='_blank' href='/login'>HERE</a> to login.
    </div>

  return (
    <>
      {
        wallet &&
        <div className='d-flex flex-column'>
          <div>
            <h5 className='mtz-h5'>My Wallet</h5>
            {/* <small className='text-danger'><i>* Please make sure your wallet has enough balance to pay for any services on MyTradeZone</i></small> */}
          </div>

          <ul>
            <li><b> Balance:</b> ${wallet.cents ? wallet.cents / 100 : 0}</li>
          </ul>

          <div className='text-right'>
            <button data-toggle='modal' data-target='#modal-wallet-dep' ref={modalWalletDepBtn}
              className='btn btn-primary'>Deposit</button>
          </div>
        </div>
      }

      <div className='modal' id='modal-wallet-dep'>
        <div className='modal-dialog modal-dialog-scrollable modal-lg'>
          <div className='modal-content'>
            <div className='modal-header d-flex'>
              <div className='flex-fill mtz-h5'>Add New Deposit</div>
              <button className='btn btn-sm close fa fa-close close' onClick={() => modalWalletDepBtn.current.click()}></button>
            </div>

            <div className='modal-body'>
              {!me && 'Login required'}

              {!myMtzCustomer && 'No customer info found'}

              {
                me && myMtzCustomer &&
                <div className=''>
                  <div className='form-group'>
                    <label><u>Step 1: Select card to deposit</u></label>
                    <CardManager onSelect={onCardSelect} />
                  </div>

                  <hr />

                  <div className='form-group'>
                    <label><u>Step 2: Enter deposit amount</u></label>
                    <form className='d-flex flex-column mtz-gap-16' onSubmit={deposit}>
                      <div className='form-group'>
                        <label className='form-group-label'>Enter amount you want deposit - E.g. 15 will be $15 USD</label>
                        <input step={.01} type='number' min='0' onChange={e => setAmount(e.target.value)} value={amount} className='form-control' />
                        <small className='text text-danger'>* Minimum $15 USD</small>
                      </div>

                      <button className='btn btn-lg btn-outline-primary' type='submit'>
                        Deposit
                      </button>
                    </form>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyWallet;