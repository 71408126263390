import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGEventSponsorPlan = (cgEventSponsorPlan) => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plans`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, cgEventSponsorPlan, headers);
};

export const deleteCGEventSponsorPlanById = (cgEventSponsorPlanId) => {
    let method = "DELETE";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plans/${cgEventSponsorPlanId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const updateCGEventSponsorPlanById = (cgEventSponsorPlanId, params) => {
    let method = "PATCH";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plans/${cgEventSponsorPlanId}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
};

export const getCGEventSponsorPlanById = (cgEventSponsorPlanId) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plans/${cgEventSponsorPlanId}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventSponsorPlans = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plans?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGEventSponsorPlanStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/cg-event-sponsor-plans/stats?${decodeURIComponent(
        queries
    )}`;
    let headers = { "Content-Type": "application/json" };
    return commonUtil.httpclient(method, url, {}, headers);
};