import commonUtil from "../common";
import {
  COMPANY_SERVER_URL,
  SERVER_API_ALL,
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

const getProductMenuItems = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-menu-items?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getProductMenuItemStats = queries => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-menu-items/stats?${decodeURIComponent(queries)}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const getProductMenuItemById = productMenuIdId => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-menu-items/${productMenuIdId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

const createProductMenuItem = productMenuId => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-menu-items`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, productMenuId, headers);
}

const deleteProductMenuItemById = productMenuId => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/product-menu-items/${productMenuId}`;
  let headers = { "Content-Type": "application/json", };
  return commonUtil.httpclient(method, url, null, headers);
}

export {
  createProductMenuItem,
  deleteProductMenuItemById,
  getProductMenuItemById, getProductMenuItems,
  getProductMenuItemStats
};
