import React from "react";
import { NavLink } from "react-router-dom";

import AuthContext from "../../../AuthContext";
import mtzApis from "../../../services";
import "./style.css";
const { companyService } = mtzApis;

export default function ProductMenuDisplayManager({ companyId, profileName }) {
  let [menus, setMenus] = React.useState(null);
  let [company, setCompany] = React.useState(null);

  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    (async () => {
      let myCompany = null;
      if (companyId) myCompany = await companyService.getCompanyById(companyId);
      if (profileName) {
        myCompany = await companyService.getCompanies(
          new URLSearchParams(`profileName=${profileName}`)
        );
        if (myCompany && myCompany.length > 0) myCompany = myCompany[0];
      }
      setCompany(myCompany);

      if (myCompany) {
        setCompany(myCompany);
        let menus = await companyService.getProductMenus(
          new URLSearchParams(`companyIds=${myCompany.id}`)
        );
        if (menus) setMenus(menus);
      }
    })();
  }, [companyId, profileName]);

  const renderNotLogin = () => {
    return (
      <React.Fragment>
        {
          company && (
            <div className="mtz-gap-16 d-flex flex-column mtz-rounded-16 bg-white mtz-p-16">
              <div className="product-menu-display-text mtz-h5">Product Menus</div>
              <div>
                Please&nbsp;
                <NavLink to={`/login?redirectUrl=/profile/${company.profileName}`}>
                  Login
                </NavLink>
                &nbsp;to see
              </div>
            </div>
          )
        }
      </React.Fragment>
    );
  };

  if (!me || Object.keys(me).length === 0 || !me.userId) return renderNotLogin();

  return (
    <React.Fragment>
      {
        company && menus && menus.length > 0 && (
          <div className="mtz-gap-16 d-flex flex-column mtz-rounded-16 bg-white mtz-p-16">
            <div className="product-menu-display-text mtz-h5">Product Menus</div>

            <div className='dropdown'>
              <button
                className="btn btn-outline-dark dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                Select a menu
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                  menus.map((menu) => (
                    <NavLink
                      key={menu.id}
                      className="dropdown-item"
                      to={`/profile/${company.profileName}/product-menus?productMenuId=${menu.id}`}
                    >
                      {menu.name}
                    </NavLink>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }
    </React.Fragment >
  );
}
