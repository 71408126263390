import commonUtil from "../common";
import {
    PAYMENT_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = PAYMENT_SERVER_URL;

const createMtzCharge = params => {
    let method = "POST";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-charges`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, params, headers);
}

const getMtzCharges = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-charges?${queries}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}

const getMtzChargeStats = queries => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-charges/stats?${queries}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}

const getMtzChargeById = id => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-charges/${id}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}

export {
    getMtzChargeById, getMtzCharges, getMtzChargeStats,
    createMtzCharge
};

