import commonUtil from "../common";
import {
    COMPANY_SERVER_URL,
    SERVER_API_ALL
} from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const getMtzSharedFiles = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-files/secrets/shared-files?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzSharedFileStats = (queries) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-files/secrets/shared-files/stats?${decodeURIComponent(queries)}`;
    let headers = { "Content-Type": "application/json", };
    return commonUtil.httpclient(method, url, null, headers);
}

export const getMtzSharedFileStreamById = (mtzFileId, secretKey) => {
    let method = "GET";
    let url = `${SERVER_URL}${SERVER_API_ALL}/mtz-files/secrets/shared-files/${mtzFileId}/streams/${secretKey}`;
    let headers = {
        "Content-Type": "application/json",
    };
    return commonUtil.httpclient(method, url, null, headers);
}





