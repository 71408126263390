const HOST = window.location.hostname
const PORT = window.location.port;
const PROT = window.location.protocol;

const primaryColor = "#2C5AA5";
const secondaryColor = "#BD2130";
const defaultColor = "#efefef";

// ----- DO NOT TOUCH START
let METRIC_SERVER_URL = `${PROT}//${HOST}/metric-service`;
let CHAT_SERVER_URL = `${PROT}//${HOST}/chat-service`;
let ALERT_SERVER_URL = `${PROT}//${HOST}/alert-service`;
let COMPANY_SERVER_URL = `${PROT}//${HOST}/company-service`;
let CRON_SERVER_URL = `${PROT}//${HOST}/cron-service`;
let PAYMENT_SERVER_URL = `${PROT}//${HOST}/payment-service`;
// ----- DO NOT TOUCH END

if (HOST.endsWith('.net')) {
  COMPANY_SERVER_URL = `https://mytradezone.net/company-service`;
  METRIC_SERVER_URL = `https://mytradezone.net/metric-service`;
  CHAT_SERVER_URL = `https://mytradezone.net/chat-service`;
  ALERT_SERVER_URL = `https://mytradezone.net/alert-service`;
  CRON_SERVER_URL = `https://mytradezone.net/cron-service`;
  PAYMENT_SERVER_URL = `https://mytradezone.net/payment-service`;
}

if (HOST === 'localhost' || HOST.startsWith('192.168')) {
  COMPANY_SERVER_URL = `http://localhost:8184/company-service`;
  ALERT_SERVER_URL = `http://localhost:8185/alert-service`;
  METRIC_SERVER_URL = `http://localhost:8182/metric-service`;
  CHAT_SERVER_URL = `http://localhost:8183/chat-service`;
  CRON_SERVER_URL = `http://localhost:8186/cron-service`;
  PAYMENT_SERVER_URL = `http://localhost:8189/payment-service`;
}

const SERVER_API_ALL = "/api/v1";

const GOOGLE_API_KEY = "AIzaSyAJ3PgXSBGIrZh_ii7gnJD_rrP-Y7-1d0A";
const GOOGLE_CLIENT_ID = "574129111072-dtgbh49irsihfcgl9q48nkslhugq5okp.apps.googleusercontent.com";

const OUTLOOK_CLIENT_ID = "cecc771c-a0f7-4ba9-878a-a448d57b1fef";

// const LINKEDIN_CLIENT_ID = "7825oqfo5xz6lx"; //test
const LINKEDIN_CLIENT_ID = "776szocaje8u9f"; //prod

const MICROSOFT_CLIENT_ID = "48b9f34b-ef87-415f-bc82-19c14e78aad6";
const MICROSOFT_REDIRECT_URL = "https://localhost:8181/user-service/api/v1/social-login/microsoft";

const GOOGLE_RECAPTCHA_CLIENT = "6LfA79oqAAAAALgoAyoXTfamvy0IJ5X83VzDnx4C";

export {
  ALERT_SERVER_URL, CHAT_SERVER_URL,
  COMPANY_SERVER_URL, CRON_SERVER_URL, GOOGLE_API_KEY,
  GOOGLE_CLIENT_ID,
  LINKEDIN_CLIENT_ID, METRIC_SERVER_URL, MICROSOFT_CLIENT_ID,
  MICROSOFT_REDIRECT_URL, OUTLOOK_CLIENT_ID, PAYMENT_SERVER_URL, GOOGLE_RECAPTCHA_CLIENT,
  SERVER_API_ALL, defaultColor, primaryColor,
  secondaryColor
};

