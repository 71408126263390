import commonUtil from "../common";
import { COMPANY_SERVER_URL, SERVER_API_ALL } from "../config";
const SERVER_URL = COMPANY_SERVER_URL;

export const createCGMemberPlanOrder = (cgMemOTOrder) => {
  let method = "POST";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-orders`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, cgMemOTOrder, headers);
}

export const getCGMemberPlanOrderStats = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-orders/stats?${(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGMemberPlanOrders = (queries) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-orders?${(queries)}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const deleteCGMemberPlanOrderById = (cgMemberPlanOrderId) => {
  let method = "DELETE";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-orders/${cgMemberPlanOrderId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};

export const getCGMemberPlanOrderById = (cgMemberPlanOrderId) => {
  let method = "GET";
  let url = `${SERVER_URL}${SERVER_API_ALL}/cg-member-plan-orders/${cgMemberPlanOrderId}`;
  let headers = { "Content-Type": "application/json" };
  return commonUtil.httpclient(method, url, {}, headers);
};
