import React from "react";
import { useLocation, withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from '../../../../AuthContext';
import mtzApis from "../../../../services";

const { companyService } = mtzApis;

function CGEventDetailNavbar(props) {
    const me = React.useContext(AuthContext);
    let [eventProfileName, setEventProfileName] = React.useState(props.match.params.eventProfileName);
    let [groupProfileName, setGroupProfileName] = React.useState(props.match.params.groupProfileName);


    const location = useLocation();
    const currentTab = location.pathname;

    React.useEffect(() => {
        (async () => {
            let profileName = props.match.params.eventProfileName;
            let groupProfileName = props.match.params.groupProfileName;

            setEventProfileName(profileName);
            setGroupProfileName(groupProfileName);

            // let companyGroup;
            // if (profileName) {
            //     const companyGroups = await companyService.getCompanyGroups(
            //         new URLSearchParams(`profileName=${profileName}`)
            //     );
            //     companyGroup = companyGroups && companyGroups.length > 0 ? companyGroups[0] : null;
            // }

            // if (companyGroup === null) {
            //     return;
            // }
        })();
    }, [props.match.params]);

    return (
        <div className="mtz-card">
            <div className="w-100 d-flex align-items-center mtz-gap-8 flex-wrap">


                <NavLink className="btn btn-sm mtz-h5"
                    to={`/groups/${groupProfileName}/events/${eventProfileName}`}>
                    <span className={`/groups/${groupProfileName}/events/${eventProfileName}` === window.location.pathname ? 'text-primary' : 'text-secondary'}>About</span>
                </NavLink>

                <NavLink className="btn btn-sm mtz-h5"
                    to={`/groups/${groupProfileName}/events/${eventProfileName}/sponsors`}>
                    <span className={`/groups/${groupProfileName}/events/${eventProfileName}/sponsors` === window.location.pathname ? 'text-primary' : 'text-secondary'}>Sponsors</span>
                </NavLink>





            </div>
        </div>
    );
}

export default withRouter(CGEventDetailNavbar);
