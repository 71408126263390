import React, { useEffect, useState } from 'react';

import mtzApis from '../../../../services';

const { companyService } = mtzApis;

const CompanyGroupMemberCategoryManager = ({ companyGroupId, companyGroupMemberId }) => {
    const [categories, setCategories] = useState(null);
    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [isDialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        (async () => {
            init();
        })();
    }, [companyGroupId]);

    const init = async () => {
        const fetchedCategories = await companyService.getCompanyGroupCategories(
            new URLSearchParams(`companyGroupIds=${companyGroupId}`)
        );

        let companyGroupMember = await companyService.getCompanyGroupMemberById(companyGroupMemberId);
        let groupCategoryIds = companyGroupMember.groupCategoryIds;
        if (fetchedCategories && fetchedCategories.length > 0 && groupCategoryIds == null) {
            setDialogOpen(true);
        }

        setSelectedCategoryIds(groupCategoryIds);
        setCategories(fetchedCategories);
    };

    const handleEditCategories = () => {
        setDialogOpen(true);
    };

    const handleUpdate = async () => {
        try {
            const updatedParams = { groupCategoryIds: selectedCategoryIds };
            let updated = await companyService.updateCompanyGroupMemberById(companyGroupMemberId, updatedParams);
            if (updated) {
                alert("Categories updated successfully");
            }
            setDialogOpen(false);
        } catch (error) {
        }
    };

    const handleClose = async () => {
        if (selectedCategoryIds == null) {
            const updatedParams = { groupCategoryIds: [] };
            await companyService.updateCompanyGroupMemberById(companyGroupMemberId, updatedParams);
        }
        setDialogOpen(false);
    };

    const handleCategoryToggle = (categoryId) => {
        setSelectedCategoryIds((prevIds) =>
            prevIds && prevIds.includes(categoryId)
                ? prevIds.filter((id) => id !== categoryId)
                : prevIds ? [...prevIds, categoryId] : [categoryId]
        );
    };

    return (
        <>
            {isDialogOpen && (
                <div className="modal show d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog modal-dialog-scrollable-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Manage Categories</h5>
                                <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    <div className="mb-3">
                                        {selectedCategoryIds && selectedCategoryIds.length > 0 && <h6>Selected Categories:</h6>}
                                        {categories && categories.length > 0 && selectedCategoryIds && selectedCategoryIds.length > 0 && selectedCategoryIds.map((categoryId) => {
                                            const selectedCategory = categories.find((c) => c.id === categoryId);
                                            if (selectedCategory) {
                                                return (
                                                    <span key={categoryId} className="badge badge-pill badge-secondary mr-2">
                                                        {selectedCategory.name}
                                                        <button type="button" className="ml-1" onClick={() => handleCategoryToggle(categoryId)}>x</button>
                                                    </span>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                    <div>
                                        <h6>Select Categories:</h6>
                                        <div className="form-group">
                                            <div className="overflow-auto mt-3" style={{ maxHeight: '200px' }}>
                                                {categories &&
                                                    categories.map((category) => (
                                                        <div key={category.id} className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectedCategoryIds && selectedCategoryIds.includes(category.id)}
                                                                onChange={() => handleCategoryToggle(category.id)}
                                                                id={`category-${category.id}`}
                                                            />
                                                            <label className="form-check-label" htmlFor={`category-${category.id}`}>
                                                                {category.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={handleUpdate}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CompanyGroupMemberCategoryManager;
